import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import checkScrollToTop from '@helpers/check-scroll-top';
import Blog, { BlogDataType } from '@components/blog';

interface CaseStudiesProps {
  prismicCaseStudies: BlogDataType;
}

const query = graphql`
  query CaseStudiesQuery {
    prismicCaseStudies {
      ...CaseStudiesData
    }
  }
`;

const CaseStudyPage: React.FC = (): JSX.Element | null => {
  const { prismicCaseStudies }: CaseStudiesProps = useStaticQuery(query);

  useEffect(() => {
    checkScrollToTop();
  }, []);

  return <Blog data={prismicCaseStudies.data} postsType="case_study_post" />;
};

export default CaseStudyPage;
