import styled, { css } from 'styled-components';
import Text from '@components/text';
import Wrapper from '@components/wrapper.styled';
import { generateScales } from '@helpers/generate-media-queries';
import Arrow from '@components/svg-icons/arrow';
import Grid from '@components/grid';

export const boxDirection = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: transform 0.25s ease-in-out;

  ${({ theme }) => css`
    line-height: ${theme.lineHeights.lg};
    background-color: ${theme.colors.light};
  `}

  &::before {
    position: absolute;
    content: '';
    width: 70.71%;
    height: 70.71%;
    transform: rotate(45deg);
  }

  &:hover {
    transform: translateX(0);
    z-index: 9;
  }

  &:hover::before {
    width: 100%;
    height: 100%;
    transform: none;
  }
`;

export const Box = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

export const BoxTop = styled.div`
  ${boxDirection}
  transform: translateY(calc(-100% - 1px));

  &:before {
    top: 80%;
    right: 0;
    transform-origin: 100% 0;
  }
`;

export const BoxBottom = styled.div`
  ${boxDirection}
  transform: translateY(calc(100% + 1px));

  &:before {
    bottom: 80%;
    left: 0;
    transform-origin: 0 100%;
  }
`;

export const BoxLeft = styled.div`
  ${boxDirection}
  transform: translateX(calc(-100% - 1px));

  &:before {
    left: 100%;
    transform-origin: 0 0;
  }
`;

export const BoxRight = styled.div`
  ${boxDirection}
  transform:translateX(calc(100% + 1px));

  &:before {
    right: 100%;
    bottom: 0;
    transform-origin: 100% 100%;
  }
`;

export const StyledWrapper = styled(Wrapper)`
  ${({ theme }) => `
    position: relative;
    margin: 0 auto;
    z-index: ${theme.zIndex.aboveLayers}};
  `}
`;

export const StyledArrow = styled(Arrow)`
  margin-left: 1rem;
  z-index: 2;
  position: relative;
`;

export const StyledTitle = styled(Text)`
  text-align: center;
`;

export const Content = styled.div`
  position: relative;
  z-index: 3;
`;

export const StyledGrid = styled(Grid)`
  ${({ theme }) => `
    border-top: 1px solid ${theme.colors.muted};
  `}
  grid-row-gap: 0;
`;

export const Category = styled.a`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: space-between;
    ${generateScales('font-size', theme.fontSize.large.mobile, theme.fontSize.large.desktop, theme)}
    ${generateScales('padding-top', '1.5rem', '2.5rem', theme)}
    ${generateScales('padding-bottom', '1.5rem', '2.5rem', theme)}
    ${generateScales('padding-left', '0.5rem', '1rem', theme)}
    ${generateScales('padding-right', '0.5rem', '1rem', theme)}
    width: 100%;
    border-bottom: 1px solid ${theme.colors.muted};
    color: ${theme.colors.dark};
    border-color: ${theme.colors.muted};
    pointer-events: ${'auto'};
    position: relative;
    overflow: hidden;
    transition: background-color 0.25s ease-in-out;

    span {
      position: relative;
      z-index: 2;
    }

    ::before {
      display: block;
      content: '';
      height: 100%;
      width: 100%;
      background-color: ${theme.colors.light};
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      transition: transform 0.25s ease-in-out;
      transform: scaleX(0);
      transform-origin: 0 0;
    }

    :hover {
      color: ${theme.colors.primary};
      border-bottom: 1px solid ${theme.colors.primary};

      ::before {
        transform: scaleX(1);
      }

      svg {
        stroke: ${theme.colors.primary};
      }

      ${Box} {
        /* background-color: ${theme.colors.light}; */
      }
    }
  `}
`;
