import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';

interface ContainerProps {
  $hasBorder?: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${({ theme, $hasBorder }) => css`
    padding: 2rem 0 0;
    width: 100%;
    border-top: ${$hasBorder ? `1px solid ${theme.colors.muted} ` : 'none'};
  `}
`;

export const Title = styled.p<{ textColor: keyof typeof defaultTheme.colors }>`
  ${({ theme, textColor }) => css`
    ${generateScales(
      'font-size',
      theme.fontSize.extraSmall.mobile,
      theme.fontSize.extraSmall.desktop,
      theme
    )}
    line-height: 1;
    font-weight: 500;
    text-transform: uppercase;
    color: ${theme.colors[textColor]};
    opacity: 0.75;
    margin-bottom: 0.5rem;
  `}
`;

export const Media = styled.div`
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
`;

export const MediaIcon = styled.div<{ strokeColor: keyof typeof defaultTheme.colors }>`
  position: relative;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  cursor: pointer;

  svg path {
    stroke: ${({ theme, strokeColor }) => theme.colors[strokeColor]};
  }

  :hover svg path {
    stroke: #d741a7;
  }
`;

export const MediaShareIcon = styled(MediaIcon)`
  margin: 0;
`;
