import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import FONTS from '@constants/fonts';
import SCREENS from '@constants/screens';

export const underlineTransition = css`
  // add an animated underline to text
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 0.15rem;
  transition: background-size 0.25s;
  text-decoration: none;

  :hover,
  :focus {
    background-size: 100% 0.15rem;
  }
`;

const StyledTextLink = styled(Link)`
  margin: 2rem 0 0;
  display: block;
  font-family: ${FONTS.DMSans};
  color: var(--primary);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  &::after {
    content: '→';
    margin: 0 0 0 0.5rem;
    display: inline-block;
  }
`;

const StyledButtonLink = styled(Link)`
  padding: 0 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  background-color: var(--primary);
  border-radius: var(--buttonBorderRadius);
  color: var(--black);
  line-height: normal;
  height: 3.75rem;
  font-family: ${FONTS.SFProDisplay};
  font-size: 1.125rem;
  font-weight: 400;
  text-decoration: none;
  :hover {
    background-color: var(--white);
  }
  @media (max-width: ${SCREENS.sm}) {
    font-size: 1rem;
    padding: 0 2.25rem;
    height: 3.5rem;
  }
`;

const StyledButtonLabel = styled.span`
  line-height: 1rem;
`;

export { StyledTextLink, StyledButtonLink, StyledButtonLabel };
