import React from 'react';
import PostsList, { PostsListProps } from '@components/posts-list/relative-posts-list';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import { StyledTitle } from './relative-posts.styled';

interface RelativePostsProps {
  edges: PostsListProps['edges'];
  title: string;
}

const RelativePosts = ({ edges, title }: RelativePostsProps): JSX.Element => (
  <Section backgroundColor="light">
    <Wrapper>
      {title && <StyledTitle type="title" stringText={title} fontColor="dark" size="heading5" />}
    </Wrapper>
    <PostsList edges={edges} />
  </Section>
);

export default RelativePosts;
