import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import Text from '@components/text';
import Section from '@components/section';
import { generateScales } from '@helpers/generate-media-queries';
import Image from '@components/image';
import { defaultTheme } from '@styles/theme.styled';
import { TextLink } from '@components/text-link.styled';

export const StyledPost = styled.div`
  ${({ theme }) => css`
    border-radius: 0.75rem;
    overflow: hidden;
    background-color: ${theme.colors.light};
    display: flex;
    flex-direction: column;
  `};
`;
export const PostInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
`;

export const Tag = styled.a<{ hoverColor?: keyof typeof defaultTheme.colors }>`
  ${({ theme, hoverColor = 'dark' }) => css`
    color: ${theme.colors.dark};
    background-color: #f1e8db;
    height: 1.75rem;
    padding: 0 0.75rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.875rem;
    font-family: ${theme.fontFamily.text};
    font-weight: 500;
    ${generateScales('font-size', '0.75rem', '0.8125rem', theme)}
    text-decoration: none;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: color ${theme.transitions.short};
    display: inline-flex;

    &:hover {
      color: ${theme.colors.white};
    }

    &::after {
      display: block;
      content: '';
      position: absolute;
      z-index: -1;
      background-color: ${theme.colors[hoverColor]};
      height: 100%;
      width: 100%;
      transform: scaleX(0);
      transform-origin: 0% 50%;
      border-radius: 0;
      transition: all ${theme.transitions.short};
    }

    &:hover::after {
      border-radius: 0.75rem;
      transform: scaleX(1);
    }
  `};
`;

export const MoreTag = styled(Tag)<{ isOpen: boolean }>`
  ${({ theme, isOpen }) => css`
    display: inline-flex;
    width: 1.75rem;
    padding: 0;
    background-color: transparent;
    border: 2px solid #f1e8db;
    ${generateScales('font-size', '0.6875rem', '0.75rem', theme)}

    ${isOpen
      ? css`
          display: inline-flex;
        `
      : css`
          display: none;
        `}

    &:hover {
      color: ${theme.colors.dark};
    }

    &::after {
      background-color: #f1e8db;
    }
  `};
`;

export const TagLink = styled(Tag)<{ isOpen: boolean }>`
  ${({ isOpen }) => css`
    width: unset;
    ${isOpen
      ? css`
          display: inline-flex;
        `
      : css`
          display: none;
        `}
  `};
`;

export const TypeLink = styled(Tag)`
  ${({ theme }) => css`
    text-transform: capitalize;
    background-color: ${theme.colors.primary}30;
    color: ${theme.colors.dark};

    &:hover {
      color: ${theme.colors.white};
    }

    &::after {
      background-color: ${theme.colors.primary};
    }
  `};
`;

export const PostContent = styled.div<{ isImageless: boolean }>`
  ${({ isImageless }) => css`
    padding: 1.75rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.75rem;
    justify-content: space-between;
    flex: 1;

    ${isImageless &&
    css`
      padding-top: 2rem;
    `}
  `};
`;

export const ViewLink = styled.a`
  display: inline;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0;
  display: block;
  width: fit-content;
  text-decoration: none;
  font-family: ${({ theme }) => theme.fontFamily.text};
  font-weight: 500;
  ${({ theme }) => generateScales('font-size', '0.96875rem', '1rem', theme)}

  span {
    position: relative;
  }
`;

export const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledText = styled(Text)`
  display: inline;
  margin-bottom: 0;
  // add an animated underline to text
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 0.15rem;
  transition: background-size 0.25s;
  text-decoration: none;

  :hover,
  :focus {
    background-size: 100% 0.15rem;
  }
`;

export const StyledImage = styled(Image)`
  height: 100%;
  width: auto;
  transition: transform 0.3s ease-in-out;
`;

export const ImageLink = styled.a`
  position: absolute;
  inset: 0 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;

  &:hover ${StyledImage} {
    transform: scale(1.25);
  }
`;

export const PostsGrid = styled.div`
  ${({ theme }) => css`
    justify-content: center;
    display: grid;
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    grid-template-columns: repeat(3, 1fr);

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: repeat(1, 1fr);
    }
  `}
`;

export const EmptyState = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.dark};
    text-align: center;
    width: 100%;
    ${generateScales('padding-top', '2em', '4rem', theme)}
    ${generateScales('padding-bottom', '2rem', '4rem', theme)}
    ${generateScales(
      'font-size',
      theme.fontSize.subheading.mobile,
      theme.fontSize.subheading.desktop,
      theme
    )}
  `}
`;

export const StyledSection = styled(Section)`
  /* ${({ theme }) => `
    ${generateScales('padding-top', '3em', '6rem', theme)}
    ${generateScales('padding-bottom', '3rem', '6rem', theme)}
  `} */
`;

export const StyledLink = styled(Link)`
  position: absolute;
  inset: 0 0 0 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;

  &:hover ${StyledImage} {
    transform: scale(1.25);
  }
`;

export const StyledTextLink = styled(Link)`
  text-decoration: none;
`;

export const StyledTitle = styled(Text)`
  text-align: center;
  ${({ theme }) => css`
    ${generateScales('margin-bottom', '2rem', '3.5rem', theme)}
  `}
`;

export const ImageContainer = styled.div`
  width: 100%;
  padding-bottom: 63%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;
  position: relative;
`;

export const Meta = styled.div`
  ${({ theme }) => css`
    margin: 0;
    color: ${theme.colors.muted};
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop, theme)}
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.base};
    display: flex;
    flex-direction: row;
    row-gap: 0.375rem;
    column-gap: 0.375rem;
    flex-wrap: wrap;
    transition: all ${theme.transitions.short};
  `}
`;

export const Category = styled.span<{ color?: string }>`
  ${({ theme, color = 'accent' }) => css`
    color: ${color};
    font-family: ${theme.fontFamily.accent};
    font-weight: 500;
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop, theme)}
    line-height: 1;
  `}
`;

export const CategoryLink = styled.a<{ surfaceColor?: string }>`
  ${({ theme, surfaceColor = 'light' }) => css`
    color: ${theme.colors.accent};
    ${TextLink(theme.colors.accent, surfaceColor)}
  `}
`;

export const RelativeCategoryLink = styled.a<{ surfaceColor?: string }>`
  ${({ theme, surfaceColor = 'light' }) => css`
    color: ${theme.colors.accent};
    ${TextLink(theme.colors.accent, surfaceColor)}
  `}
`;

export const Title = styled.h3`
  ${({ theme }) => `
    margin: 0;
    color: ${theme.colors.dark};
    font-family: ${theme.fontFamily.display};
    ${generateScales('font-size', '1.125rem', '1.375rem', theme)}
    font-weight: ${theme.fontWeights.bold};
    line-height: ${theme.lineHeights.lg};
  `}
`;

export const Description = styled.p`
  ${({ theme }) => `
    margin: 1.25rem 0;
    color: ${theme.colors.dark};
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', '0.84375rem', '1rem', theme)}
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.base};

    + ${Meta} {
      text-transform: none;
    }
  `}
`;

export const NoImage = styled.div`
  ${({ theme }) => `
    width: 100%;
    height: 100%;
    min-height: 12rem;
    background-color: ${theme.colors.primary};
  `};
`;
