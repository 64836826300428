import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import checkScrollToTop from '@helpers/check-scroll-top';
import Blog, { BlogDataType } from '@components/blog';

interface WhitePapersProps {
  prismicWhitePapers: BlogDataType;
}

const query = graphql`
  query WhitePapersQuery {
    prismicWhitePapers {
      ...WhitePapersData
    }
  }
`;

const WhitePapersPage: React.FC = (): JSX.Element | null => {
  const { prismicWhitePapers }: WhitePapersProps = useStaticQuery(query);

  useEffect(() => {
    checkScrollToTop();
  }, []);

  return <Blog data={prismicWhitePapers.data} postsType="white_paper_post" />;
};

export default WhitePapersPage;
