import styled from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';

export const Container = styled.div``;

export const StyledTitle = styled(Text)`
  text-align: left;
  ${({ theme }) => `
    ${generateScales('margin-bottom', '2rem', '4rem', theme)}
    ${generateScales('font-size', '1.375rem', '2.25rem', theme)}
  `}
`;
