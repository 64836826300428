import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import checkScrollToTop from '@helpers/check-scroll-top';
import Blog, { BlogDataType } from '@components/blog';

interface BlogProps {
  prismicBlog: BlogDataType;
}

const query = graphql`
  query BlogQuery {
    prismicBlog {
      ...BlogData
    }
  }
`;

const BlogPage: React.FC = (): JSX.Element | null => {
  const { prismicBlog }: BlogProps = useStaticQuery(query);

  useEffect(() => {
    checkScrollToTop();
  }, []);

  return <Blog data={prismicBlog.data} postsType="post" />;
};

export default BlogPage;
