const links = [
  {
    name: 'Terms',
    url: 'https://www.tripshot.com/terms-of-service',
  },
  {
    name: 'Privacy Policy',
    url: 'https://www.tripshot.com/privacy-policy',
  },
  {
    name: 'Cookies Notice',
    url: 'https://www.tripshot.com/cookies-notice',
  },
];

export default links;
