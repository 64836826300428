import React from 'react';
import { RichText } from 'prismic-reactjs';
import { TextComponentProps } from '@components/text/text-props.type';
import { StyledSubheading } from './subheading.styled';

const Subheading = ({
  fontColor = 'dark',
  size = 'subheading',
  richText,
  className,
  spacing = 'none',
}: TextComponentProps): JSX.Element => (
  <StyledSubheading size={size} fontColor={fontColor} className={className} spacing={spacing}>
    <RichText render={richText} />
  </StyledSubheading>
);

export default Subheading;
