import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';

export const StyledSubheading = styled.div<{
  size: keyof typeof defaultTheme.fontSize;
  fontColor: keyof typeof defaultTheme.colors;
  spacing: keyof typeof defaultTheme.spacing | 'none';
}>`
  ${({ theme, size, fontColor, spacing = 'none' }) => css`
    font-family: ${theme.fontFamily.accent};
    font-weight: 400;
    line-height: ${theme.lineHeights.base};
    color: ${theme.colors[fontColor]};
    margin-bottom: ${spacing === 'none' ? '0' : theme.spacing[spacing]};

    ${generateScales('font-size', theme.fontSize[size].mobile, theme.fontSize[size].desktop, theme)}
  `}
`;
