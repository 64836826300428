import styled, { css } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import { TextLink } from '@components/text-link.styled';

export const Container = styled.div`
  width: 100%;
`;

export const NavWrapper = styled.nav`
  margin: 0 auto;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  column-gap: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.muted};
  border-radius: 2rem;
  height: 3rem;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      width: 100%;
      grid-template-columns: minmax(auto, 5rem) auto minmax(auto, 5rem);
      column-gap: 0.25rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      padding: 0;
      border: none;
      justify-content: space-between;
    }
  `}
`;

export const NavBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
  padding: 0 0 0 1rem;
  border-left: 1px solid ${({ theme }) => theme.colors.muted};
  height: 100%;
  align-items: center;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      border-left: none;
      border-right: none;
      padding: 0;
      justify-content: center;
    }
  `}
`;

export const NavLink = styled.a<{ isDisabled?: boolean; isActive?: boolean }>`
  ${({ theme, isDisabled, isActive }) => css`
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', '1rem', '1.125rem', theme)}
    line-height: ${theme.lineHeights.base};
    color: ${theme.colors.primary};
    text-decoration: none;
    padding: 0;
    margin: 0;
    text-wrap: nowrap;

    ${isDisabled &&
    css`
      pointer-events: none;
      color: rgba(0, 0, 0, 0.5);
    `}

    ${isActive &&
    css`
      cursor: default;
      color: ${theme.colors.dark};
      text-decoration: none;
      pointer-events: none;
      ${TextLink(theme.colors.white)}
    `}
  `}
`;

export const PrevNavLink = styled(NavLink)`
  text-align: right;
`;

export const NumberLink = styled(NavLink)`
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const DropdownWrapper = styled.div`
  ${({ theme }) => css`
    width: 10rem;

    @media only screen and (min-width: ${theme.breakpoints.lg}) {
      display: none;
    }
  `}
`;

export const PageList = styled.ol`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }
  `}
`;

export const PageItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    padding-right: 1rem;
    height: 100%;
    align-items: center;

    @media only screen and (min-width: ${theme.breakpoints.lg}) {
      border-right: 1px solid ${theme.colors.muted};
    }
  `}
`;
