import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import * as prismic from '@prismicio/client';
import FeaturePost from '@components/feature-post';
import Text from '@components/text';
import PostsList, { PostPreviewType } from '@components/posts-list';
import { PostPreviewGraphqlType } from '@components/posts-list/relative-posts-list';
import { PrimaryProps } from '@components/module';
import Pagination, { PageInfoProps } from '@components/pagination';
import Loader from '@components/loader';
import Wrapper from '@components/wrapper.styled';
import fetch from 'node-fetch';
import { StyledSection, DummySection, FadeIn, StyledWrapper } from './posts-list-container.styled';

const PAGE_SIZE = 12;

export interface PostListContainerProps {
  primary: PrimaryProps;
  postsType?: string;
  featuredPost?: PostPreviewGraphqlType;
}

const PostListContainer = ({
  primary,
  postsType = 'post',
  featuredPost,
}: PostListContainerProps): JSX.Element => {
  const repoName = process.env.GATSBY_PRISMIC_REPO_NAME || 'tripshot-new';
  const stagingToken = process.env.GATSBY_PRISMIC_STAGING_TOKEN;
  const stagingrRef = process.env.GATSBY_PRISMIC_STAGING_REF;
  const previewOptions =
    stagingToken && stagingrRef ? { ref: stagingrRef, accessToken: stagingToken } : {};
  const location = useLocation();
  const pageParam = new URLSearchParams(location.search).get('page') || null;
  const activeCategory = new URLSearchParams(location.search).get('category') || '';
  const activeTag = new URLSearchParams(location.search).get('tag') || '';
  const client = prismic.createClient(repoName, { fetch });
  const allCategories = ['post', 'book_post', 'case_study_post', 'video_post', 'white_paper_post'];
  const [isFeedLoading, setIsFeedLoading] = useState<boolean>(true);
  const [isFeaturedLoading, setIsFeaturedLoading] = useState<boolean>(true);
  const [isFeaturedDisplayable, setIsFeatureDisplayable] = useState(false);
  const [posts, setPosts] = useState<PostPreviewType[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfoProps>({
    page: pageParam || 1,
    prev_page: null,
    next_page: null,
    total_pages: 1,
    blogUrl: location.pathname,
  });

  useEffect(() => {
    if (featuredPost) {
      setIsFeatureDisplayable(activeCategory ? featuredPost.tags.includes(activeCategory) : true);
    }

    setIsFeaturedLoading(false);
  }, [featuredPost, activeCategory]);

  const allTypes =
    location.pathname === '/' || location.pathname === '/resources/' ? allCategories : [postsType];
  const allTypesFetch = allTypes
    .map((post) => [
      `my.${post}.category`,
      `my.${post}.post_title`,
      `my.${post}.post_description`,
      `my.${post}.post_image`,
    ])
    .flat();
  const allTypesFetchLinks = allTypes.map((post) => `my.${post}.post_image`);

  const loadPosts = (
    currentPage = 1,
    setValues: (values: PostPreviewType[]) => void,
    setLoadingValue: (values: boolean) => void
  ) => {
    const getPageItems = (items: PostPreviewType[], page: number) => {
      const startIndex = (page - 1) * PAGE_SIZE;
      const endIndex = startIndex + PAGE_SIZE;
      return items.slice(startIndex, endIndex);
    };

    const fetchCategoryPosts = async () => {
      const getCall = (category: string) =>
        client.get({
          ...previewOptions,
          filters: [
            prismic.filter.at(`my.${category}.category`, activeCategory),
            prismic.filter.not('document.id', featuredPost?.prismicId || ''),
          ],
          orderings: {
            field: 'document.first_publication_date',
            direction: 'desc',
          },
          fetchLinks: [...allTypesFetchLinks, 'category.name'],
          pageSize: 100,
          page: 1,
          fetch: allTypesFetch,
        });
      const getCalls = allCategories.map((category) => getCall(category));
      const responses = await Promise.all(getCalls);

      if (responses) {
        const allResponse = responses.flatMap((response) =>
          response ? response.results : {}
        ) as unknown as PostPreviewType[];
        const currentResponse = getPageItems(allResponse, currentPage);
        const total_pages = Math.ceil(allResponse.length / PAGE_SIZE);
        const prev_page = currentPage > 1 ? `${currentPage - 1}` : null;
        const next_page = currentPage < total_pages ? `${currentPage + 1}` : null;

        setPageInfo({
          page: currentPage,
          prev_page,
          next_page,
          total_pages,
          blogUrl: location.pathname,
        });
        setValues([...currentResponse] as unknown as PostPreviewType[]);
        setLoadingValue(false);
      }
    };

    const fetchPosts = async () => {
      const response = await client.get({
        ...previewOptions,
        filters: [
          prismic.filter.any('document.type', allTypes),
          prismic.filter.not('document.id', featuredPost?.prismicId || ''),
          ...(activeTag
            ? [prismic.filter.at('document.tags', [decodeURIComponent(activeTag)])]
            : []),
        ],
        orderings: {
          field: 'document.first_publication_date',
          direction: 'desc',
        },
        fetchLinks: [...allTypesFetchLinks, 'category.name'],
        pageSize: PAGE_SIZE,
        page: currentPage,
        fetch: allTypesFetch,
      });

      if (response) {
        const { page, prev_page, next_page, total_pages, results } = response;
        setPageInfo({
          page,
          prev_page,
          next_page,
          total_pages,
          blogUrl: location.pathname,
        });

        setValues([...results] as unknown as PostPreviewType[]);
        setLoadingValue(false);
      }
    };

    if (activeCategory) {
      fetchCategoryPosts()
        .then((r) => console.info(r))
        .catch((e) => console.error(e));
    } else {
      fetchPosts()
        .then((r) => console.info(r))
        .catch((e) => console.error(e));
    }
  };

  useEffect(() => {
    loadPosts(+pageInfo.page, setPosts, setIsFeedLoading);
  }, []);

  return (
    <>
      <StyledSection backgroundColor="white">
        <StyledWrapper>
          {isFeaturedDisplayable && (
            <FadeIn className={isFeaturedLoading ? '' : 'animate'}>
              {isFeaturedLoading ? (
                <Loader type="featured" />
              ) : (
                isFeaturedDisplayable && (
                  <FeaturePost post={featuredPost} blogUrl={location.pathname} />
                )
              )}
            </FadeIn>
          )}
          {primary.post_title?.text && <Text type="title" stringText={primary.post_title?.text} />}
          <FadeIn className={isFeedLoading ? '' : 'animate'}>
            {isFeedLoading ? (
              <DummySection>
                <Wrapper>
                  <Loader type="index" />
                </Wrapper>
              </DummySection>
            ) : (
              <PostsList posts={posts} blogUrl={location.pathname} />
            )}
            <Pagination pageInfo={pageInfo} />
          </FadeIn>
        </StyledWrapper>
      </StyledSection>
    </>
  );
};

export default PostListContainer;
