import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import checkScrollToTop from '@helpers/check-scroll-top';
import Blog, { BlogDataType } from '@components/blog';

interface BooksProps {
  prismicBooks: BlogDataType;
}

const query = graphql`
  query BooksQuery {
    prismicBooks {
      ...BooksData
    }
  }
`;

const BooksPage: React.FC = (): JSX.Element | null => {
  const { prismicBooks }: BooksProps = useStaticQuery(query);

  useEffect(() => {
    checkScrollToTop();
  }, []);

  return <Blog data={prismicBooks.data} postsType="book_post" />;
};

export default BooksPage;
