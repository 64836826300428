import React from 'react';
import MenuDropdown from '@components/menu-dropdown';
import { useLocation } from '@reach/router';
import generateId from '@helpers/generate-id';
import {
  StyledExternalLink,
  LinkContainer,
  StyledLink,
  LinkItem,
} from '@components/header-nav/mobile-header.styled';

const idGenerator = generateId();
const linkIdGenerator = generateId();

export interface NavbarLinksType {
  link_label: string;
  link_url: {
    url: string;
    target: string;
    document: {
      uid: string;
    };
  };
  dropdown_menu: {
    document: {
      uid: string;
      data: {
        title: {
          text: string;
        };
        body: {
          primary: {
            group_title: {
              text: string;
            };
          };
          items: {
            link: {
              url: string;
              document: {
                uid: string;
              };
            };
            link_label: string;
            description: string;
          }[];
        }[];
      };
    };
  };
}

const renderMobileNavbarLinks = (
  navbarLinks: NavbarLinksType[],
  layoutType: 'dark' | 'light'
): JSX.Element[] =>
  navbarLinks.map((link) => {
    const location = useLocation();
    const { link_label, link_url, dropdown_menu } = link;
    const { document, url, target } = link_url;

    const linkType = (): JSX.Element => {
      // is internal link
      if (document !== null) {
        const to = document.uid === '/main' ? '/' : `/${document.uid}`;

        if (to === '/contact') {
          return <></>;
        }

        if (to === '/careers') {
          return (
            <LinkContainer>
              <StyledLink
                to={to}
                $isActive={location.pathname === to}
                id={`${linkIdGenerator.next().value}-navbar-link`}
              >
                {link_label}
              </StyledLink>
            </LinkContainer>
          );
        }

        return (
          <StyledLink
            to={to}
            $isActive={location.pathname === to}
            id={`${linkIdGenerator.next().value}-navbar-link`}
          >
            {link_label}
          </StyledLink>
        );
      }

      if (dropdown_menu.document) {
        return (
          <MenuDropdown
            title={dropdown_menu.document.data.title.text}
            data={dropdown_menu.document.data.body}
            layoutType={layoutType}
          />
        );
      }

      // external link
      return (
        <StyledExternalLink
          href={url}
          target={target !== null ? target : '_self'}
          id={`${linkIdGenerator.next().value}-navbar-link`}
        >
          {link_label}
        </StyledExternalLink>
      );
    };

    return (
      <LinkItem key={idGenerator.next().value} layoutType={layoutType}>
        {linkType()}
      </LinkItem>
    );
  });

export default renderMobileNavbarLinks;
