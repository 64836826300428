import React from 'react';

interface DownloadIconProps {
  color?: string;
}

const DownloadIcon = ({ color = '#AA1EF5' }: DownloadIconProps): JSX.Element => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 6H33.008C37.974 6 42 10.026 42 14.992V33.01C42 37.974 37.974 42 33.008 42H14.992C10.026 42 6 37.974 6 33.008V15C6 10.03 10.03 6 15 6V6Z"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M30.8182 33.09H17.1822C14.6702 33.09 12.6362 31.056 12.6362 28.544V19.454C12.6362 16.942 14.6702 14.908 17.1822 14.908H30.8182C33.3302 14.908 35.3642 16.942 35.3642 19.454V28.544C35.3642 31.056 33.3302 33.09 30.8182 33.09V33.09Z"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M34.0318 16.24L26.8418 23.43C25.2738 24.998 22.7278 24.998 21.1598 23.43L13.9678 16.24"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M21.1601 23.4319L13.4561 31.1359"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M26.8398 23.4319L34.5438 31.1359"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);

export default DownloadIcon;
