import styled from 'styled-components';
import { headerHeight } from '@components/header/header.styled';

const Container = styled.div`
  ${({ theme }) => `
    padding-top: ${headerHeight};
    overflow-x: hidden;
    background-color: ${theme.colors.white};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      padding-top: 5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      padding-top: 4rem;
    }
  `}
`;

const Content = styled.div`
  ${({ theme }) => `
    background-color: ${theme.colors.white};
    color: ${theme.colors.dark};
  `}

  // adjust margin of page hero and showcase "terminal" sections
  .page-hero + .section-showcase {
    margin-top: 0;
  }
`;

export { Container, Content };
