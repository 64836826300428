const copyOnClick = (copiedText: string, action: () => void): void => {
  if (action) {
    action();
  }
  navigator.clipboard
    .writeText(copiedText)
    .then(() => console.log('write data into clipboard'))
    .catch((err) => console.log(err));
};

export default copyOnClick;
