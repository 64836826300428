import React, { ChangeEvent } from 'react';
import SmallInput from './input-small/input-small.styled';
import LargeInput from './input-large/input-large.styled';

export interface InputProps {
  type: string;
  size?: 'small';
  placeholder?: string;
  onChange: (value: string) => void;
  className?: string;
  value?: string;
  name?: string;
  disabled?: boolean;
}

const Input = ({
  placeholder,
  size,
  className = '',
  type,
  name,
  disabled = false,
  onChange,
  value = '',
}: InputProps): JSX.Element | null => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value);
  switch (size) {
    case 'small':
      return (
        <SmallInput
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
          className={className}
          value={value}
          name={name}
          disabled={disabled}
        />
      );
    default:
      return (
        <LargeInput
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
          className={className}
          value={value}
          name={name}
          disabled={disabled}
        />
      );
  }
};

export default Input;
