import React, { useState } from 'react';
import Button from '@components/button';
import { Form, StyledInput } from './sign-up-form.styled';

interface CallToActionProps {
  buttonLabel?: string;
  inputPlaceholder?: string;
}

const portalId = '20688672';
const formId = 'f697a783-6d9e-458c-87ae-e1f8bd5aaeee';

const SignUpForm = ({
  buttonLabel = 'Sign up',
  inputPlaceholder = 'Email address',
}: CallToActionProps): JSX.Element => {
  const [status, setStatus] = useState('ready');
  const [email, setEmail] = useState('');
  const onEmailInputChange = (currentValue: string) => {
    setEmail(currentValue);
  };

  const handleSubmit = () => {
    const xhr = new XMLHttpRequest();
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    // In var url above, replace portalId and formId with your values from HubSpot.
    // Example request JSON:
    const data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: email,
        },
      ],
      context: {
        pageUri: 'subscribe-success',
        pageName: 'Subscribe successful',
      },
    };

    const final_data = JSON.stringify(data);
    xhr.open('POST', url);

    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          console.log(xhr.responseText); // Returns a 200 response if the submission is successful.
          setStatus('success');
        } else if (xhr.status === 400) {
          console.log(xhr.responseText); // Returns a 400 error the submission is rejected.
          setStatus('error');
        } else if (xhr.status === 403) {
          console.log(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
          setStatus('error');
        } else {
          console.log(xhr.responseText); // Returns a 404 error if the formGuid isn't found
          setStatus('error');
        }
      }
    };

    // Sends the request
    xhr.send(final_data);
  };

  if (status === 'success') {
    return <div>Thanks for signing up! We&apos;ll be in touch shortly</div>;
  }

  return (
    <>
      {status === 'error' && (
        <div style={{ marginBottom: '1rem' }}>Whoops! Something went wrong. Please try again</div>
      )}
      <Form>
        <StyledInput type="email" onChange={onEmailInputChange} placeholder={inputPlaceholder} />
        <Button variant="dark" label={buttonLabel} onClick={handleSubmit} isIconVisible />
      </Form>
    </>
  );
};

export default SignUpForm;
