import styled, { css } from 'styled-components';
import Wrapper from '@components/wrapper.styled';
import { TextLink } from '@components/text-link.styled';

const Title = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.dark};
    font-family: ${theme.fontFamily.display};
    ${TextLink(theme.colors.dark, theme.colors.dark)}
    font-weight: ${theme.fontWeights.bold};
    text-wrap: nowrap;
    font-size: 1.03125rem;
    line-height: 1;

    &:active {
      color: ${theme.colors.primary};

      ::before {
        background-color: ${theme.colors.primary};
      }
    }
  `}
`;

const StyledWrapper = styled(Wrapper)`
  ${({ theme }) => css`
    position: relative;
    z-index: ${theme.zIndex.aboveLayers}};
  `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem 1rem 1.5rem;
    width: 100%;
    background-color: ${theme.colors.light};
    filter: drop-shadow(${theme.shadows.normal});
    border-radius: 0.75rem;
    border: 1px solid ${theme.colors.dark};
    z-index: ${theme.zIndex.layeredContent};
    position: relative;
    gap: 2rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      gap: 1rem;
      border-radius: 1rem;
      padding: 1.5rem 1rem 1rem;
    }
  `}
`;

const Categories = styled.div`
  display: none;
  align-items: center;
  gap: 0.75rem;
  width: 100%;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      display: flex;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      gap: 0.75rem;
      width: 100%;
      display: flex;
    }
  `}
`;

const CategoryItem = styled.li`
  margin: 0;
  padding: 0;
`;

const CategoryTitleItem = styled(CategoryItem)``;

const CategoryList = styled.ul`
  ${({ theme }) => css`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    column-gap: 1.5rem;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      ${CategoryItem} {
        display: none;
      }
      ${CategoryTitleItem} {
        display: unset;
      }
    }
  `}
`;

const InputWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    width: 100%;
    max-width: 16rem;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      max-width: 30rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 100%;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
    }
  `}
`;

const CategoryText = styled.span`
  ${({ theme }) => css`
    font-size: 1rem;
    text-decoration: none;
    ${TextLink(theme.colors.dark, theme.colors.dark)}
    color: ${theme.colors.primary};
    pointer-events: none;
  `}
`;

const CategoryLink = styled.a`
  ${({ theme }) => css`
    margin: 0;
    padding: 0;
    font-size: 1rem;
    color: ${theme.colors.dark};
    text-decoration: none;
    ${TextLink(theme.colors.dark, theme.colors.dark)}
    line-height: 1;
  `}
`;

export {
  Title,
  StyledWrapper,
  Container,
  Categories,
  CategoryText,
  CategoryList,
  CategoryItem,
  CategoryLink,
  CategoryTitleItem,
  InputWrapper,
};
