import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import checkScrollToTop from '@helpers/check-scroll-top';
import Blog, { BlogDataType } from '@components/blog';

interface BooksProps {
  prismicVideos: BlogDataType;
}

const query = graphql`
  query VideosQuery {
    prismicVideos {
      ...VideosData
    }
  }
`;

const VideoPage: React.FC = (): JSX.Element | null => {
  const { prismicVideos }: BooksProps = useStaticQuery(query);

  useEffect(() => {
    checkScrollToTop();
  }, []);

  return <Blog data={prismicVideos.data} postsType="video_post" />;
};

export default VideoPage;
