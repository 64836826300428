/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactNode, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import GlobalStyle from '@components/global-style';
import HeaderNav from '@components/header-nav';
import Footer from '@components/footer';
import { QueryThemeConfigType } from '@layouts/helpers/theme-config.type';
import Cookie from '@components/cookie';
import getThemeConfig from './helpers/get-theme-config';
import { Container, Content } from './default-layout.styled';
import '../styles/typography.css';

interface LayoutProperties {
  children: ReactNode;
  mode?: 'light' | 'dark';
}

const Layout = ({ children, mode = 'dark' }: LayoutProperties): JSX.Element => {
  const [offsetAmount, setOffsetAmount] = useState(0);
  const {
    allPrismicThemeConfig: { nodes },
  } = useStaticQuery<QueryThemeConfigType>(graphql`
    query ThemeConfigQuery {
      allPrismicThemeConfig {
        nodes {
          data {
            border_radius {
              large
              normal
              small
            }
            border_widths {
              icons
              ui
            }
            breakpoints {
              lg
              md
              sm
              xl
              xs
              xxl
            }
            button_variants {
              dark
              primary
              white
            }
            colors {
              accent
              accent_secondary
              black
              dark
              light
              light_gray
              muted
              primary
              primary_light
              secondary
              tertiary
              white
            }
            font_family {
              display
              mono
              accent
              text
            }
            font_size_desktop {
              body
              extra_small
              eyebrow
              h1
              h2
              h3
              h4
              h5
              h6
              large
              small
              subheading
              tooltip
            }
            font_size_mobile {
              body
              extra_small
              eyebrow
              h1
              h2
              h3
              h4
              h5
              h6
              large
              small
              subheading
              tooltip
            }
            font_weights {
              bold
              normal
            }
            input_size_lg {
              desktop_border_radius
              desktop_font_size
              desktop_height
              mobile_border_radius
              mobile_font_size
              mobile_height
            }
            input_size_md {
              desktop_border_radius
              desktop_font_size
              desktop_height
              mobile_border_radius
              mobile_font_size
              mobile_height
            }
            input_size_sm {
              desktop_border_radius
              desktop_font_size
              desktop_height
              mobile_border_radius
              mobile_font_size
              mobile_height
            }
            line_heights {
              base
              lg
              sm
              xs
              xl
            }
            padding {
              lg
              lg_cards
              md
              md_cards
              sm
              sm_cards
              xl
              xs
            }
            scales {
              lg
              md
              sm
              xl
              xs
              xxl
            }
            shadows {
              normal
              small
            }
            spacing {
              column_gap
              lg
              md
              sm
              xl
              xs
              xxl
              xxs
              xxxl
            }
            transitions {
              long
              normal
              short
            }
            z_index {
              above_layers
              layered_content
              layered_graphic
              navbar
              surface
            }
          }
        }
      }
    }
  `);

  const defaultTheme = getThemeConfig(nodes[0].data);

  return (
    /* @ts-expect-error Server Component */
    <ThemeProvider theme={defaultTheme}>
      <Container>
        {/* @ts-expect-error Server Component */}
        <GlobalStyle />
        <HeaderNav layoutType={mode} theme={defaultTheme} />
        <Content>{children}</Content>
        <Cookie offsetAmountSetter={setOffsetAmount} />
        <Footer offsetAmount={offsetAmount} />
      </Container>
    </ThemeProvider>
  );
};

export default Layout;
