import styled, { css } from 'styled-components';
import Text from '@components/text';

export const StyledTitle = styled(Text)`
  text-align: left;
`;

export const LinkedTitle = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.dark};
    display: inline;
    margin-bottom: 0;
    // add an animated underline to text
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 0.15rem;
    transition: background-size 0.25s;
    text-decoration: none;

    :hover,
    :focus {
      background-size: 100% 0.15rem;
    }
  `}
`;

export const PostContent = styled.div<{ isImageless: boolean }>`
  ${({ isImageless }) => css`
    padding: 1.75rem 0 0;
    display: flex;
    flex-direction: column;
    row-gap: 1.75rem;
    justify-content: space-between;
    flex: 1;

    ${isImageless &&
    css`
      padding-top: 2rem;
    `}
  `};
`;

export const ImageContainer = styled.div`
  width: 100%;
  padding-bottom: 63%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;
  position: relative;
  border-radius: 0.75rem;
`;
