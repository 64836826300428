import React, { useState, useRef } from 'react';
import { reposition } from 'nanopop';
import useOnClickOutside from '@hooks/use-on-click-outside';
import generateId from '@helpers/generate-id';
import {
  DropdownContainer,
  StyledDropDown,
  Button,
  Links,
  InternalLinks,
  Title,
  StyledCaret,
  Triangle,
  DropDownColumn,
  LinkContainer,
  Description,
  MobileMenu,
} from './dropdown.styled';

export interface DropdownProps {
  data: {
    primary: {
      group_title: {
        text: string;
      };
    };
    items: {
      link: {
        url: string;
        document: {
          uid: string;
        };
      };
      link_label: string;
      description: string;
    }[];
  }[];
  title?: string;
  layoutType: 'dark' | 'light';
}
const dropdownIdGenerator = generateId();

const DropdownMenu = ({ title, data, layoutType }: DropdownProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggle = () => {
    if (ref.current && dropdownRef.current) {
      reposition(ref.current, dropdownRef.current, {
        position: 'bottom-middle',
        margin: 12,
      });
    }

    setIsOpen(!isOpen);
  };

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <DropdownContainer ref={ref}>
      <Button onKeyPress={toggle} onClick={toggle} isVisible={isOpen} layoutType={layoutType}>
        <span>{title}</span> <StyledCaret layoutType={layoutType} />
        <Triangle isVisible={isOpen} layoutType={layoutType} />
      </Button>
      <StyledDropDown isVisible={isOpen} ref={dropdownRef}>
        {data.map(({ primary, items }) => (
          <DropDownColumn key={dropdownIdGenerator.next().value}>
            {primary.group_title.text && (
              <Title type="title" stringText={primary.group_title.text} />
            )}
            {items.map(({ link, link_label, description }) => {
              if (link.document !== null && link.document.uid === 'main') {
                return (
                  <LinkContainer key={dropdownIdGenerator.next().value}>
                    <InternalLinks to="/">{link_label}</InternalLinks>
                  </LinkContainer>
                );
              }
              return (
                <LinkContainer key={dropdownIdGenerator.next().value}>
                  {link.document !== null ? (
                    <InternalLinks to={`/${link.document.uid}`}>{link_label}</InternalLinks>
                  ) : (
                    <Links href={link.url}>{link_label}</Links>
                  )}
                  {description && <Description type="title" stringText={description} />}
                </LinkContainer>
              );
            })}
          </DropDownColumn>
        ))}
      </StyledDropDown>
      <MobileMenu layoutType={layoutType}>
        {data.map(({ primary, items }) => (
          <DropDownColumn key={dropdownIdGenerator.next().value}>
            {primary.group_title.text && (
              <Title type="title" stringText={primary.group_title.text} />
            )}
            {items.map(({ link, link_label, description }) => (
              <LinkContainer key={dropdownIdGenerator.next().value}>
                {link.document !== null ? (
                  <InternalLinks to={`/${link.document.uid}`}>{link_label}</InternalLinks>
                ) : (
                  <Links href={link.url}>{link_label}</Links>
                )}
                {description && <Description type="title" stringText={description} />}
              </LinkContainer>
            ))}
          </DropDownColumn>
        ))}
      </MobileMenu>
    </DropdownContainer>
  );
};

export default DropdownMenu;
