import React from 'react';

const FacebookIcon = (): JSX.Element => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 6H33.008C37.974 6 42 10.026 42 14.992V33.01C42 37.974 37.974 42 33.008 42H14.992C10.026 42 6 37.974 6 33.008V15C6 10.03 10.03 6 15 6V6Z"
      stroke="#F3E8DA"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M22.2002 25.8H33.0002"
      stroke="#F3E8DA"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M32.9998 16.8H31.1098C28.1778 16.8 25.7998 19.1781 25.7998 22.11V24V42"
      stroke="#F3E8DA"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);

export default FacebookIcon;
