import React from 'react';
import { ImageProps } from '@components/image';
import { RichTextBlock } from 'prismic-reactjs';
import SignUpForm from '@components/sign-up-form';
import Wrapper from '@components/wrapper.styled';
import Text from '@components/text';
import {
  Header,
  Container,
  Content,
  StyledSubheading,
  TextContainer,
  StyledImage,
} from './page-hero.styled';

interface PageHeroProps {
  image?: ImageProps;
  title?: string;
  subheading?: RichTextBlock[];
  isFormEnabled?: boolean;
}

const PageHero = ({
  image,
  title,
  subheading = [],
  isFormEnabled = false,
}: PageHeroProps): JSX.Element => {
  const hasSubheading: boolean = subheading?.length > 0 && subheading[0].text !== '';
  if (!title && !image && !hasSubheading) {
    return <></>;
  }

  return (
    <Header className="page-hero">
      <Wrapper>
        {image?.url ? (
          <Container>
            <Content>
              {title && <Text size="heading1" type="title" stringText={title} />}
              {hasSubheading && <StyledSubheading type="subheading" richText={subheading} />}
              {isFormEnabled && <SignUpForm />}
            </Content>
            <StyledImage url={image.url} alt={image.alt} />
          </Container>
        ) : (
          <TextContainer>
            {title && <Text size="heading1" type="title" stringText={title} />}
            {hasSubheading && <StyledSubheading type="subheading" richText={subheading} />}
            {isFormEnabled && <SignUpForm />}
          </TextContainer>
        )}
      </Wrapper>
    </Header>
  );
};

export default PageHero;
