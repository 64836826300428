import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import renderModule, { ModuleProps } from '@components/module';
import SEO from '@components/seo';
import { PostPreviewGraphqlType } from '@components/posts-list/relative-posts-list';
import Layout from '@layouts/default-layout';
import generateId from '@helpers/generate-id';

const blogPageIdGenerator = generateId();

const query = graphql`
  query GeneralBlogQuery {
    prismicGeneralConfig {
      data {
        favicon {
          url
        }
      }
    }
  }
`;

interface BlogProps {
  prismicGeneralConfig: {
    data: {
      favicon?: {
        url?: string;
      };
    };
  };
}

export interface BlogDataType {
  data: {
    page_title: {
      text: string;
    };
    featured_post?: {
      document: PostPreviewGraphqlType;
    };
    body: ModuleProps[];
  };
  postsType: string;
}

const Blog = ({ data, postsType }: BlogDataType): JSX.Element | null => {
  const {
    prismicGeneralConfig: {
      data: { favicon },
    },
  }: BlogProps = useStaticQuery(query);

  const { page_title, featured_post } = data;

  return (
    <Layout>
      <SEO title={page_title.text} faviconHref={favicon?.url || ''} />
      {data.body.map(
        (module) =>
          module && (
            <React.Fragment key={blogPageIdGenerator.next().value}>
              {renderModule({ postsType, ...module }, featured_post?.document)}
            </React.Fragment>
          )
      )}
    </Layout>
  );
};

export default Blog;
