import React from 'react';
import Text from '@components/text';
import { RichTextBlock } from 'prismic-reactjs';
import { ImageProps } from '@components/image';
import {
  GradientWrapper,
  LinkButton,
  Container,
  ImageContainer,
  Content,
  TextContent,
} from './tout.styled';

interface ToutProps {
  image?: ImageProps;
  title?: {
    text: string;
  };
  description?: {
    text: string;
    richText: RichTextBlock[];
  };
  link: {
    url: string;
    target?: string;
  };
  link_label: string;
  mode?: 'light' | 'dark';
}

const Tout = ({
  title,
  description,
  image,
  link,
  link_label,
  mode = 'light',
}: ToutProps): JSX.Element => {
  const hasImage: boolean = image?.url !== undefined && image?.url !== null && image?.url !== '';
  const textColor = mode === 'light' ? 'dark' : 'light';

  if (!title && !image && !description?.text && !link) {
    return <></>;
  }

  return (
    <GradientWrapper>
      <Container backgroundColor={mode === 'light' ? 'white' : 'dark'}>
        {hasImage && (
          <ImageContainer
            url={image?.url}
            alt={image?.alt || ''}
            gatsbyImageData={image?.gatsbyImageData}
            objectFit="contain"
          />
        )}
        <Content hasImage={hasImage}>
          <TextContent>
            {title && (
              <Text
                type="title"
                tag="h2"
                size="heading3"
                stringText={title.text}
                fontColor={textColor}
                spacing="sm"
              />
            )}
            <Text
              size="subheading"
              richText={description?.richText}
              fontColor={textColor}
              spacing="none"
            />
          </TextContent>
          <LinkButton
            variant={mode === 'light' ? 'secondary' : 'white'}
            href={link?.url}
            target={link?.target}
            type="button"
            title={link_label || 'Request a Demo'}
          >
            {link_label}
          </LinkButton>
        </Content>
      </Container>
    </GradientWrapper>
  );
};

export default Tout;
