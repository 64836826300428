import { withPrefix } from 'gatsby';

const getBlogType = (
  type: 'book_post' | 'white_paper_post' | 'case_study_post' | 'video_post' | 'post',
  prefix = true
): string => {
  switch (type) {
    case 'video_post':
      return prefix ? withPrefix('/videos') : '/video';
    case 'book_post':
      return prefix ? withPrefix('/ebooks') : '/ebooks';
    case 'case_study_post':
      return prefix ? withPrefix('/case-studies') : '/case-studies';
    case 'white_paper_post':
      return prefix ? withPrefix('/white-papers') : '/white-papers';
    case 'post':
      return prefix ? withPrefix('/blog') : '/blog';
    default:
      return withPrefix('/blog');
  }
};

export default getBlogType;
