import React, { useState, useRef, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { DefaultTheme } from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import renderNavbarLinks, { NavbarLinksType } from '@helpers/get-navbar-links';
import renderMobileNavbarLinks from '@helpers/get-mobile-navbar-links';
import Wrapper from '@components/wrapper.styled';
import { StyledHeader, Content, Menu, Logo, MainActionsWrapper } from './header.styled';
import {
  BurgerButton,
  Line,
  MobileMenu,
  MobileContent,
  MobileContactButton,
} from './mobile-header.styled';

gsap.registerPlugin(ScrollTrigger);

interface HeaderNavQueryInterface {
  prismicGeneralConfig: {
    data: {
      logo: {
        alt?: string;
        url: string;
        target: string;
      };
      navbar_links: NavbarLinksType[];
    };
  };
}

export const query = graphql`
  query HeaderNavQuery {
    prismicGeneralConfig {
      data {
        logo {
          url
          alt
        }
        navbar_links {
          link_label
          link_url {
            url
            target
            document {
              ... on PrismicPage {
                uid
              }
              ... on PrismicBlog {
                uid
              }
              ... on PrismicBooks {
                uid
              }
              ... on PrismicVideos {
                uid
              }
              ... on PrismicWhitePapers {
                uid
              }
              ... on PrismicCaseStudies {
                uid
              }
            }
          }
          dropdown_menu {
            document {
              ... on PrismicDropdownMenu {
                uid
                data {
                  title {
                    text
                  }
                  body {
                    ... on PrismicDropdownMenuDataBodyLinks {
                      primary {
                        group_title {
                          text
                        }
                      }
                      items {
                        link {
                          url
                          document {
                            ... on PrismicPage {
                              uid
                            }
                            ... on PrismicBlog {
                              uid
                            }
                            ... on PrismicBooks {
                              uid
                            }
                            ... on PrismicVideos {
                              uid
                            }
                            ... on PrismicWhitePapers {
                              uid
                            }
                            ... on PrismicCaseStudies {
                              uid
                            }
                          }
                        }
                        link_label
                        description
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

interface HeaderProps {
  layoutType?: 'dark' | 'light';
  theme: DefaultTheme;
}

const HeaderNav = ({ layoutType = 'dark', theme }: HeaderProps): JSX.Element => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const headerRef = useRef<HTMLHeadingElement>(null);

  const {
    prismicGeneralConfig: {
      data: { logo, navbar_links },
    },
  }: HeaderNavQueryInterface = useStaticQuery(query);

  const headerHeight = headerRef?.current?.offsetHeight;

  const applyScrollTrigger = () => {
    const initialBackgroundColor = theme.colors.transparent;
    const activeBackgroundColor = layoutType === 'dark' ? theme.colors.primary : theme.colors.white;
    const emphasizeNav = gsap.fromTo(
      headerRef.current,
      {
        backgroundColor: initialBackgroundColor,
        filter: 'drop-shadow(0rem 0.5rem 2rem rgba(0, 0, 0, 0))',
      },
      {
        backgroundColor: activeBackgroundColor,
        filter: 'drop-shadow(0rem 0.5rem 2rem rgba(0, 0, 0, 0.125))',
      }
    );

    return ScrollTrigger.create({
      animation: emphasizeNav,
      trigger: 'body',
      scrub: true,
      start: `top top`,
      end: `top top-=100`,
    });
  };

  useEffect(() => {
    const scrollTriggerInstance = applyScrollTrigger();

    return () => {
      scrollTriggerInstance.kill();
    };
  }, []);

  useEffect(() => {
    if (isMenuVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isMenuVisible]);

  return (
    <>
      <StyledHeader ref={headerRef} $isActive={isMenuVisible} layoutType={layoutType}>
        <Wrapper>
          <Content id="nav-content">
            {logo.url && (
              <a href="https://www.tripshot.com">
                <Logo imageUrl={logo.url} layoutType={layoutType} />
              </a>
            )}
            <Menu>{renderNavbarLinks(navbar_links, layoutType)}</Menu>
            <MainActionsWrapper>
              <MobileContactButton
                to="https://www.tripshot.com/contact"
                $isActive={false}
                id="mobile-contact-navbar-link"
              >
                Contact
              </MobileContactButton>
              <BurgerButton
                onClick={() => setIsMenuVisible(!isMenuVisible)}
                isActive={isMenuVisible}
                layoutType={layoutType}
              >
                <Line />
                <Line />
                <Line />
              </BurgerButton>
            </MainActionsWrapper>
          </Content>
        </Wrapper>
      </StyledHeader>
      <MobileContent isVisible={isMenuVisible} headerHeight={headerHeight} layoutType={layoutType}>
        <Wrapper>
          <MobileMenu>{renderMobileNavbarLinks(navbar_links, layoutType)}</MobileMenu>
        </Wrapper>
      </MobileContent>
    </>
  );
};

export default HeaderNav;
