const checkScrollToTop = () => {
  // check if the current url is different from the previous one
  if (window.location.href !== window.sessionStorage.getItem('url')) {
    // if it is, scroll to top and save the new url in the session storage
    window.scrollTo(0, 0);
    window.sessionStorage.setItem('url', window.location.href);
  }
};

export default checkScrollToTop;
