import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';

export const StyledDescription = styled.div<{
  fontColor: keyof typeof defaultTheme.colors;
  size: keyof typeof defaultTheme.fontSize;
  spacing: keyof typeof defaultTheme.spacing | 'none';
}>`
  ${({ theme, size, fontColor = 'dark', spacing = 'none' }) => {
    return css`
      font-family: ${theme.fontFamily.text};
      line-height: ${theme.lineHeights.base};
      color: ${theme.colors[fontColor]};
      margin-bottom: ${spacing === 'none' ? '0' : theme.spacing[spacing]};

      ${generateScales(
        'font-size',
        theme.fontSize[size].mobile,
        theme.fontSize[size].desktop,
        theme
      )}
    `;
  }}
`;
