import React from 'react';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery, withPrefix } from 'gatsby';
import generateId from '@helpers/generate-id';
import { DropdownItemProps } from '@components/dropdown';
import Section from '@components/section';
import { StyledWrapper, Category, StyledTitle, StyledGrid, StyledArrow } from './categories.styled';

const categoriesIdGenerator = generateId();

interface CategoriesProps {
  title?: string;
  itemsPerRow?: number;
}

interface CategoriesQueryInterface {
  allPrismicCategory: {
    edges: [];
  };
}

export const query = graphql`
  query CategoryListQuery {
    allPrismicCategory {
      edges {
        node {
          ...CategoryFragment
        }
      }
    }
  }
`;

const CategoriesTag = ({ title, itemsPerRow = 3 }: CategoriesProps): JSX.Element => {
  const location = useLocation();
  const activeCategory = new URLSearchParams(location.search).get('category') || '';
  const activeTag = new URLSearchParams(location.search).get('tag') || '';
  const {
    allPrismicCategory: { edges },
  }: CategoriesQueryInterface = useStaticQuery(query);
  const categoryItems: DropdownItemProps[] = edges.map(
    (edge: { node: { prismicId: string; data: { name?: string } } }): DropdownItemProps => {
      return {
        label: edge.node?.data?.name || '',
        link: edge.node?.prismicId,
      };
    }
  );

  return (
    <Section backgroundColor="white">
      <StyledWrapper>
        {title && (
          <StyledTitle
            size="heading3"
            type="title"
            fontColor="dark"
            stringText={title}
            spacing="lg"
          />
        )}
        <StyledGrid itemsPerRow={itemsPerRow}>
          {categoryItems.map(({ label, link }) => {
            const to = withPrefix(`/?category=${link}`);

            return decodeURI(activeCategory) === link ? null : (
              <Category href={to} key={categoriesIdGenerator.next().value}>
                <span>{label}</span>
                <StyledArrow color="primary" />
              </Category>
            );
          })}
          {activeCategory === '' && activeTag === '' ? (
            <></>
          ) : (
            <Category href={withPrefix('/')} key={categoriesIdGenerator.next().value}>
              <span>View All</span>
              <StyledArrow color="primary" />
            </Category>
          )}
        </StyledGrid>
      </StyledWrapper>
    </Section>
  );
};

export default CategoriesTag;
