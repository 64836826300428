import styled, { css, keyframes } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';

export const StyledSection = styled(Section)`
  z-index: 9;
`;

export const DummySection = styled.div`
  pointer-events: none;
  transition: all 0.5s ease;

  ${({ theme }) => css`
    ${generateScales('padding-top', '3.5rem', '5.5rem', theme)}
    ${generateScales('padding-bottom', '3.5rem', '5.5rem', theme)}
    &.animate {
      display: none;
    }
  `}
`;

export const DummyWrapper = styled(Wrapper)`
  pointer-events: none;
  transition: all 0.5s ease;

  &.animate {
    display: none;
  }
`;

const fadeInKeyframes = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const fadeInAnimation = () => css`
  animation: ${fadeInKeyframes} 0.5s ease-in;
`;

export const FadeIn = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 6rem;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      row-gap: 3rem;
    }

    &.animate {
      ${fadeInAnimation}
    }
  `}
`;

export const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
`;
