import styled, { css } from 'styled-components';

export const headerHeight = '5.5rem';

export const StyledHeader = styled.nav<{ $isActive: boolean; layoutType: 'dark' | 'light' }>`
  ${({ theme, layoutType }) => css`
    display: flex;
    padding: 0.5rem 0;
    align-items: center;
    width: 100%;
    min-height: ${headerHeight};
    max-height: max-content;
    background-color: ${layoutType === 'dark' ? theme.colors.primary : theme.colors.white};
    position: fixed;
    top: 0;
    z-index: ${theme.zIndex.navbar};
    transition: background-color ${theme.transitions.normal};
    transition-delay: 0.5;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      min-height: 5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      min-height: 4rem;
    }
  `}
  ${({ theme, $isActive, layoutType }) =>
    $isActive
      ? `
      transition-delay: 0;
      background-color: ${
        layoutType === 'dark' ? theme.colors.primary : theme.colors.white
      } !important;
      `
      : ''}
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Logo = styled.div<{ imageUrl: string; layoutType: 'dark' | 'light' }>`
  ${({ theme, imageUrl, layoutType }) => css`
    width: 10.67rem;
    height: 2.5rem;
    background-color: ${layoutType === 'dark' ? theme.colors.white : theme.colors.primary};
    mask: url(${imageUrl}) no-repeat center / contain;
    -webkit-mask: url(${imageUrl}) no-repeat center / contain;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 7rem;
      height: 2rem;
    }
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 9.375rem;
      height: 2.1875rem;
    }
  `}
`;

export const MainActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;
`;

export const Menu = styled.ul`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    column-gap: 2rem;
    align-items: center;
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      column-gap: 1.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      column-gap: 1rem;
      display: none;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      column-gap: 1.25rem;
    }
  `}
`;
