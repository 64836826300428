import styled, { css } from 'styled-components';
import Text from '@components/text';
import { MediumButton } from '@styles/button.styled';
import Arrow from '@components/svg-icons/arrow';
import { generateScales } from '@helpers/generate-media-queries';
import DownloadIcon from '@components/svg-icons/download';
import { TextLink } from '@components/text-link.styled';

export const VideoSectionWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${generateScales('row-gap', '3.5rem', '5.5rem', theme)}
  `}
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled.a`
  ${MediumButton}
  ${({ theme }) => css`
    ${generateScales('font-size', '1rem', '0.875rem', theme)}
  `}
  width: auto;
`;

export const ButtonsContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.75rem;
    ${generateScales('margin-top', '2.5rem', '4rem', theme)}

    @media (max-width: ${theme.breakpoints.xl}) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: repeat(2, 1fr);
    }
  `}
`;

export const StyledArrow = styled(Arrow)`
  margin-left: 0.625rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 60rem;
  padding: 0 1rem;
`;

export const HeroImageContainer = styled.div`
  width: 100%;
  max-height: 38rem;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;

  div,
  img {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;

  div,
  img {
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
  }
`;

export const DownloadContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;

    @media (max-width: ${theme.breakpoints.lg}) {
      border-top: none;
      width: fit-content;
    }
  `}
`;

export const StyledExternalLink = styled.a`
  ${({ theme }) => css`
    padding: 0.5rem;
    margin: 0 0.5rem;
    text-decoration: none;
    color: ${theme.colors.dark};
    font-family: ${theme.fontFamily.text};
    font-size: ${theme.lineHeights.xs};
    line-height: ${theme.lineHeights.xs};

    :hover {
      text-decoration: underline;
    }

    :last-child {
      padding-right: 0;
      margin-right: 0;
    }
  `}
`;

export const MethodsContainer = styled.div`
  display: flex;
  width: 100%;
  align-self: center;
  margin: 0 auto;
  margin-top: 3.5rem;

  ${({ theme }) => css`
    padding-bottom: 8rem;

    @media (max-width: ${theme.breakpoints.xl}) {
      padding-bottom: 6rem;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      padding-bottom: 5rem;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding-bottom: 4rem;
    }
  `}
`;

export const PostContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    column-gap: 4rem;
    width: 100%;
    max-width: 64rem;
    align-self: center;
    margin: 0 auto;
    padding-top: 4rem;
    padding-bottom: 5rem;

    ${generateScales('padding-bottom', '3.5rem', '5.5rem', theme)}

    @media (max-width: ${theme.breakpoints.xl}) {
      row-gap: 3rem;
      padding-top: 3rem;
      column-gap: 3rem;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column-reverse;
      align-items: center;
      max-width: 56rem;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column-reverse;
    }
  `}
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 0.375rem;
  column-gap: 0.375rem;
  flex-wrap: wrap;
`;

export const MetaContainer = styled.div`
  ${({ theme }) => css`
    flex-direction: column;
    row-gap: 2rem;
    display: flex;
    min-width: 15rem;

    @media (max-width: ${theme.breakpoints.lg}) {
      min-width: 12rem;
      margin: 0;
      padding: 0;
      ${generateScales('margin-top', '3.5rem', '5.5rem', theme)}
      padding-top: 2rem;
      width: 100%;
      display: flex;
      border-top: 1px solid ${theme.colors.muted};
    }
  `}
`;

export const MethodMetaContainer = styled.div`
  margin: 0 0 0 4rem;

  ${({ theme }) => css`
    box-shadow: ${theme.shadows.small};
    border-radius: ${theme.borderRadius.small};
    padding: 2rem 1.5rem;
    @media (max-width: ${theme.breakpoints.lg}) {
      margin: 0;
      padding: 0;
      ${generateScales('margin-top', '2.5rem', '4rem', theme)}
      ${generateScales('padding-top', '2.5rem', '4rem', theme)}
      width: 100%;
      display: flex;
      border-top: 1px solid ${theme.colors.dark};
    }
  `}
`;

export const MetaInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start
    row-gap: 5rem;
    width: 16rem;
    height: max-content;
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop, theme)}
    line-height: 1;
    color: ${theme.colors.muted};

    @media (max-width: ${theme.breakpoints.lg}) {
      width: 100%;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
    }
  `}
`;

export const CategoryLink = styled.a<{ surfaceColor?: string }>`
  ${({ theme, surfaceColor = 'white' }) => css`
    color: ${theme.colors.accent};
    ${TextLink(theme.colors.accent, surfaceColor)}
  `}
`;

export const Categories = styled.div`
  ${({ theme }) => css`
    ${CategoryLink} + ${CategoryLink} {
      position: relative;
      margin-left: 0.625rem;

      ::after {
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        color: ${theme.colors.dark} !important;
        content: ',';
        width: 0.5rem;
        line-height: 1.25;
        text-align: left;
        height: 100%;
        display: block;
        pointer-events: none;
      }
    }
  `}
`;

export const TopMeta = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
`;

export const SubMeta = styled.div`
  display: flex;
`;

export const TextSubMeta = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const AuthorPhotoConteiner = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  overflow: hidden;

  div {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  + ${TextSubMeta} {
    margin: 0 0 0 0.75rem;
  }
`;

export const AuthorName = styled.div`
  ${({ theme }) => css`
    ${generateScales('font-size', '0.8375rem', '1rem', theme)}
    line-height: 1;
    color: ${theme.colors.dark};
  `}
`;

export const PrefixAuthorName = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.dark};
  `}
`;

export const Date = styled.div`
  ${({ theme }) => css`
    margin-top: 0.75rem;
    color: ${theme.colors.dark};
    opacity: 0.5;
    line-height: 1.125;
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop, theme)}
  `}
`;

export const PostBody = styled.div`
  ${({ theme }) => css`
    flex: 1 auto;
    font-family: ${theme.fontFamily.text};
    width: 100%;
    overflow: scroll;
  `}
`;
export const VideoCategoryBar = styled.div`
  ${({ theme }) => css`
    padding: 4rem 0;
    position: relative;

    @media (max-width: ${theme.breakpoints.xl}) {
      padding: 4rem 0;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      padding: 3rem 0;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 2.5rem 0;
    }
  `}
`;

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    border-bottom: 1px solid ${theme.colors.muted};
    ${generateScales('padding-top', '1.5rem', '3rem', theme)}
    ${generateScales('padding-bottom', '1.5rem', '3rem', theme)}
  `}
`;

export const ResearcherName = styled.div`
  ${({ theme }) => css`
    ${generateScales('font-size', '0.8375rem', '1rem', theme)}
    line-height: 1;
    color: ${theme.colors.dark};

    a,
    a:visited {
      color: ${theme.colors.tertiary};

      &:hover {
        text-decoration: none;
      }
    }
  `}
`;

export const CreditContainer = styled.div`
  width: 100%;
  margin: 2rem 0 0;

  ${({ theme }) => css`
    color: ${theme.colors.dark};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop, theme)}
    line-height: ${theme.lineHeights.lg};

    a,
    a:visited {
      color: ${theme.colors.tertiary};

      &:hover {
        text-decoration: none;
      }
    }
  `}
`;

export const CreditTitle = styled.h3`
  ${({ theme }) => css`
    ${generateScales('font-size', theme.fontSize.large.mobile, theme.fontSize.large.mobile, theme)};
    line-height: ${theme.lineHeights.sm};
    font-weight: ${theme.fontWeights.bold};
    margin-bottom: 0.5rem;
  `}
`;

export const CTAWrapper = styled.div`
  ${({ theme }) => css`
    max-width: 64rem;
    ${generateScales('padding-bottom', '3.5rem', '5.5rem', theme)}
  `}
`;

export const Title = styled(Text)`
  ${({ theme }) => css`
    ${generateScales('font-size', '2rem', '2.75rem', theme)};
    ${generateScales('margin-bottom', '0.75rem', '1.5rem', theme)}
    line-height: ${theme.lineHeights.sm};
  `}
`;

export const StyledIcon = styled(DownloadIcon)`
  ${({ theme }) => css`
    border-width: ${theme.borderWidths.ui};
    border-style: solid;
  `}
`;

export const IconContainer = styled.div`
  position: relative;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  cursor: pointer;

  :hover svg path {
    stroke: #d741a7;
  }
`;

export const DownloadButton = styled.a`
  ${({ theme }) => css`
    text-decoration: none;
    font-family: ${theme.fontFamily.text};
    font-weight: 500;
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)}
    color: ${theme.colors.primary};
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 0.25rem;

    &:hover {
      color: #d741a7;
    }

    &:hover svg path {
      stroke: #d741a7;
    }
  `}
`;

export const DownloadTitle = styled.p`
  ${({ theme }) => css`
    ${generateScales(
      'font-size',
      theme.fontSize.extraSmall.mobile,
      theme.fontSize.extraSmall.desktop,
      theme
    )}
    margin-bottom: 0.5rem;
    font-family: ${theme.fontFamily.accent};
    line-height: 1;
    font-weight: 500;
    text-transform: uppercase;
    color: ${theme.colors.dark};
    opacity: 0.5;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.accent};
    line-height: ${theme.lineHeights.lg};
    ${generateScales(
      'font-size',
      theme.fontSize.subheading.mobile,
      theme.fontSize.subheading.desktop,
      theme
    )}
    font-weight: 400;
    margin: 0;
  `}
`;

export const StyledDescription = styled.p`
  ${({ theme }) => css`
    margin: 1.25rem 0;
    color: ${theme.colors.light};
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)}
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.base};
  `}
`;

export const BodyImageContainer = styled.div`
  ${({ theme }) => css`
    ${generateScales('font-size', '2rem', '4rem', theme)}
    ${generateScales('margin-top', '1.5rem', '3rem', theme)}
    ${generateScales('margin-bottom', '1.5rem', '3rem', theme)}
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      align-items: center;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    padding: ${theme.padding.md} 0;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      align-items: center;
    }
  `}
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const DescriptionCategory = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop, theme)}
    text-transform: uppercase;
    line-height: ${theme.lineHeights.xs};
    color: ${theme.colors.muted};
    margin-bottom: 1rem;
  `}
`;

export const VideoWrapper = styled.div`
  width: 100%;
  flex: 0 0 48rem;

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.xxl}) {
      flex: 0 0 42rem;
    }

    @media (max-width: ${theme.breakpoints.xl}) {
      flex: 0 0 34rem;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      flex: auto;
    }
  `}
`;

export const VideoMetaContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const VideoLayout = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 4rem;
    grid-template-columns: 1fr 24rem;

    @media (max-width: ${theme.breakpoints.xxl}) {
      column-gap: 3rem;
    }

    @media (max-width: ${theme.breakpoints.xl}) {
      column-gap: 2.5rem;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      row-gap: 2.5rem;
    }

    ${TitleContainer} {
      border-bottom: none;
    }
  `}
`;

export const CodeBlockContainer = styled.div`
  ${({ theme }) => css`
    margin: 3rem 0 0;
    line-height: 1.5;
    border-radius: ${theme.borderRadius.normal};
    font-family: ${theme.fontFamily.mono};
    font-size: ${theme.fontSize.small.desktop};
    max-width: 100%;
  `}
`;

export const RichTextContainer = styled.div`
  ${({ theme }) => css`
    margin: 3rem 0 0;
    font-family: ${theme.fontFamily.text};
    font-size: ${theme.fontSize.body.desktop};
    color: ${theme.colors.dark};
    line-height: 1.6875rem;

    p + p {
      margin-top: 1.5rem;
    }

    p + h1,
    p + h2,
    p + h3,
    p + h4 {
      ${generateScales('margin-top', '1.5rem', '3rem', theme)}
    }

    p + h5,
    p + h6 {
      ${generateScales('margin-top', '1.25rem', '2.5rem', theme)}
    }

    em {
      font-style: italic;
    }

    strong {
      font-weight: bold;
    }

    a,
    a:visited {
      color: ${theme.colors.tertiary};
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: ${theme.fontFamily.display};
      color: ${theme.colors.dark};
      margin: 0;
      line-height: ${theme.lineHeights.base};
      font-weight: bold;

      strong {
        color: ${theme.colors.primary};
      }
    }

    h2 {
      ${generateScales(
        'font-size',
        theme.fontSize.heading2.mobile,
        theme.fontSize.heading2.desktop,
        theme
      )}

      + p {
        ${generateScales('margin-top', '1rem', '1.5rem', theme)}
      }

      + h3,
      + h4,
      + h5,
      + h6 {
        margin-top: 2.5rem;
      }
    }

    h3 {
      ${generateScales(
        'font-size',
        theme.fontSize.heading3.mobile,
        theme.fontSize.heading3.desktop,
        theme
      )}

      + p {
        ${generateScales('margin-top', '1rem', '1.5rem', theme)}
      }

      + h4,
      + h5,
      + h6 {
        margin-top: 3rem;
      }
    }

    h2,
    h3,
    h4 {
      ::after {
        display: block;
        content: '';
        height: 0.125rem;
        width: 3rem;
        background-color: ${theme.colors.secondary};
        ${generateScales('margin-top', '0.5rem', '1rem', theme)}
      }
    }

    h4 {
      ${generateScales(
        'font-size',
        theme.fontSize.heading4.mobile,
        theme.fontSize.heading4.desktop,
        theme
      )}

      + p {
        ${generateScales('margin-top', '0.875rem', '1.75rem', theme)}
      }

      + h5,
      + h6 {
        ${generateScales('margin-top', '1.25rem', '2.5rem', theme)}
      }
    }

    h5 {
      ${generateScales(
        'font-size',
        theme.fontSize.heading5.mobile,
        theme.fontSize.heading5.desktop,
        theme
      )}

      + h6 {
        margin-top: 2.5rem;
      }
    }

    h6 {
      ${generateScales('font-size', '1rem', '1.125rem', theme)}

      + p {
        ${generateScales('margin-top', '0.5rem', '0.75rem', theme)}
      }

      + h2,
      + h3,
      + h4,
      + h5 {
        ${generateScales('margin-top', '0.625rem', '1.25rem', theme)}
      }
    }

    ul,
    ol {
      margin: 2rem 0;
      padding-left: 1.5rem;
    }
    ul {
      li {
        margin-top: 1rem;
        list-style-type: none;
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0.125rem;
          right: calc(100% + 0.75rem);
          margin-left: 0.25rem;
          height: 0.75rem;
          transform: rotate(45deg);
          width: 0.75rem;
          margin-top: 0.25rem;
          border-top: 2px solid ${theme.colors.secondary};
          border-right: 2px solid ${theme.colors.secondary};
        }
      }
    }

    ol {
      li {
        margin-top: 1rem;
        list-style-type: decimal;
      }
    }

    strong em,
    em strong {
      font-style: normal;
      font-family: ${theme.fontFamily.accent};
      font-weight: 400;
      color: ${theme.colors.primary};
      ${generateScales('font-size', '1.125rem', '1.25rem', theme)}
      line-height: ${theme.lineHeights.lg};
      display: block;
    }
  `}
`;

export const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
