import { DefaultTheme } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { ThemeConfigType } from './theme-config.type';

const {
  borderRadius,
  fontFamily,
  fontSize,
  fontWeights,
  lineHeights,
  borderWidths,
  buttonVariants,
  inputSize,
  zIndex,
} = defaultTheme;

export default ({
  border_radius,
  border_widths,
  breakpoints,
  button_variants,
  colors,
  font_family,
  font_size_desktop,
  font_size_mobile,
  font_weights,
  input_size_lg,
  input_size_md,
  input_size_sm,
  line_heights,
  padding,
  scales,
  shadows,
  spacing,
  transitions,
  z_index,
}: ThemeConfigType): DefaultTheme => ({
  borderRadius: {
    small: border_radius[0].small || borderRadius.small,
    normal: border_radius[0].normal || borderRadius.normal,
    large: border_radius[0].large || borderRadius.large,
  },

  fontFamily: {
    display: font_family[0].display || fontFamily.display,
    text: font_family[0].text || fontFamily.text,
    accent: font_family[0].accent || fontFamily.accent,
    mono: font_family[0].mono || fontFamily.mono,
  },

  fontSize: {
    tooltip: {
      desktop: font_size_desktop[0].tooltip || fontSize.tooltip.desktop,
      mobile: font_size_mobile[0].tooltip || fontSize.tooltip.mobile,
    },
    extraSmall: {
      desktop: font_size_desktop[0].extra_small || fontSize.extraSmall.desktop,
      mobile: font_size_mobile[0].extra_small || fontSize.extraSmall.mobile,
    },
    small: {
      desktop: font_size_desktop[0].small || fontSize.small.desktop,
      mobile: font_size_mobile[0].small || fontSize.small.mobile,
    },
    body: {
      desktop: font_size_desktop[0].body || fontSize.body.desktop,
      mobile: font_size_mobile[0].body || fontSize.body.mobile,
    },
    large: {
      desktop: font_size_desktop[0].large || fontSize.large.desktop,
      mobile: font_size_mobile[0].large || fontSize.large.mobile,
    },
    eyebrow: {
      desktop: font_size_desktop[0].eyebrow || fontSize.eyebrow.desktop,
      mobile: font_size_mobile[0].eyebrow || fontSize.eyebrow.mobile,
    },
    subheading: {
      desktop: font_size_desktop[0].subheading || fontSize.subheading.desktop,
      mobile: font_size_mobile[0].subheading || fontSize.subheading.mobile,
    },
    heading6: {
      desktop: font_size_desktop[0].h6 || fontSize.heading6.desktop,
      mobile: font_size_mobile[0].h6 || fontSize.heading6.mobile,
    },
    heading5: {
      desktop: font_size_desktop[0].h5 || fontSize.heading5.desktop,
      mobile: font_size_mobile[0].h5 || fontSize.heading5.mobile,
    },
    heading4: {
      desktop: font_size_desktop[0].h4 || fontSize.heading4.desktop,
      mobile: font_size_mobile[0].h4 || fontSize.heading4.mobile,
    },
    heading3: {
      desktop: font_size_desktop[0].h3 || fontSize.heading3.desktop,
      mobile: font_size_mobile[0].h3 || fontSize.heading3.mobile,
    },
    heading2: {
      desktop: font_size_desktop[0].h2 || fontSize.heading2.desktop,
      mobile: font_size_mobile[0].h2 || fontSize.heading2.mobile,
    },
    heading1: {
      desktop: font_size_desktop[0].h1 || fontSize.heading1.desktop,
      mobile: font_size_mobile[0].h1 || fontSize.heading1.mobile,
    },
  },

  fontWeights: {
    normal: font_weights[0].normal || fontWeights.normal,
    bold: font_weights[0].bold || fontWeights.bold,
  },

  lineHeights: {
    xs: line_heights[0].xs || lineHeights.xs,
    sm: line_heights[0].sm || lineHeights.sm,
    base: line_heights[0].base || lineHeights.base,
    lg: line_heights[0].lg || lineHeights.lg,
    xl: line_heights[0].xl || lineHeights.xl,
  },

  spacing: {
    xxs: spacing[0].xxs || defaultTheme.spacing.xxs,
    xs: spacing[0].xs || defaultTheme.spacing.xs,
    sm: spacing[0].sm || defaultTheme.spacing.sm,
    md: spacing[0].md || defaultTheme.spacing.md,
    lg: spacing[0].lg || defaultTheme.spacing.lg,
    xl: spacing[0].xl || defaultTheme.spacing.xl,
    xxl: spacing[0].xxl || defaultTheme.spacing.xxl,
    xxxl: spacing[0].xxxl || defaultTheme.spacing.xxxl,
    columnGap: spacing[0].column_gap || defaultTheme.spacing.columnGap,
  },

  padding: {
    xs: padding[0].xs || defaultTheme.padding.xs,
    sm: padding[0].sm || defaultTheme.padding.sm,
    md: padding[0].md || defaultTheme.padding.md,
    lg: padding[0].lg || defaultTheme.padding.lg,
    xl: padding[0].xl || defaultTheme.padding.xl,
    cards: {
      sm: padding[0].sm_cards || defaultTheme.padding.cards.sm,
      md: padding[0].md_cards || defaultTheme.padding.cards.md,
      lg: padding[0].lg_cards || defaultTheme.padding.cards.lg,
    },
  },

  colors: {
    transparent: defaultTheme.colors.transparent,
    white: colors[0].white || defaultTheme.colors.white,
    black: colors[0].black || defaultTheme.colors.black,
    primary: colors[0].primary || defaultTheme.colors.primary,
    primaryLight: colors[0].primary_light || defaultTheme.colors.primaryLight,
    secondary: colors[0].secondary || defaultTheme.colors.secondary,
    tertiary: colors[0].tertiary || defaultTheme.colors.tertiary,
    accent: colors[0].accent || defaultTheme.colors.accent,
    accentSecondary: colors[0].accent_secondary || defaultTheme.colors.accentSecondary,
    dark: colors[0].dark || defaultTheme.colors.dark,
    light: colors[0].light || defaultTheme.colors.light,
    muted: colors[0].muted || defaultTheme.colors.muted,
    lightGray: colors[0].light_gray || defaultTheme.colors.lightGray,
  },

  borderWidths: {
    ui: border_widths[0].ui || borderWidths.ui,
    icons: border_widths[0].icons || borderWidths.icons,
  },

  breakpoints: {
    xs: breakpoints[0].xs || defaultTheme.breakpoints.xs,
    sm: breakpoints[0].sm || defaultTheme.breakpoints.sm,
    md: breakpoints[0].md || defaultTheme.breakpoints.md,
    lg: breakpoints[0].lg || defaultTheme.breakpoints.lg,
    xl: breakpoints[0].xl || defaultTheme.breakpoints.xl,
    xxl: breakpoints[0].xxl || defaultTheme.breakpoints.xxl,
  },

  buttonVariants: {
    primary: button_variants[0].primary || buttonVariants.primary,
    white: button_variants[0].white || buttonVariants.white,
    dark: button_variants[0].dark || buttonVariants.dark,
  },

  inputSize: {
    sm: {
      desktop: {
        height: input_size_sm[0].desktop_height || inputSize.sm.desktop.height,
        fontSize: input_size_sm[0].desktop_font_size || inputSize.sm.desktop.fontSize,
        borderRadius: input_size_sm[0].desktop_border_radius || inputSize.sm.desktop.borderRadius,
      },
      mobile: {
        height: input_size_sm[0].mobile_height || inputSize.sm.mobile.height,
        fontSize: input_size_sm[0].mobile_font_size || inputSize.sm.mobile.fontSize,
        borderRadius: input_size_sm[0].mobile_border_radius || inputSize.sm.mobile.borderRadius,
      },
    },
    md: {
      desktop: {
        height: input_size_md[0].desktop_height || inputSize.md.desktop.height,
        fontSize: input_size_md[0].desktop_font_size || inputSize.md.desktop.fontSize,
        borderRadius: input_size_md[0].desktop_border_radius || inputSize.md.desktop.borderRadius,
      },
      mobile: {
        height: input_size_md[0].mobile_height || inputSize.md.mobile.height,
        fontSize: input_size_md[0].mobile_font_size || inputSize.md.mobile.fontSize,
        borderRadius: input_size_md[0].mobile_border_radius || inputSize.md.mobile.borderRadius,
      },
    },
    lg: {
      desktop: {
        height: input_size_lg[0].desktop_height || inputSize.lg.desktop.height,
        fontSize: input_size_lg[0].desktop_font_size || inputSize.lg.desktop.fontSize,
        borderRadius: input_size_lg[0].desktop_border_radius || inputSize.lg.desktop.borderRadius,
      },
      mobile: {
        height: input_size_lg[0].mobile_height || inputSize.lg.mobile.height,
        fontSize: input_size_lg[0].mobile_font_size || inputSize.lg.mobile.fontSize,
        borderRadius: input_size_lg[0].mobile_border_radius || inputSize.lg.mobile.borderRadius,
      },
    },
  },

  scales: {
    xs: scales[0].xs || defaultTheme.scales.xs,
    sm: scales[0].sm || defaultTheme.scales.sm,
    md: scales[0].md || defaultTheme.scales.md,
    lg: scales[0].lg || defaultTheme.scales.lg,
    xl: scales[0].xl || defaultTheme.scales.xl,
    xxl: scales[0].xxl || defaultTheme.scales.xxl,
  },

  shadows: {
    small: shadows[0].small || defaultTheme.shadows.small,
    normal: shadows[0].normal || defaultTheme.shadows.normal,
  },

  transitions: {
    short: transitions[0].short || defaultTheme.transitions.short,
    normal: transitions[0].normal || defaultTheme.transitions.normal,
    long: transitions[0].long || defaultTheme.transitions.long,
  },

  zIndex: {
    navbar: z_index[0].navbar || zIndex.navbar,
    surface: z_index[0].surface || zIndex.surface,
    layeredGraphic: z_index[0].layered_graphic || zIndex.layeredGraphic,
    layeredContent: z_index[0].layered_content || zIndex.layeredContent,
    aboveLayers: z_index[0].above_layers || zIndex.aboveLayers,
  },
});
