import { css } from 'styled-components';

export const TextLink = (color: string, surfaceColor = 'white') => css`
  text-decoration: none;
  position: relative;

  span {
    z-index: 2;
    position: relative;
  }

  :hover span {
    text-shadow: 0px 2px 1px ${surfaceColor}, 1px 1px ${surfaceColor}, -1px -1px ${surfaceColor},
      -1px 1px ${surfaceColor}, 1px -1px ${surfaceColor}, -1px 0px ${surfaceColor},
      0px 1px ${surfaceColor}, 1px 0px ${surfaceColor}, 0px -1px ${surfaceColor};
  }

  ::before {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    border-radius: 1px;
    bottom: 0;
    left: 0;
    background-color: ${color};
    transform-origin: bottom right;
    transition: transform 0.125s ease-in-out;
    z-index: 1;
  }

  &:hover,
  &:focus {
    &::before {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
`;
