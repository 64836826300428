import React from 'react';
import { defaultTheme } from '@styles/theme.styled';
import { StyledActionArrow } from './svg-icon.styled';

interface ButtonProps {
  fillColor?: keyof typeof defaultTheme.colors;
  strokeColor?: keyof typeof defaultTheme.colors;
  width?: number;
  height?: number;
  className?: string;
}

const ActionArrow = ({
  fillColor = 'dark',
  strokeColor = 'white',
  width = 36,
  height = 36,
  className = '',
}: ButtonProps): JSX.Element => (
  <StyledActionArrow
    width={width}
    height={height}
    fillColor={fillColor}
    strokeColor={strokeColor}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      className="action-arrow-fill"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
      d="M18,36c-4.8,0-9.4-1.9-12.7-5.3C1.9,27.4,0,22.8,0,18l0,0c0-4.8,1.9-9.4,5.3-12.7C8.6,1.9,13.2,0,18,0l0,0
	      c4.8,0,9.4,1.9,12.7,5.3c3.4,3.4,5.3,8,5.3,12.7l0,0c0,4.8-1.9,9.4-5.3,12.7C27.4,34.1,22.8,36,18,36L18,36z"
    />
    <path
      className="action-arrow-stroke"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
      d="M26,18H10"
    />
    <path
      className="action-arrow-stroke"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
      d="M20,12l6,6l-6,6"
    />
  </StyledActionArrow>
);

export default ActionArrow;
