import styled, { css } from 'styled-components';
import Caret from '@components/svg-icons/caret';
import { generateScales } from '@helpers/generate-media-queries';
import Text from '@components/text';
import { Link } from 'gatsby';
import { underlineTransition } from '@components/link/link.styled';

export const DropdownContainer = styled.div`
  position: relative;
`;

export const StyledCaret = styled(Caret)`
  ${({ theme }) => css`
    transition: transform 0.25s ease-in-out;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }
  `}
`;

export const GroupCaret = styled(Caret)`
  ${({ theme }) => css`
    transition: transform 0.25s ease-in-out;
    height: 0.675rem;
    width: 0.675rem;
    transform: scaleY(1);

    path {
      fill: ${theme.colors.primary};
    }
  `}
`;

interface StyledDropDownProps {
  isVisible?: boolean;
}

export const StyledDropDown = styled.div<StyledDropDownProps>`
  ${({ theme, isVisible }) => css`
    display: flex;
    row-gap: 2rem;
    column-gap: 2.5rem;
    position: fixed;
    top: 64px;
    ${generateScales('padding', '1.25rem', '2.5rem', theme)}
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.small};
    box-shadow: ${theme.shadows.small};
    visibility: hidden;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      column-gap: 2rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      column-gap: 1.75rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }

    @keyframes drop {
      from {
        opacity: 0;
        transform: translateY(-10px);
        visibility: visible;
      }

      to {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
      }
    }

    ${isVisible &&
    css`
      visibility: visible;
      opacity: 1;
      animation: 0.25s drop ease-in-out;

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        width: 100vw;
        justify-content: center;
        top: 5rem !important;
        left: 0 !important;
      }
    `};
  `}
`;

interface ButtonProps {
  isVisible?: boolean;
  layoutType: 'dark' | 'light';
}

export const Button = styled.button<ButtonProps>`
  ${({ theme, layoutType, isVisible }) => {
    return css`
      display: flex;
      column-gap: 0.25rem;
      align-items: center;
      font-family: ${theme.fontFamily.text};
      font-size: 1.0625rem;
      font-weight: 500;
      color: ${layoutType === 'dark' ? theme.colors.white : theme.colors.primary};
      cursor: pointer;
      background: none;
      border: none;
      margin: 0;
      padding: 0;

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        font-size: 1rem;
      }

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        display: none;
      }

      span {
        ${underlineTransition}
      }

      ${isVisible &&
      css`
        span {
          background-size: 100% 0.15rem;
        }

        ${StyledCaret} {
          transform: scaleY(-1);
        }
      `};
    `;
  }}
`;

export const MobileButton = styled.button<ButtonProps>`
  ${({ theme, layoutType, isVisible }) => {
    return css`
      display: none;
      column-gap: 0.25rem;
      align-items: center;
      font-family: ${theme.fontFamily.display};
      font-size: 1.0625rem;
      font-weight: 500;
      color: ${layoutType === 'dark' ? theme.colors.white : theme.colors.primary};
      cursor: pointer;
      background: none;
      border: none;
      margin: 0;
      padding: 0;

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        cursor: default;
        font-size: 1.125rem;
        margin-bottom: 1rem;
        display: flex;
      }

      ${isVisible &&
      css`
        ${GroupCaret} {
          transform: scaleY(-1);
        }
      `};
    `;
  }}
`;

export const Links = styled.a`
  ${({ theme }) => css`
    ${underlineTransition}
    width: 100%;
    color: ${theme.colors.primary};
    text-decoration: none;
    font-family: ${theme.fontFamily.text};
    font-size: 1rem;
    line-height: ${theme.lineHeights.base};
    font-weight: normal;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      font-size: 1rem;
    }
  `}
`;

export const InternalLinks = styled(Link)`
  ${({ theme }) => css`
    ${underlineTransition}
    width: 100%;
    color: ${theme.colors.primary};
    text-decoration: none;
    font-family: ${theme.fontFamily.text};
    font-size: 1rem;
    line-height: ${theme.lineHeights.base};
    font-weight: normal;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      font-size: 1rem;
    }
  `}
`;

export const AccentLink = styled(Links)`
  ${({ theme }) => css`
    font-size: 1rem;
    color: ${theme.colors.secondary};
    margin-top: 0.375rem;
    display: inline-flex;
    width: fit-content;
  `}
`;

interface TriangleProps {
  layoutType: 'dark' | 'light';
  isVisible?: boolean;
}

export const Triangle = styled.div<TriangleProps>`
  ${({ theme, layoutType, isVisible }) => css`
    position: absolute;
    z-index: 1;
    visibility: ${isVisible ? 'visible' : 'hidden'};
    top: -0.5625rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 0;
    height: 0;
    border-left: 0.75rem solid ${theme.colors.transparent};
    border-right: 0.75rem solid ${theme.colors.transparent};
    border-bottom: 0.625rem solid ${layoutType === 'dark' ? theme.colors.white : theme.colors.white};
  `}
`;

export const Title = styled(Text)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.text};
    font-size: 0.875rem;
    line-height: ${theme.lineHeights.sm};
    color: ${theme.colors.primaryLight};
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0.5rem;
  `}
`;

export const DropDownColumn = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    max-width: 20rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      row-gap: 1rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 100%;
      max-width: unset;
    }
  `}
`;

export const LinkContainer = styled.div`
  :last-child {
    margin-bottom: 0;
  }
`;

export const Description = styled(Title)`
  ${({ theme }) => css`
    margin: 0.25rem 0;
    text-transform: unset;
    color: ${theme.colors.dark};
    font-weight: normal;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: none;
    }
  `}
`;

interface MobileMenuProps {
  layoutType: 'dark' | 'light';
}

export const MobileMenu = styled.div<MobileMenuProps>`
  ${({ theme, layoutType }) => css`
    display: none;
    position: fixed;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      position: unset;
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 2.5rem;
      ${generateScales('padding-bottom', '1rem', '2rem', theme)}
      ${generateScales('padding-left', '1rem', '2rem', theme)}
      margin-top: 1rem;

      ${Title}, ${Description}, ${Links}, ${InternalLinks} {
        ${layoutType === 'dark' ? `color: ${theme.colors.white}` : `color: ${theme.colors.primary}`}
      }

      ${Title}, ${Description} {
        opacity: 0.75;
      }

      ${LinkContainer} {
        padding-left: 1rem;
      }
    }
  `}
`;
