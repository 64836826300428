import styled, { css } from 'styled-components';
import Image from '@components/image';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';

export const Header = styled.header`
  width: 100%;
`;

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    ${generateScales('padding-top', '0.5rem', '1rem', theme)}
    ${generateScales('padding-bottom', '0.5rem', '1rem', theme)}
  `}
`;

export const Content = styled.div`
  ${({ theme }) => `
    z-index: ${theme.zIndex.layeredContent};
    position: relative;
    align-self: flex-start;
    max-width: 60%;
    width: 100%;
    text-shadow: 0 0 2rem ${theme.colors.white};

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      max-width: 65%;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      max-width: 65%;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 65%;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      max-width: 70%;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-width: 65%;
    }

    @media only screen and (max-width: ${theme.breakpoints.xs}) {
      max-width: 70%;
    }
  `}
`;

export const StyledSubheading = styled(Text)`
  ${({ theme }) => `
    width: 70%;

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      width: 75%;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: 80%;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: 90%;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 100%;
    }    
  `}
`;

export const TextContainer = styled.div`
  ${({ theme }) => `
    position: relative;
    z-index: ${theme.zIndex.layeredContent};
    margin: ${theme.spacing.xl} 0;
    align-self: flex-start;
    max-width: 60%;
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      max-width: 40rem;
    }
  `}
`;

export const StyledImage = styled(Image)`
  ${({ theme }) => `
    z-index: ${theme.zIndex.layeredGraphic};
    position: absolute !important;
    top: 0;
    left: 60%;
    width: calc(40% + 4rem);
    height: auto;
    transform: translateX(-2rem);

    @media only screen and (max-width: ${theme.breakpoints.xxl}) {
      width: calc(35% + 4rem);
      left: 65%;
    }

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: calc(35% + 4rem);
      left: 65%;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: calc(35% + 4rem);
      left: 65%;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: 70%;
      left: 70%;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      top: 50%;
      transform: translateX(-2rem) translateY(-50%);
      width: 75%;
      left: 65%;
    }

    @media only screen and (max-width: ${theme.breakpoints.xs}) {
      top: 50%;
      transform: translateX(-2rem) translateY(-50%);
      width: 90%;
      left: 70%;
    }
  `}
`;
