/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { monokai } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Image, { ImageProps } from '@components/image';
import {
  BodyImageContainer,
  ImageContainer,
  VideoContainer,
  RichTextContainer,
  CodeBlockContainer,
} from './paragraphs.shared.styled';

export interface PrimaryProps {
  image: ImageProps;
  code_text: {
    richText: RichTextBlock[];
  };
  code_language: string;
  rich_text: {
    richText: RichTextBlock[];
  };
  video: {
    html: string;
  };
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  subheading?: {
    richText: RichTextBlock[];
  };
}

const renderParagraphs = (type: string, primary: PrimaryProps): JSX.Element | null => {
  switch (type) {
    case 'rich_text_content': {
      if (primary.rich_text.richText.length > 0 && primary.rich_text.richText[0].text !== '') {
        return (
          <RichTextContainer>
            <RichText render={primary.rich_text.richText} />
          </RichTextContainer>
        );
      }
      return null;
    }
    case 'code_block': {
      if (primary.code_text.richText.length > 0 && primary.code_text.richText[0].text !== '') {
        let codeText = '';
        primary.code_text.richText.forEach((code, index) => {
          codeText += code.text;
          if (index + 1 < primary.code_text.richText.length) {
            codeText += `\n`;
          }
        });
        return (
          <>
            {/* @ts-expect-error Server Component */}
            <SyntaxHighlighter
              lineNumberStyle={{ opacity: '0.5' }}
              customStyle={{ padding: '1rem' }}
              PreTag={CodeBlockContainer}
              showLineNumbers
              language={primary.code_language}
              style={monokai}
            >
              {codeText}
            </SyntaxHighlighter>
          </>
        );
      }
      return null;
    }
    case 'image': {
      if (primary.image.url) {
        return (
          <BodyImageContainer>
            <ImageContainer>
              <Image
                url={primary.image.url}
                alt={primary.image.alt}
                gatsbyImageData={primary.image.gatsbyImageData}
                objectFit="contain"
              />
            </ImageContainer>
          </BodyImageContainer>
        );
      }
      return null;
    }
    case 'video': {
      if (primary.video.html) {
        return <VideoContainer dangerouslySetInnerHTML={{ __html: primary.video.html }} />;
      }
      return null;
    }
    default:
      return null;
  }
};

export default renderParagraphs;
