import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Wrapper from '@components/wrapper.styled';
import ActionArrow from '@components/svg-icons/action-arrow';
import { ImageProps } from '@components/image';
import {
  MainContent,
  StyledImage,
  StyledButton,
  StyledSection,
  ContentLayout,
  StyledText,
} from './call-to-action.styled';

interface CallToActionProps {
  description?: RichTextBlock[];
  buttonLabel?: string;
  image: ImageProps;
}

const CallToAction = ({ description = [], buttonLabel, image }: CallToActionProps): JSX.Element => {
  const hasDescription: boolean = description?.length > 0 && description[0].text !== '';
  const hasImage: boolean = image?.url !== null && image?.url !== undefined && image?.url !== '';

  if (!hasDescription && !buttonLabel) {
    return <></>;
  }

  return (
    <StyledSection>
      <Wrapper>
        <ContentLayout>
          {hasImage && (
            <StyledImage
              url={image.url}
              alt={image.alt || ''}
              gatsbyImageData={image.gatsbyImageData}
            />
          )}
          <MainContent>
            {hasDescription && (
              <StyledText
                type="title"
                fontColor="dark"
                size="heading5"
                stringText={description[0].text}
              />
            )}
            <StyledButton variant="white" href="https://www.tripshot.com/contact">
              {buttonLabel}
              <ActionArrow fillColor="primary" />
            </StyledButton>
          </MainContent>
        </ContentLayout>
      </Wrapper>
    </StyledSection>
  );
};

export default CallToAction;
