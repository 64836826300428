import styled, { css } from 'styled-components';
import Text from '@components/text';
import Image from '@components/image';
import { generateScales } from '@helpers/generate-media-queries';
import { TextLink } from '@components/text-link.styled';
import { defaultTheme } from '@styles/theme.styled';

export const StyledCustomLink = styled.a`
  text-decoration: none;
`;

export const ViewLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    display: block;
    width: fit-content;
    text-decoration: none;
    font-family: ${theme.fontFamily.text};
    font-weight: 500;
    ${generateScales('font-size', '1rem', '1.125rem', theme)}

    span {
      position: relative;
    }

    span::before {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      border-radius: 1px;
      bottom: 0;
      left: 0;
      background-color: ${theme.colors.primary};
      transform-origin: bottom right;
      transition: transform 0.125s ease-in-out;
    }

    &:hover {
      span {
        &::before {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }

    &::after {
      content: '\\00A0\\2192';
    }
  `}
`;

export const CategoryLink = styled.a<{ surfaceColor?: keyof typeof defaultTheme.colors }>`
  ${({ theme, surfaceColor = 'white' }) => css`
    color: ${theme.colors.accent};
    ${TextLink(theme.colors.accent, theme.colors[surfaceColor])}
  `}
`;

export const StyledImage = styled(Image)`
  transition: transform 0.3s ease-in-out;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 0 0;
  width: 100%;
  height: auto;
  position: absolute;
`;

export const ImageLink = styled.a`
  display: block;
  text-decoration: none;
  width: 100%;
  padding-bottom: 63%;

  &:hover ${StyledImage} {
    transform: scale(1.25) translate(-50%, -50%);
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 0.75rem;
  width: 100%;
`;

export const FeaturedContainer = styled.div`
  align-items: center;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3rem;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      row-gap: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  `}
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  justify-content: flex-start;
  align-items: flex-start;

  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 100%;
    }
  `}
`;

export const Title = styled(Text)``;

export const Description = styled.p`
  ${({ theme }) => css`
    margin: 0;
    color: ${theme.colors.dark};
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', '1rem', '1.125rem', theme)}
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.lg};
    line-height: 1.5;
  `}
`;

export const Meta = styled.div`
  ${({ theme }) => css`
    margin: 0;
    color: ${theme.colors.muted};
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop, theme)}
    font-weight: ${theme.fontWeights.normal};
    line-height: ${theme.lineHeights.base};
    display: flex;
    flex-direction: row;
    row-gap: 0.375rem;
    column-gap: 0.375rem;
    flex-wrap: wrap;
    transition: all ${theme.transitions.short};
  `}
`;

export const DescriptionCategory = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop, theme)}
    line-height: 1;
    margin-bottom: 0.875rem;

    ${CategoryLink} + ${CategoryLink} {
      position: relative;
      margin-left: 0.625rem;

      ::after {
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        color: ${theme.colors.dark} !important;
        content: ',';
        width: 0.5rem;
        line-height: 1.25;
        text-align: left;
        height: 100%;
        display: block;
        pointer-events: none;
      }
    }
  `}
`;
