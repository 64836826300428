import React from 'react';
import { withPrefix } from 'gatsby';
import { ImageProps } from '@components/image';
import getBlogType from '@helpers/get-blog-type';
import generateId from '@helpers/generate-id';
import getPrettyTypeName from '@helpers/get-pretty-type-name';
import Wrapper from '@components/wrapper.styled';
import TagList from '@components/posts-list/tag-list/tag-list';
import {
  EmptyState,
  PostsGrid,
  StyledLink,
  StyledTextLink,
  TypeLink,
  PostWrapper,
  StyledImage,
  Meta,
  Category,
  PostInfo,
  CategoryLink,
  NoImage,
} from './posts-list.shared.styled';
import {
  PostContent,
  StyledTitle,
  ImageContainer,
  LinkedTitle,
} from './relative-posts-list.styled';

const postsListIdGenerator = generateId();

export interface PostPreviewGraphqlType {
  type: 'book_post' | 'white_paper_post' | 'case_study_post' | 'video_post' | 'post';
  uid: string;
  prismicId: string;
  first_publication_date: string;
  tags: string[];
  data: {
    category: {
      document: {
        prismicId: string;
        data: {
          name: string;
        };
      };
    };
    post_title: {
      text: string;
    };
    post_description: {
      text: string;
    };
    post_image: ImageProps;
  };
}

const PostPreview = ({ node }: { node: PostPreviewGraphqlType }): JSX.Element => {
  const postId = postsListIdGenerator.next().value;
  const imageUrl = node.data.post_image?.url;
  const isImageless = !imageUrl?.trim();

  if (!node.data.post_title.text) {
    return <></>;
  }

  return (
    <PostWrapper key={postId}>
      <ImageContainer>
        {imageUrl ? (
          <StyledLink to={`${getBlogType(node.type, false)}/${node.uid}`}>
            <StyledImage url={imageUrl} alt={node.data.post_image.alt} />
          </StyledLink>
        ) : (
          <NoImage />
        )}
      </ImageContainer>
      <PostContent isImageless={isImageless}>
        <PostInfo>
          {node.data.category?.document?.data?.name && (
            <span>
              <CategoryLink
                href={withPrefix(`/?category=${node.data.category?.document?.prismicId}`)}
                title={`Explore more posts about ${node.data.category?.document?.data?.name}`}
              >
                <Category>{node.data.category?.document?.data?.name}</Category>
              </CategoryLink>
            </span>
          )}
          <StyledTextLink to={`${getBlogType(node.type, false)}/${node.uid}`}>
            <LinkedTitle
              type="title"
              tag="h3"
              size="heading5"
              fontColor="dark"
              stringText={node.data.post_title.text}
            />
          </StyledTextLink>
        </PostInfo>
        <Meta>
          <TypeLink
            href={`${getBlogType(node.type)}/`}
            title={`Explore more ${getPrettyTypeName(node.type)} posts`}
          >
            {getPrettyTypeName(node.type)}
          </TypeLink>
          <TagList tags={node.tags} blogUrl={withPrefix('/')} />
        </Meta>
      </PostContent>
    </PostWrapper>
  );
};

export interface PostsListProps {
  edges: {
    node: PostPreviewGraphqlType;
  }[];
  title?: string;
}

const PostsList = ({ edges = [], title }: PostsListProps): JSX.Element => {
  return (
    <>
      {title && <StyledTitle size="heading4" type="title" stringText={title} fontColor="dark" />}
      <Wrapper>
        {edges.length > 0 ? (
          <PostsGrid>
            {edges.map(({ node }) => (
              <PostPreview node={node} />
            ))}
          </PostsGrid>
        ) : (
          <EmptyState>No posts found.</EmptyState>
        )}
      </Wrapper>
    </>
  );
};

export default PostsList;
