import styled, { css } from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';
import SharePost from '@components/share-post';
import { TextLink } from '@components/text-link.styled';

export const Categories = styled.div`
  color: #d741a7;
  display: flex;
  gap: 0.375rem;
`;

export const MobileMeta = styled.div`
  ${({ theme }) => css`
    display: none;
    @media (max-width: ${theme.breakpoints.md}) {
      display: block;
      border-top: 1px solid ${theme.colors.muted};
      ${generateScales('margin-top', '2rem', '4rem', theme)};
    }
  `}
`;

export const DesktopMeta = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16rem;
    @media (max-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `}
`;

export const CategoryLink = styled.a<{ surfaceColor?: string }>`
  ${({ theme, surfaceColor = 'white' }) => css`
    color: ${theme.colors.accent};
    ${TextLink(theme.colors.accent, surfaceColor)}

    &:not(:last-child) span::after {
      content: ', ';
    }
  `}
`;

export const BookCategoryBar = styled.div`
  ${({ theme }) => css`
    ${generateScales('padding-top', '2rem', '4rem', theme)};
    ${generateScales('padding-bottom', '2rem', '4rem', theme)};
    position: relative;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 10rem;

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.xl}) {
      column-gap: 6rem;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      column-gap: 4rem;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      display: block;
      row-gap: 2.5rem;
    }
  `}
`;

export const BookContainer = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.muted};
    border-radius: ${theme.borderRadius.normal};
    ${generateScales('margin-top', '2rem', '4rem', theme)};
  `}

  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Title = styled(Text)`
  ${({ theme }) => css`
    ${generateScales('margin-bottom', '1.25rem', '2.5rem', theme)};
  `}
`;

export const Description = styled(Text)`
  margin-bottom: 0;
  ${({ theme }) => css`
    ${generateScales('font-size', theme.fontSize.large.mobile, theme.fontSize.large.desktop, theme)}
    font-family: ${theme.fontFamily.accent};
    line-height: ${theme.lineHeights.lg};
    font-weight: ${theme.fontWeights.normal};
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    ${generateScales('padding-top', '1rem', '2rem', theme)}
    ${generateScales('padding-bottom', '3.5rem', '5.5rem', theme)}
  `}
`;

export const DescriptionContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex: 0 0 16rem;
    flex-direction: column;
    row-gap: 1.5rem;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      flex: 0 0 16rem;
      row-gap: 1.25rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex: 0 0 12rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      flex-direction: row;
      column-gap: 3rem;
      ${generateScales('margin-top', '1.5rem', '4rem', theme)}
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      row-gap: 2rem;
    }
  `}
`;

export const DescriptionCategory = styled.div`
  ${({ theme }) => css`
    max-width: 11.94rem;
    font-family: ${theme.fontFamily.text};
    font-weight: ${theme.fontWeights.bold}
    ${generateScales(
      'font-size',
      theme.fontSize.extraSmall.mobile,
      theme.fontSize.extraSmall.desktop,
      theme
    )}
    line-height: ${theme.lineHeights.xs};
    color: ${theme.colors.muted};
      margin-bottom: 0.875rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      margin-bottom: 0.75rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      margin-bottom: 0rem;
    }
  `}
`;

export const Date = styled.div`
  ${({ theme }) => `
    margin-top: 1.5rem;
    color: ${theme.colors.tertiary};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop, theme)}
    line-height: 1;
  `}
`;

export const TitleContainer = styled.div`
  width: 100%;
`;

export const StyledSharePost = styled(SharePost)`
  max-width: 11.94rem;
  border: none;
  padding-top: 0;
  margin-top: 0;
  padding-top: 0;
`;
