const getPrettyTypeName = (
  nameType: 'book_post' | 'white_paper_post' | 'case_study_post' | 'video_post' | 'post'
): string => {
  switch (nameType) {
    case 'video_post':
      return 'video';
    case 'book_post':
      return 'book';
    case 'white_paper_post':
      return 'white paper';
    case 'case_study_post':
      return 'case studies';
    case 'post':
      return 'blog';
    default:
      return 'post';
  }
};

export default getPrettyTypeName;
