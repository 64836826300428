import React from 'react';
import { graphql } from 'gatsby';
import Post, { PostProps } from '@components/post';

const PostPage = ({ data }: PostProps): JSX.Element => <Post data={data} />;

export default PostPage;

export const query = graphql`
  query PostQuery($uid: String, $categoriesArray: [String]) {
    prismicGeneralConfig {
      data {
        favicon {
          url
        }
      }
    }
    prismicPostPageConfig {
      ...PostPageConfigFragment
    }
    currentPost: allPrismicPost(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
    relativePosts: allPrismicPost(
      filter: { tags: { in: $categoriesArray }, uid: { ne: $uid } }
      limit: 3
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          ...PostPreviewFragment
        }
      }
    }
    restPosts: allPrismicPost(
      filter: { uid: { ne: $uid } }
      limit: 3
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          ...PostPreviewFragment
        }
      }
    }
  }
`;
