import React, { useState } from 'react';
import generateId from '@helpers/generate-id';
import { defaultTheme } from '@styles/theme.styled';
import { TagLink, MoreTag } from '../posts-list.shared.styled';

const tagIdGenerator = generateId();

export interface TagListProps {
  tags: string[];
  blogUrl: string;
  hoverColor?: keyof typeof defaultTheme.colors;
  visibleTags?: number;
}

const TagList = ({
  tags,
  blogUrl,
  hoverColor = 'dark',
  visibleTags = 2,
}: TagListProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const handlClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsOpen(true);
  };
  return (
    <>
      {tags.map((tag, index) => (
        <div key={`tag-${tagIdGenerator.next().value}`}>
          {index === visibleTags && (
            <MoreTag
              href="#"
              title="View all tags"
              isOpen={!isOpen}
              onClick={(e) => handlClick(e)}
              hoverColor={hoverColor}
            >
              +{tags.length - visibleTags}
            </MoreTag>
          )}
          <TagLink
            href={`${blogUrl}?tag=${encodeURIComponent(tag)}`}
            title={`Explore more posts about ${tag}`}
            isOpen={index < 2 ? true : isOpen}
            hoverColor={hoverColor}
          >
            {tag}
          </TagLink>
        </div>
      ))}
    </>
  );
};

export default TagList;
