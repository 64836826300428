import React from 'react';

const LinkedinIcon = (): JSX.Element => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 6H33.008C37.974 6 42 10.026 42 14.992V33.01C42 37.974 37.974 42 33.008 42H14.992C10.026 42 6 37.974 6 33.008V15C6 10.03 10.03 6 15 6V6Z"
      stroke="#F3E8DA"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M16.2402 22.2V33"
      stroke="#F3E8DA"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M23.438 33V26.7C23.438 24.214 25.452 22.2 27.938 22.2C30.424 22.2 32.438 24.214 32.438 26.7V33"
      stroke="#F3E8DA"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
    <path
      d="M16.2361 15.676C15.9881 15.676 15.7861 15.878 15.7881 16.126C15.7881 16.374 15.9901 16.576 16.2381 16.576C16.4861 16.576 16.6881 16.374 16.6881 16.126C16.6881 15.876 16.4861 15.676 16.2361 15.676"
      stroke="#F3E8DA"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </svg>
);

export default LinkedinIcon;
