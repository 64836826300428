import React from 'react';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery, withPrefix } from 'gatsby';
import Dropdown, { DropdownItemProps } from '@components/dropdown';
import DropdownCombobox from '../autocomplete/autocomplete';
import {
  Title,
  StyledWrapper,
  Container,
  Categories,
  CategoryList,
  CategoryItem,
  CategoryTitleItem,
  CategoryLink,
  CategoryText,
  InputWrapper,
} from './categories-bar.styled';

interface CategoriesBlockProps {
  className?: string;
}

interface CategoriesQueryInterface {
  allPrismicCategory: {
    edges: [];
  };
}

export const query = graphql`
  query CategoryQuery {
    allPrismicCategory {
      edges {
        node {
          ...CategoryFragment
        }
      }
    }
  }
`;

const CategoriesBlock = ({ className = '' }: CategoriesBlockProps): JSX.Element => {
  const location = useLocation();
  const activeCategory = new URLSearchParams(location.search).get('category') || '';

  const {
    allPrismicCategory: { edges },
  }: CategoriesQueryInterface = useStaticQuery(query);
  const categoryItems: DropdownItemProps[] = edges.map(
    (edge: { node: { prismicId: string; data: { name?: string } } }): DropdownItemProps => {
      return {
        label: edge.node?.data?.name || '',
        link: withPrefix(`/?category=${edge.node?.prismicId}`),
      };
    }
  );
  categoryItems.push({
    label: 'Browse All',
    link: withPrefix('/'),
  });

  return (
    <StyledWrapper>
      <Container className={className}>
        <CategoryList>
          <CategoryTitleItem>
            <Title href={withPrefix('/')}>Resource Center</Title>
          </CategoryTitleItem>
          {edges.map(
            (edge: { node: { prismicId: string; data: { name?: string | undefined } } }) => {
              const to = withPrefix(`/?category=${edge.node?.prismicId}`);
              const isActive = activeCategory === edge.node?.prismicId;

              return (
                <CategoryItem key={edge.node?.prismicId}>
                  {isActive ? (
                    <CategoryText>{edge.node?.data?.name}</CategoryText>
                  ) : (
                    <CategoryLink href={to}>{edge.node?.data?.name}</CategoryLink>
                  )}
                </CategoryItem>
              );
            }
          )}
        </CategoryList>
        <InputWrapper>
          <Categories>
            <Dropdown title="Categories" items={categoryItems} />
          </Categories>
          <DropdownCombobox />
        </InputWrapper>
      </Container>
    </StyledWrapper>
  );
};

export default CategoriesBlock;
