import React from 'react';
import { TextComponentProps } from '@components/text/text-props.type';
import { StyledTitle } from './title.styled';

const Title = ({
  spacing = 'md',
  fontColor = 'primary',
  size = 'heading2',
  tag = 'h1',
  stringText = '',
  className,
}: TextComponentProps): JSX.Element => {
  return (
    <StyledTitle spacing={spacing} size={size} fontColor={fontColor} className={className} as={tag}>
      {stringText}
    </StyledTitle>
  );
};

export default Title;
