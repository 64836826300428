import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import ColorVars from '@styles/color.styled';

const GlobalStyle = createGlobalStyle`

  ${reset}

  :root {
    ${ColorVars}
  }

  body {
    margin: 0;
    font-family: ${(props) => props.theme.fontFamily.text};
    font-size: 1.125rem;
    background-color: ${(props) => props.theme.colors.dark};
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  p {
    margin: 0;
  }
`;

export default GlobalStyle;
