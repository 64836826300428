import styled, { css } from 'styled-components';
import { rotateDownAnimation } from '@components/dropdown/dropdown.styled';
import { generateScales } from '@helpers/generate-media-queries';

export const AutocompleteWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledMenu = styled.ul<{ isOpen: boolean }>`
  ${({ theme, isOpen }) => css`
    ${isOpen &&
    css`
      padding: 0.675rem 0.375rem 0.375rem 0.375rem;
      ${rotateDownAnimation}
    `}
    max-height: 16rem;
    overflow-y: auto;
    background-color: ${theme.colors.white};
    list-style: none;
    position: absolute;
    z-index: 5;
    border-radius: ${theme.borderRadius.normal};
    box-shadow: ${theme.shadows.small};
    inset: calc(100% + 0.25rem) 0 auto 0;

    ::-webkit-scrollbar {
      width: 1rem;
    }

    ::-webkit-scrollbar-track {
      border-top-right-radius: ${theme.borderRadius.normal};
      border-bottom-right-radius: ${theme.borderRadius.normal};
    }

    ::-webkit-scrollbar-thumb {
      border-radius: ${theme.borderRadius.normal};
      background: ${theme.colors.primary};
      background-clip: padding-box;
      border: 0.25rem solid rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors.primary};
      background-clip: padding-box;
      border: 0.25rem solid rgba(0, 0, 0, 0);
    }
  `}
`;

export const GroupInput = styled.div`
  height: 2.5rem;
`;

export const StyledArrow = styled.div<{ isOpen: boolean }>`
  ${({ theme, isOpen }) => css`
    box-sizing: border-box;
    position: absolute;
    inset: 50% 0.75rem auto auto;
    display: block;
    transform: translateY(-50%) rotate(-45deg);
    transform-origin: center center;
    width: 0.75rem;
    height: 0.75rem;
    border: 2px solid ${theme.colors.dark};
    border-radius: 100%;
    transition: all ${theme.transitions.normal};

    ::after {
      content: '';
      display: block;
      box-sizing: border-box;
      position: absolute;
      border-radius: 18.75%;
      width: 2px;
      height: 75%;
      background: currentColor;
      transform: translateX(-50%);
      transform-origin: 0 0;
      top: 100%;
      left: 50%;
      transition: all ${theme.transitions.normal};
    }

    ${isOpen &&
    css`
      /* transform: translateY(-50%) rotateX(180deg); */
    `}
  `}
`;

export const StyledLink = styled.a`
  ${({ theme }) => css`
    display: flex;
    padding: 0.5rem 0.5rem;
    text-decoration: none;
    border-radius: ${theme.borderRadius.small};
    text-align: left;
    color: ${theme.colors.dark};
    ${generateScales('font-size', '0.8375rem', '1rem', theme)}
    line-height: 1;
    justify-content: space-between;

    ::after {
      content: '→';
      display: block;
      color: ${theme.colors.dark}00;
      font-family: ${theme.fontFamily.text};
      font-weight: ${theme.fontWeights.normal};
      ${generateScales('font-size', '0.8375rem', '1rem', theme)}
      line-height: 1;
      text-decoration: none;
      margin-left: 0.375rem;
    }

    :last-child {
      padding-bottom: 0.75rem;
    }

    :hover {
      color: ${theme.colors.white};
      margin-top: -0.25rem;
      background-color: ${theme.colors.primary};
      padding: 0.75rem 0.5rem;

      + a {
        padding-top: 0.25rem;
      }

      ::after {
        color: ${theme.colors.white};
      }
    }
  `}
`;

export const NoItems = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    display: flex;
    padding: 0.5rem 0.5rem 0.75em;
    text-decoration: none;
    border-radius: ${theme.borderRadius.small};
    text-align: left;
    color: ${theme.colors.dark}85;
    ${generateScales('font-size', '0.8375rem', '1rem', theme)}
    line-height: 1;
    justify-content: space-between;
  `}
`;

export const StyleItem = styled.li<{ isHighlighted: boolean }>`
  ${({ theme, isHighlighted }) => css`
    ${isHighlighted &&
    css`
      ${StyledLink} {
        color: ${theme.colors.white};
        margin-top: -0.25rem;
        background-color: ${theme.colors.primary};
        padding: 0.75rem 0.5rem;

        + a {
          padding-top: 0.25rem;
        }

        ::after {
          color: ${theme.colors.white};
        }
      }
    `}
  `}
`;

export const StyledInput = styled.input`
  ${({ theme }) => css`
    text-decoration: none;
    font-family: ${theme.fontFamily.text};
    font-weight: ${theme.fontWeights.normal};
    ${generateScales('font-size', '0.8375rem', '1rem', theme)}
    display: flex;
    width: 100%;
    column-gap: 0.5rem;
    justify-content: space-between;
    align-items: center;
    border-width: ${theme.borderWidths.ui};
    border-style: solid;
    overflow: hidden;
    border-color: ${theme.colors.dark};
    background: ${theme.colors.white};
    color: ${theme.colors.dark};
    height: 2.5rem;
    padding: 0 0.75rem;
    border-radius: 0.25rem;

    ::placeholder {
      color: ${theme.colors.dark}85;
    }

    :hover {
      border-color: ${theme.colors.primary};

      + ${StyledArrow} {
        border-color: ${theme.colors.primary};

        ::after {
          background-color: ${theme.colors.primary};
        }
      }
    }

    :focus {
      outline: 1px solid ${theme.colors.primary};
      border-color: ${theme.colors.primary};

      + ${StyledArrow} {
        border-color: ${theme.colors.primary};

        ::after {
          background-color: ${theme.colors.primary};
        }
      }
    }

    :not(:placeholder-shown) + ${StyledArrow} {
      width: 0;
      top: 50%;
      right: 1rem;
      border: none;
      height: 0.875rem;
      width: 2px;
      transform: translateY(-50%) rotate(-45deg);
      background-color: ${theme.colors.dark};
      border-radius: 0;
      cursor: pointer;

      &::after {
        width: 0;
        top: 50%;
        left: 50%;
        height: 0.875rem;
        width: 2px;
        transform-origin: 50% 50%;
        border: none;
        background-color: ${theme.colors.dark};
        transform: translate(-50%, -50%) rotate(90deg);
        border-radius: 0;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        height: 0.75rem;
        width: 0.75rem;
        transform: translate(-50%, -50%);
      }

      :hover {
        background-color: ${theme.colors.primary} !important;

        ::after {
          background-color: ${theme.colors.primary} !important;
        }
      }
    }
  `}
`;
