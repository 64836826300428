import React, { useEffect, useState } from 'react';
import { graphql, withPrefix } from 'gatsby';
import SEO from '@components/seo';
import TagList from '@components/posts-list/tag-list/tag-list';
import Video from '@components/video';
import { CategoriesBlog } from '@components/module/module.shared.styled';
import createRelativePosts from '@helpers/create-relative-posts';
import Layout from '@layouts/default-layout';
import Section from '@components/section';
import Tout from '@components/tout';
import SharePost from '@components/share-post';
import Text from '@components/text';
import Wrapper from '@components/wrapper.styled';
import {
  VideoLayout,
  VideoMetaContent,
  VideoCategoryBar,
  VideoWrapper,
  VideoSectionWrapper,
} from '@components/paragraphs/paragraphs.shared.styled';
import { ImageProps } from '@components/image';
import { PostsListProps } from '@components/posts-list/relative-posts-list';
import RelativePosts from '@components/relative-posts';
import renderModule from '@components/module';
import generateId from '@helpers/generate-id';
import { Category, Meta } from '@components/posts-list/posts-list.shared.styled';
import { CategoryLink, DescriptionCategory } from '@components/feature-post/feature-post.styled';
import { RichTextBlock } from 'prismic-reactjs';

const postPageIdGenerator = generateId();

interface PostVideoProps {
  data: {
    prismicGeneralConfig: {
      data: {
        favicon?: {
          url?: string;
        };
      };
    };
    currentPost: {
      edges: {
        node: {
          uid: string;
          tags: string[];
          first_publication_date: string;
          data: {
            category: {
              document: {
                prismicId: string;
                data: {
                  name: string;
                };
              };
            };
            post_title: {
              text: string;
            };
            post_description: {
              text: string;
              richText: RichTextBlock[];
            };
            video: {
              html: string;
            };
            post_image: ImageProps;
            header_image: ImageProps;
            call_to_action: {
              document: {
                data: {
                  image?: ImageProps;
                  title?: {
                    text: string;
                  };
                  description?: {
                    text: string;
                    richText: RichTextBlock[];
                  };
                  link: {
                    url: string;
                    target?: string;
                  };
                  link_label: string;
                };
              };
            };
          };
        };
      }[];
    };
    relativePosts?: PostsListProps;
    restPosts?: PostsListProps;
    prismicPostPageConfig: {
      data: {
        title_for_relative_posts: {
          text: string;
        };
        body: [];
      };
    };
  };
}
const PostVideo = ({
  data: {
    prismicGeneralConfig: {
      data: { favicon },
    },
    currentPost,
    relativePosts,
    restPosts,
    prismicPostPageConfig,
  },
}: PostVideoProps): JSX.Element | null => {
  if (!currentPost) return null;

  const { edges } = currentPost;
  const [displayRelativePosts, setDisplayRelativePosts] = useState([] as PostsListProps['edges']);
  const { title_for_relative_posts, body } = prismicPostPageConfig?.data || {};
  const { tags } = edges[0].node;
  const { post_title, post_description, video, post_image, category, call_to_action } =
    edges[0].node.data;
  const getPostUrl = (): string => (typeof window !== 'undefined' ? window.location.href : '');

  useEffect(() => {
    createRelativePosts(relativePosts, restPosts, displayRelativePosts, setDisplayRelativePosts);
  }, []);

  const VideoTextContent = (): JSX.Element => (
    <VideoMetaContent>
      {category?.document?.data?.name && (
        <DescriptionCategory>
          <CategoryLink
            href={withPrefix(`/?category=${category?.document?.prismicId}`)}
            title={`Explore more posts about ${category?.document?.data?.name}`}
            surfaceColor="dark"
          >
            <Category>{category?.document?.data?.name}</Category>
          </CategoryLink>
        </DescriptionCategory>
      )}

      {post_title.text && (
        <Text type="title" stringText={post_title.text} fontColor="white" size="heading3" />
      )}

      {post_description.text && (
        <Text
          type="description"
          richText={post_description.richText}
          fontColor="white"
          spacing="md"
        />
      )}

      <Meta>
        <TagList tags={tags} blogUrl={withPrefix('/')} hoverColor="primary" />
      </Meta>

      <SharePost
        url={getPostUrl()}
        border={false}
        title={post_title.text}
        description={post_description.text}
      />
    </VideoMetaContent>
  );

  return (
    <Layout mode="light">
      <SEO
        title={post_title.text}
        description={post_description.text}
        faviconHref={favicon?.url}
        image={post_image}
      />
      <VideoCategoryBar>
        <CategoriesBlog />
      </VideoCategoryBar>
      <Section backgroundColor="dark">
        <Wrapper>
          <VideoSectionWrapper>
            <VideoLayout>
              <VideoWrapper>
                <Video video={video} />
              </VideoWrapper>
              <VideoTextContent />
            </VideoLayout>
            {call_to_action.document.data.link && (
              <Tout
                title={call_to_action?.document?.data.title}
                description={call_to_action?.document?.data.description}
                image={call_to_action?.document?.data.image}
                link={call_to_action?.document?.data.link}
                link_label={call_to_action?.document?.data.link_label}
                mode="dark"
              />
            )}
          </VideoSectionWrapper>
        </Wrapper>
      </Section>
      {displayRelativePosts?.length > 0 && (
        <RelativePosts edges={displayRelativePosts} title={title_for_relative_posts?.text || ''} />
      )}
      {body?.map(
        (module) =>
          module && (
            <React.Fragment key={postPageIdGenerator.next().value}>
              {renderModule(module)}
            </React.Fragment>
          )
      )}
    </Layout>
  );
};

export default PostVideo;

export const query = graphql`
  query PostVideoQuery($uid: String, $categoriesArray: [String]) {
    prismicGeneralConfig {
      data {
        page_name
        favicon {
          url
        }
      }
    }
    prismicPostPageConfig {
      ...PostPageConfigFragment
    }
    currentPost: allPrismicVideoPost(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          ...VideoPostFragment
        }
      }
    }
    relativePosts: allPrismicVideoPost(
      filter: { tags: { in: $categoriesArray }, uid: { ne: $uid } }
      limit: 3
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          ...VideoPostPreviewFragment
        }
      }
    }
    restPosts: allPrismicVideoPost(
      filter: { uid: { ne: $uid } }
      limit: 3
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          ...VideoPostPreviewFragment
        }
      }
    }
  }
`;
