import React, { useState, useEffect } from 'react';
import { withPrefix } from 'gatsby';
import { tagsEndpoint } from '@constants/api';
import { useCombobox } from 'downshift';
import {
  AutocompleteWrapper,
  StyledMenu,
  StyledInput,
  StyledArrow,
  StyleItem,
  NoItems,
  StyledLink,
  GroupInput,
} from './autocomplete.styled';

const DropdownCombobox = (): JSX.Element => {
  const [tags, setTags] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<string | null | undefined>('');
  const [inputItems, setInputItems] = useState(tags);
  const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps, selectItem } =
    useCombobox({
      items: inputItems,
      onInputValueChange: ({ inputValue = '' }) => {
        setInputItems(
          tags.filter((item) => item.toLowerCase().startsWith(inputValue.toLowerCase()))
        );
      },
      onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
        setSelectedItem(newSelectedItem);
      },
    });

  const onClick = () => {
    const inputProps = getInputProps();
    if (inputProps.value) {
      selectItem(null);
    }
  };

  useEffect(() => {
    if (selectedItem) {
      window.location.href = withPrefix(`/?tag=${encodeURIComponent(selectedItem)}`);
    }
  }, [selectedItem]);

  useEffect(() => {
    Promise.resolve(fetch(tagsEndpoint))
      .then((response) => response.json())
      .then((data: string[]) => {
        const responseTags = data.length > 0 ? data : [];
        const sortedTags = responseTags.sort();
        setInputItems(sortedTags);
        setTags(sortedTags);
      })
      .catch(() => 'fetch error');
  }, []);

  return (
    <AutocompleteWrapper>
      <GroupInput>
        <StyledInput {...getInputProps()} placeholder="Explore more topics" />
        <StyledArrow isOpen={isOpen} onClick={onClick} />
      </GroupInput>
      <StyledMenu {...getMenuProps()} isOpen={isOpen}>
        {isOpen && (
          <>
            {inputItems.length > 0 ? (
              inputItems.map((item, index) => (
                <StyleItem
                  isHighlighted={highlightedIndex === index}
                  key={`${item}`}
                  {...getItemProps({ item, index })}
                >
                  <StyledLink href={withPrefix(`/?tag=${encodeURIComponent(item)}`)}>
                    {item}
                  </StyledLink>
                </StyleItem>
              ))
            ) : (
              <NoItems>No results</NoItems>
            )}
          </>
        )}
      </StyledMenu>
    </AutocompleteWrapper>
  );
};

export default DropdownCombobox;
