import styled from 'styled-components';

export const StyledGrid = styled.div<{ itemsPerRow?: number; itemWith: number }>`
  ${({ theme, itemsPerRow = 3, itemWith }) => `
    justify-content: center;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(${itemsPerRow}, 1fr);

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      grid-gap: 3rem;
      grid-template-columns: repeat(auto-fill, minmax(${itemWith}rem, 1fr))};
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      grid-gap: 2rem;
      grid-template-columns: repeat(auto-fill, minmax(${itemWith}rem, 1fr))};
    }
  `}
`;
