import styled, { css } from 'styled-components';
import Text from '@components/text';
import Image from '@components/image';
import { LargeButton } from '@styles/button.styled';
import { generateScales } from '@helpers/generate-media-queries';

export const StyledSection = styled.section`
  background: linear-gradient(84.88deg, #aa1ef5 0%, #ffb3b4 34.9%, #f3e8da 76.49%);
  padding: 8rem 0;
  position: relative;
  width: 100%;
  ${({ theme }) => `
    @media (max-width: ${theme.breakpoints.xl}) {
      padding: 6rem 0;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      padding: 5rem 0;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: 4rem 0;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    padding: ${theme.padding.cards.lg};
    display: flex;
    justify-content: center;
    background-color: ${theme.colors.primaryLight};
    border-radius: 0.5rem;

    @media (max-width: ${theme.breakpoints.lg}) {
      padding: ${theme.padding.cards.md};
    }

    @media (max-width: ${theme.breakpoints.md}) {
      padding: ${theme.padding.cards.sm};
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      padding: ${theme.padding.cards.sm} 1rem;
    }
  `}
`;

export const StyledButton = styled.a`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.lg;
    const desktopOffet = (parseInt(desktop.height, 10) - parseInt(desktop.height, 10) - 2.25) / 2;
    const mobileOffet = (parseInt(mobile.height, 10) - parseInt(mobile.height, 10) - 2.25) / 2;
    return css`
      ${LargeButton};
      display: flex;
      gap: 1rem;
      svg {
        ${generateScales('margin-right', `${mobileOffet}rem`, `${desktopOffet}rem`, theme)}
      }
    `;
  }}
`;

export const MainContent = styled.div``;

export const ContentLayout = styled.div`
  display: flex;
  column-gap: 4rem;
  width: 100%;
  max-width: 54rem;
  margin: 0 auto;
  align-items: center;

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.md}) {
      row-gap: 2rem;
      flex-direction: column;
      text-align: center;
      ${StyledButton} {
        margin: 0 auto;
      }
    }
  `}
`;

export const StyledText = styled(Text)`
  ${({ theme }) => css`
    display: block;
    margin-bottom: ${theme.spacing.md};
  `}
`;

export const StyledImage = styled(Image)`
  width: 100%;
  max-width: 24rem;
  height: auto;
`;
