import React, { useState, useRef } from 'react';
import { useLocation } from '@reach/router';
import useOnClickOutside from '@hooks/use-on-click-outside';
import generateId from '@helpers/generate-id';
import {
  DropdownContainer,
  StyledDropDown,
  Button,
  Text,
  Links,
  StyledArrow,
} from './dropdown.styled';

export interface DropdownItemProps {
  label: string;
  link: string;
}

export interface DropdownProps {
  items: DropdownItemProps[];
  title: string;
}

const dropdownIdGenerator = generateId();

const Dropdown = ({ title, items }: DropdownProps): JSX.Element => {
  const location = useLocation();
  const activeCategory = new URLSearchParams(location.search).get('category') || null;
  const categoryLabel = activeCategory
    ? items.find((item) => item.link === `/?category=${activeCategory}`)
    : null;

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DropdownContainer ref={ref}>
      <Button onKeyPress={toggle} onClick={toggle} isVisible={isOpen}>
        {categoryLabel?.label || title} <StyledArrow color="white" />
      </Button>
      <StyledDropDown isVisible={isOpen}>
        {items.map(({ label, link }) => {
          return (
            <Links href={link} key={dropdownIdGenerator.next().value}>
              <Text>{label}</Text>
            </Links>
          );
        })}
      </StyledDropDown>
    </DropdownContainer>
  );
};

export default Dropdown;
