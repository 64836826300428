import React from 'react';
import { ImageProps } from '@components/image';
import generateId from '@helpers/generate-id';
import getBlogType from '@helpers/get-blog-type';
import getPrettyTypeName from '@helpers/get-pretty-type-name';
import TagList from './tag-list/tag-list';
import {
  StyledPost,
  PostInfo,
  PostContent,
  ImageLink,
  StyledImage,
  ViewLink,
  EmptyState,
  PostsGrid,
  ImageContainer,
  StyledText,
  TypeLink,
  Meta,
  Category,
  CategoryLink,
} from './posts-list.shared.styled';

const postsListIdGenerator = generateId();

export interface PostsListProps {
  posts: PostPreviewType[];
  blogUrl: string;
}

interface PostDataProps {
  category: {
    id: string;
    data: {
      name: string;
    };
  };
  post_title: {
    text: string;
  }[];
  post_description: {
    text: string;
  }[];
  post_image: ImageProps;
}

export interface PostPreviewType {
  data: PostDataProps;
  uid: string;
  first_publication_date: string;
  tags: string[];
  type: 'book_post' | 'white_paper_post' | 'case_study_post' | 'video_post' | 'post';
}

interface PostImageProps {
  post: PostPreviewType;
}

const PostsList = ({ posts, blogUrl }: PostsListProps): JSX.Element => {
  const currentUrl =
    blogUrl === '/' || blogUrl === '/resources/' ? blogUrl : blogUrl.replace(/\/+$/, '');

  const PostImage = ({ post }: PostImageProps): JSX.Element => {
    const { data, uid, type } = post;
    return data?.post_image?.url ? (
      <ImageContainer>
        <ImageLink
          title={`View this ${getPrettyTypeName(type)} post`}
          href={`${
            currentUrl === '/' || currentUrl === '/resources/' ? `${getBlogType(type)}` : currentUrl
          }/${uid}`}
        >
          <StyledImage
            url={data.post_image.url}
            alt={data.post_image.alt}
            gatsbyImageData={data.post_image.gatsbyImageData}
          />
        </ImageLink>
      </ImageContainer>
    ) : (
      <></>
    );
  };

  const PostContentBlock = ({ post }: PostImageProps): JSX.Element => {
    const { data, uid, tags, type } = post;
    const imageUrl = post.data.post_image?.url;
    const isImageless =
      imageUrl == null || (typeof imageUrl === 'string' && imageUrl.trim().length === 0);

    return (
      <PostContent isImageless={isImageless}>
        <PostInfo>
          {data.category?.data?.name && (
            <span>
              <CategoryLink
                href={`${blogUrl}?category=${data.category?.id}`}
                title={`Explore more posts about ${data.category?.data?.name}`}
              >
                <Category>{data.category?.data?.name}</Category>
              </CategoryLink>
            </span>
          )}
          {data.post_title && data.post_title[0] && (
            <ViewLink
              href={`${
                currentUrl === '/' || currentUrl === '/resources/' ? getBlogType(type) : currentUrl
              }/${uid}`}
            >
              <StyledText
                type="title"
                tag="h3"
                size="heading5"
                fontColor="dark"
                stringText={data.post_title[0].text}
              />
            </ViewLink>
          )}
        </PostInfo>
        <Meta>
          <TypeLink
            href={`${
              currentUrl === '/' || currentUrl === '/resources/' ? getBlogType(type) : blogUrl
            }`}
            title={`Explore more ${getPrettyTypeName(type)} posts`}
          >
            {getPrettyTypeName(type)}
          </TypeLink>
          <TagList tags={tags} blogUrl={blogUrl} />
        </Meta>
      </PostContent>
    );
  };

  const postList =
    posts.length > 0 ? (
      <PostsGrid>
        {posts.map((post) => {
          const postId = postsListIdGenerator.next().value;

          return (
            <StyledPost key={postId}>
              <PostImage post={post} />
              <PostContentBlock post={post} />
            </StyledPost>
          );
        })}
      </PostsGrid>
    ) : (
      <EmptyState>No posts found!</EmptyState>
    );

  return postList;
};

export default PostsList;
