import React, { useState, useRef, useEffect } from 'react';
import Collapsible from 'react-collapsible';
import { reposition } from 'nanopop';
import useOnClickOutside from '@hooks/use-on-click-outside';
import generateId from '@helpers/generate-id';
import { withPrefix } from 'gatsby';
import {
  DropdownContainer,
  StyledDropDown,
  Button,
  MobileButton,
  Links,
  InternalLinks,
  Title,
  StyledCaret,
  Triangle,
  GroupCaret,
  DropDownColumn,
  LinkContainer,
  Description,
  MobileMenu,
} from './menu-dropdown.styled';

const dropdownIdGenerator = generateId();

interface MenuItem {
  link: {
    url: string;
    document: {
      uid: string;
    } | null;
  };
  link_label: string;
  description?: string;
}

interface MenuGroup {
  primary: {
    group_title: {
      text: string;
    };
  };
  items: MenuItem[];
}

interface MenuDropdownProps {
  data: MenuGroup[];
  title?: string;
  layoutType: 'dark' | 'light';
}

const MenuDropdown = React.memo(({ title, data, layoutType }: MenuDropdownProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggle = () => {
    if (ref.current && dropdownRef.current) {
      reposition(ref.current, dropdownRef.current, {
        position: 'bottom-middle',
        margin: 14,
      });
    }
    setIsOpen(!isOpen);
  };

  useOnClickOutside(ref, () => setIsOpen(false));

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const CollapseButton = (isCollapsed = true): JSX.Element => (
    <MobileButton
      onKeyDown={toggle}
      onClick={toggle}
      isVisible={isCollapsed}
      layoutType={layoutType}
    >
      <span>{title}</span> <GroupCaret layoutType={layoutType} />
    </MobileButton>
  );

  const NavDropdown = (): JSX.Element => (
    <StyledDropDown isVisible={isOpen} ref={dropdownRef}>
      <Triangle isVisible={isOpen} layoutType={layoutType} />
      {data.map(({ primary, items }) => (
        <DropDownColumn key={dropdownIdGenerator.next().value}>
          {primary.group_title.text && <Title type="title" stringText={primary.group_title.text} />}
          {items.map(({ link, link_label, description }) => {
            if (link.document !== null && link.document.uid === 'main') {
              return (
                <LinkContainer key={dropdownIdGenerator.next().value}>
                  <InternalLinks to="/" onKeyDown={toggle} onClick={toggle}>
                    {link_label}
                  </InternalLinks>
                </LinkContainer>
              );
            }

            return (
              <LinkContainer key={dropdownIdGenerator.next().value}>
                {link.document !== null ? (
                  <InternalLinks to={`/${link.document.uid}`} onKeyDown={toggle} onClick={toggle}>
                    {link_label}
                  </InternalLinks>
                ) : (
                  <Links href={link.url}>{link_label}</Links>
                )}
                {description && <Description type="title" stringText={description} />}
              </LinkContainer>
            );
          })}
        </DropDownColumn>
      ))}
    </StyledDropDown>
  );

  const ToggleCollapse = (): JSX.Element => (
    <>
      {/* @ts-expect-error Server Component */}
      <Collapsible
        trigger={CollapseButton(false)}
        transitionTime={250}
        easing="ease-out"
        triggerWhenOpen={CollapseButton(true)}
        open
      >
        <MobileMenu layoutType={layoutType}>
          {data.map(({ primary, items }) => (
            <DropDownColumn key={dropdownIdGenerator.next().value}>
              {primary.group_title.text && (
                <Title type="title" stringText={primary.group_title.text} />
              )}
              {items.map(({ link, link_label, description }) => (
                <LinkContainer key={dropdownIdGenerator.next().value}>
                  {link.document !== null ? (
                    <Links
                      href={withPrefix(`/${link.document.uid === 'main' ? '' : link.document.uid}`)}
                    >
                      {link_label}
                    </Links>
                  ) : (
                    <Links href={link.url}>{link_label}</Links>
                  )}
                  {description && <Description type="title" stringText={description} />}
                </LinkContainer>
              ))}
            </DropDownColumn>
          ))}
        </MobileMenu>
      </Collapsible>
    </>
  );

  return (
    <DropdownContainer ref={ref}>
      <Button onKeyDown={toggle} onClick={toggle} isVisible={isOpen} layoutType={layoutType}>
        <span>{title}</span> <StyledCaret layoutType={layoutType} />
      </Button>
      <NavDropdown />
      <ToggleCollapse />
    </DropdownContainer>
  );
});

export default MenuDropdown;
