import React from 'react';
import { BookContainer } from './book.styled';

export interface BookProps {
  iframe_code: string;
}

const Book = ({ iframe_code }: BookProps): JSX.Element | null => {
  return (
    <>
      <BookContainer dangerouslySetInnerHTML={{ __html: iframe_code }} />
    </>
  );
};

export default Book;
