import styled, { css } from 'styled-components';
import Image from '@components/image';
import { TextLink } from '@components/text-link.styled';
import { defaultTheme } from '@styles/theme.styled';
import { hexToRgb } from '@helpers/hex-to-rgb';

export const headerHeight = '5.5rem';
export const headerColor = '#AA1EF5';

export const StyledHeader = styled.nav<{ $isActive: boolean; $isColored: boolean }>`
  ${({ theme, $isColored, $isActive }) => {
    const primaryRgb = hexToRgb(theme.colors.primary);

    return css`
      display: flex;
      padding: 0.5rem 0;
      align-items: center;
      width: 100%;
      min-height: ${headerHeight};
      max-height: max-content;
      position: fixed;
      top: 0;
      z-index: ${theme.zIndex.navbar};
      transition: background-color ${theme.transitions.normal};

      // switch color based on layout color mode
      background-color: ${$isColored === true
        ? `rgba(${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}, 1)`
        : `rgba(${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}, 0)`};

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        min-height: 5rem;
      }

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        min-height: 4rem;
      }

      ${$isActive &&
      css`
        transition: none;
        background-color: rgba(${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}, 1);
      `}
    `;
  }}
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Logo = styled(Image)`
  height: 1.96875rem;
  width: auto;
  display: block;
`;

export const Menu = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: none;
    }
  `}
`;

export const StyledExternalLink = styled.a`
  ${({ theme }) => css`
    padding: 0.5rem;
    margin: 0 0.5rem;
    text-decoration: none;
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.text};
    font-size: ${theme.lineHeights.xs};
    line-height: ${theme.lineHeights.xs};

    :hover {
      text-decoration: underline;
    }

    :last-child {
      padding-right: 0;
      margin-right: 0;
    }
  `}
`;

export const StyledLink = styled.a<{
  $isActive: boolean;
  $isOpaque: boolean;
  linkColor: keyof typeof defaultTheme.colors;
}>`
  ${({ theme, $isActive, linkColor = 'white', $isOpaque }) => css`
    padding: 0rem;
    margin: 0 1rem;
    text-decoration: none;
    color: ${$isOpaque ? theme.colors.white : theme.colors[linkColor]};
    pointer-events: ${$isActive ? 'none' : 'auto'};
    font-family: ${theme.fontFamily.text};
    font-size: ${theme.lineHeights.xs};
    line-height: ${theme.lineHeights.xs};

    ${$isOpaque && TextLink(theme.colors.white, theme.colors.primary)}
    ${!$isOpaque && linkColor === 'white' && TextLink(theme.colors.white, theme.colors.primary)}
    ${!$isOpaque && linkColor === 'dark' && TextLink(theme.colors.dark, theme.colors.white)}
    transition: color 0.5s ease;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      color: ${theme.colors.dark};
      ${TextLink(theme.colors.dark, theme.colors.white)}
    }

    :last-child {
      padding-right: 0;
      margin-right: 0;
    }
  `}
`;
