import React, { useEffect, useState } from 'react';
import Book from '@components/book';
import { useLocation } from '@reach/router';
import getBlogType from '@helpers/get-blog-type';
import { graphql } from 'gatsby';
import getPrettyTypeName from '@helpers/get-pretty-type-name';
import SEO from '@components/seo';
import { CategoriesBlog } from '@components/module/module.shared.styled';
import Layout from '@layouts/default-layout';
import generateId from '@helpers/generate-id';
import {
  CategoryLink,
  DesktopMeta,
  MobileMeta,
  Title,
  TitleContainer,
  Container,
  ContentContainer,
  DescriptionContainer,
  DescriptionCategory,
  Description,
  StyledSharePost,
  BookCategoryBar,
} from '@components/book/book.styled';
import TagList from '@components/posts-list/tag-list/tag-list';
import { ImageProps } from '@components/image';
import Wrapper from '@components/wrapper.styled';
import { Category, TypeLink } from '@components/posts-list/posts-list.shared.styled';
import {
  DownloadButton,
  DownloadContainer,
  DownloadTitle,
  IconContainer,
  TagWrapper,
} from '@components/paragraphs/paragraphs.shared.styled';
import DownloadIcon from '@components/svg-icons/download';
import createRelativePosts from '@helpers/create-relative-posts';
import { PostsListProps } from '@components/posts-list/relative-posts-list';
import RelativePosts from '@components/relative-posts';
import renderModule from '@components/module';

const tagIdGenerator = generateId();
const bookPostIdGenerator = generateId();

interface BookProps {
  data: {
    prismicGeneralConfig: {
      data: {
        favicon?: {
          url?: string;
        };
      };
    };
    currentPost: {
      edges: {
        node: {
          uid: string;
          type: 'book_post' | 'white_paper_post' | 'case_study_post' | 'video_post' | 'post';
          first_publication_date: string;
          data: {
            category: {
              document: {
                prismicId: string;
                data: {
                  name: string;
                };
              };
            };
            post_title: {
              text: string;
            };
            post_description: {
              text: string;
            };
            link: {
              url: string;
            };
            iframe_code: string;
            post_image: ImageProps;
            header_image: ImageProps;
          };
          tags: string[];
        };
      }[];
    };
    relativePosts?: PostsListProps;
    restPosts?: PostsListProps;
    prismicPostPageConfig: {
      data: {
        title_for_relative_posts: {
          text: string;
        };
        body: [];
      };
    };
  };
}

const BookPost = ({
  data: {
    prismicGeneralConfig: {
      data: { favicon },
    },
    currentPost,
    relativePosts,
    restPosts,
    prismicPostPageConfig,
  },
}: BookProps): JSX.Element | null => {
  if (!currentPost) return null;
  const { edges } = currentPost;
  const post = currentPost.edges[0].node;
  const location = useLocation();
  const blogUrl = location.pathname;
  const currentUrl =
    blogUrl === '/' || blogUrl === '/resources/' ? blogUrl : blogUrl.replace(/\/+$/, '');

  const [displayRelativePosts, setDisplayRelativePosts] = useState([] as PostsListProps['edges']);

  const { title_for_relative_posts, body } = prismicPostPageConfig?.data || {};

  useEffect(() => {
    createRelativePosts(relativePosts, restPosts, displayRelativePosts, setDisplayRelativePosts);
  }, []);

  const {
    data: { post_title, iframe_code, post_description, link, post_image },
  } = edges[0].node;

  const getPostUrl = (): string => (typeof window !== 'undefined' ? window.location.href : '');

  const PostMeta = (
    <DescriptionContainer>
      <DescriptionCategory>
        {post.data.category?.document?.prismicId && (
          <CategoryLink
            href={`/?category=${post.data.category.document.prismicId}`}
            surfaceColor="dark"
            key={tagIdGenerator.next().value}
            title={`Explore more ${post.data.category.document.data.name} posts`}
          >
            <Category>{post.data.category.document.data.name}</Category>
          </CategoryLink>
        )}
      </DescriptionCategory>
      <TagWrapper>
        <TypeLink
          href={`${
            currentUrl === '/' || currentUrl === '/resources/'
              ? getBlogType(post.type)
              : getBlogType(post.type)
          }`}
          title={`Explore more ${getPrettyTypeName(post.type)} posts`}
        >
          {getPrettyTypeName(post.type)}
        </TypeLink>
        <TagList tags={post.tags} blogUrl="/" />
      </TagWrapper>
      <DownloadContainer>
        <DownloadTitle>Download</DownloadTitle>
        <DownloadButton href={link.url} target="_blank" rel="noreferrer">
          <IconContainer>
            <DownloadIcon />
          </IconContainer>
          Download PDF
        </DownloadButton>
      </DownloadContainer>
      <StyledSharePost
        textColor="dark"
        strokeColor="primary"
        url={getPostUrl()}
        title={post_title.text}
        description={post_description.text}
      />
    </DescriptionContainer>
  );

  return (
    <Layout mode="light">
      <SEO
        title={post_title.text}
        description={post_description.text}
        faviconHref={favicon?.url}
        image={post_image}
      />
      <BookCategoryBar>
        <CategoriesBlog />
      </BookCategoryBar>
      <Container>
        <Wrapper>
          <ContentContainer>
            <TitleContainer>
              {post_title.text && (
                <Title
                  type="title"
                  tag="h2"
                  size="heading2"
                  stringText={post_title.text}
                  fontColor="dark"
                />
              )}
              {post_description.text && (
                <Description
                  type="title"
                  tag="p"
                  stringText={post_description.text}
                  fontColor="dark"
                />
              )}
            </TitleContainer>
            <DesktopMeta>{PostMeta}</DesktopMeta>
          </ContentContainer>
          <Book iframe_code={iframe_code} />
          <MobileMeta>{PostMeta}</MobileMeta>
        </Wrapper>
      </Container>
      {displayRelativePosts?.length && (
        <RelativePosts edges={displayRelativePosts} title={title_for_relative_posts?.text || ''} />
      )}
      {body?.map(
        (module) =>
          module && (
            <React.Fragment key={bookPostIdGenerator.next().value}>
              {renderModule(module)}
            </React.Fragment>
          )
      )}
    </Layout>
  );
};

export default BookPost;

export const query = graphql`
  query BookQuery($uid: String, $categoriesArray: [String]) {
    prismicGeneralConfig {
      data {
        page_name
        favicon {
          url
        }
      }
    }
    prismicPostPageConfig {
      ...PostPageConfigFragment
    }
    currentPost: allPrismicBookPost(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          ...BookPostFragment
        }
      }
    }
    relativePosts: allPrismicBookPost(
      filter: { tags: { in: $categoriesArray }, uid: { ne: $uid } }
      limit: 3
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          ...BookPostPreviewFragment
        }
      }
    }
    restPosts: allPrismicBookPost(
      filter: { uid: { ne: $uid } }
      limit: 3
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          uid
          first_publication_date
          tags
          data {
            post_title {
              text
            }
            post_description {
              text
            }
            link {
              url
            }
            iframe_code
            link {
              url
            }
            post_image {
              url
              alt
            }
          }
          ...BookPostPreviewFragment
        }
      }
    }
  }
`;
