import styled, { css, keyframes } from 'styled-components';
import Input from '@components/input';
import Button from '@components/button';
import { wrapperWidths } from '@components/wrapper.styled';
import Text from '@components/text';

interface NewsletterWrapperProps {
  isFixed: boolean;
  offsetAmount: number;
}

const hideModal = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
  100% {
    opacity: 0;
    transform: translateY(100%) translateX(-50%);
  }
`;

export const LegalText = styled(Text)`
  ${({ theme }) => css`
    color: #999;

    a {
      color: ${theme.colors.primary};
    }
  `}
`;

export const NewsletterWrapper = styled.div<NewsletterWrapperProps>`
  ${({ theme, isFixed, offsetAmount = 96 }) => css`
    ${isFixed &&
    css`
      position: fixed;
      bottom: ${offsetAmount > 0 ? `calc(${offsetAmount}px + 2rem)` : '1rem'};
      left: 50%;
      transform: translateX(-50%);
      z-index: 101;
      width: ${wrapperWidths.xxl};
      padding: 0 1rem;

      &.animate-out {
        animation: ${hideModal} 0.5s ease-in-out;
      }

      @media only screen and (max-width: ${theme.breakpoints.xxl}) {
        width: ${wrapperWidths.xxl};
      }

      @media only screen and (max-width: ${theme.breakpoints.xl}) {
        width: ${wrapperWidths.xl};
      }

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        width: ${wrapperWidths.lg};
      }

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        position: unset;
        bottom: unset;
        left: unset;
        transform: unset;
        z-index: unset;
        width: unset;
        padding: unset;

        &.animate-out {
          animation: none;
        }
      }
    `}
  `}
`;

export const NewsletterTitle = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 0.375rem;

    /* @media only screen and (max-width: ${theme.breakpoints.md}) {
      h1,
      h2,
      h3,
      h4 {
        color: ${theme.colors.secondary};
      }
    } */
  `}
`;

export const NewsletterHeader = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: flex-start;
`;

export const CloseButton = styled.button`
  ${({ theme }) => css`
    display: block;
    height: 1.75rem;
    width: 1.75rem;
    position: relative;
    color: #999;
    border: none;
    background-color: ${theme.colors.light};
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 2;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      right: 1.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      right: 1rem;
    }

    :hover {
      color: ${theme.colors.white};
      background-color: ${theme.colors.primary};
    }

    ::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.875rem;
      height: 2px;
      border-radius: 1px;
      transform-origin: 0 0;
      background-color: currentColor;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg) translate(-50%, -50%);
    }

    ::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform-origin: 0 0;
      width: 0.875rem;
      height: 2px;
      border-radius: 1px;
      background-color: currentColor;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg) translate(-50%, -50%);
    }
  `}
`;

export const TopButton = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 2rem;
    right: 2rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      right: 1.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      right: 1rem;
    }
  `}
`;

export const NewsletterContainer = styled.div`
  ${({ theme }) => css`
    // layout
    display: flex;
    flex-direction: column;
    max-width: 32rem;
    row-gap: 1.75rem;
    padding: 2rem;
    position: relative;
    overflow: hidden;

    // styling
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.normal};
    box-shadow: ${theme.shadows.normal};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 100%;
      padding: 2rem 1.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 2rem 1rem;
    }

    h3 {
      margin-bottom: 0;
      width: 100%;
    }
  `}
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 1.75rem;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const FormRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    column-gap: 1rem;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: column;
      row-gap: 1rem;
    }
  `}
`;

export const StyledInput = styled(Input)<{ isInvalid: boolean }>`
  ${({ theme, isInvalid }) => css`
    width: 100%;
    padding: 0 0.625rem;
    border-radius: 0.125rem;
    border: 1px solid ${isInvalid ? theme.colors.accent : theme.colors.muted};
    color: ${theme.colors.dark};
    max-height: 2.25rem;
    font-size: ${theme.fontSize.small.desktop};

    :focus {
      border-color: ${theme.colors.primary};

      ::-webkit-input-placeholder {
        color: ${theme.colors.primary};
      }
    }

    ::-webkit-input-placeholder {
      color: ${isInvalid ? theme.colors.accent : theme.colors.primary};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-height: 2.5rem;
    }
  `}
`;

export const HelperText = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.text};
    color: ${theme.colors.accent};
    font-size: ${theme.fontSize.extraSmall.desktop};
    margin-top: 0.375rem;
  `}
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ConfirmationWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    row-gap: 0.25rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${theme.colors.white};
    padding: 2rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      padding: 2rem 1.5rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding: 2rem 1rem;
    }
  `}
`;
export const StyledButton = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.primary};
    border-color: ${theme.colors.primary};
    color: ${theme.colors.white};

    :hover {
      background-color: ${theme.colors.dark};
      border-color: ${theme.colors.dark};
      color: ${theme.colors.white};
    }
  `}
`;
