/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
exports.linkResolver = (doc) => {
  if (doc.uid === 'main') {
    return `/resources`;
  }

  if (doc.type === 'post') {
    return `/blog/${doc.uid}`;
  }

  if (doc.type === 'blog') {
    return `/blog`;
  }

  if (doc.type === 'video_post') {
    return `/videos/${doc.uid}`;
  }

  if (doc.type === 'videos') {
    return `/videos`;
  }

  if (doc.type === 'book_post') {
    return `/ebooks/${doc.uid}`;
  }

  if (doc.type === 'books') {
    return `/ebooks`;
  }

  if (doc.type === 'case_study_post') {
    return `/case-studies/${doc.uid}`;
  }

  if (doc.type === 'case_studies') {
    return `/case-studies}`;
  }

  if (doc.type === 'white_paper_post') {
    return `/white-papers/${doc.uid}`;
  }

  if (doc.type === 'white_papers') {
    return `/white-papers`;
  }

  return '/';
};
