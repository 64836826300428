import React from 'react';
import { useLocation } from '@reach/router';
import generateId from '@helpers/generate-id';
import Dropdown from '@components/dropdown';
import Wrapper from '@components/wrapper.styled';
import PageInfoProps from '../../pages/blog';
import {
  Container,
  NavWrapper,
  NavBlock,
  NavLink,
  NumberLink,
  PrevNavLink,
  DropdownWrapper,
  PageList,
  PageItem,
} from './pagination.styled';

const paginationIdGenerator = generateId();

export interface PageInfoProps {
  page: number | string;
  prev_page: null | string;
  next_page: null | string;
  total_pages: number;
  blogUrl: string;
}

interface PaginationProps {
  pageInfo: PageInfoProps;
}

const Pagination = ({ pageInfo }: PaginationProps): JSX.Element | null => {
  const location = useLocation();
  const activeCategory = new URLSearchParams(location.search).get('category') || '';
  const activePage = new URLSearchParams(location.search).get('page') || '1';
  const activeTag = new URLSearchParams(location.search).get('tag') || '';
  const { total_pages, next_page, prev_page, page, blogUrl } = pageInfo;
  const getPage = (currentPage: number) => {
    const isAnyParam = activeTag || activeCategory || currentPage.toString();

    return `${blogUrl}${
      isAnyParam
        ? `?${activeCategory ? `category=${activeCategory}` : ''}${
            activeTag ? `${activeCategory ? '&' : ''}tag=${activeTag}` : ''
          }${currentPage ? `${activeCategory || activeTag ? '&' : ''}page=${currentPage}` : ''}`
        : ''
    }`;
  };
  const getLinkPage = (item: number | string) => (typeof item === 'number' && getPage(item)) || '';
  const restPages = () => {
    const currentPage = parseInt(activePage, 10);
    const range = (start: number, stop: number) =>
      Array.from({ length: stop - start }, (_, i) => start + i);
    const gap = total_pages >= 7 && currentPage < total_pages - 3 ? ['...'] : [];
    const start_gap = currentPage - 1 > 2 ? ['...'] : [];

    if (total_pages <= 5) {
      return range(1, total_pages + 1);
    }

    if (currentPage < 3) {
      return [...range(1, 6), ...gap, total_pages];
    }

    if (currentPage > total_pages - 5) {
      return [1, ...start_gap, ...range(total_pages - 4, total_pages + 1)];
    }

    return [...start_gap, ...range(currentPage - 2, currentPage + 3), ...gap, total_pages];
  };
  const pageDropdownItems = (total: number) => {
    const pages = [];
    for (let index = 1; index <= total; index += 1) {
      pages.push({
        label: `Page ${index}`,
        link: getLinkPage(index),
      });
    }
    return pages;
  };

  return total_pages > 1 ? (
    <Wrapper>
      <Container>
        <NavWrapper>
          <NavLink
            href={getPage(+page - 1)}
            isDisabled={!prev_page}
            aria-label="View previous page"
          >
            &larr; Prev
          </NavLink>
          <NavBlock>
            <DropdownWrapper>
              <Dropdown title={`Page ${activePage}`} items={pageDropdownItems(total_pages)} />
            </DropdownWrapper>
            <PageList>
              {restPages().map((item) => (
                <PageItem key={paginationIdGenerator.next().value}>
                  <NumberLink
                    href={getLinkPage(item)}
                    isDisabled={item === '...' || activePage === item}
                    isActive={
                      typeof item === 'number' ? item === page : parseInt(item, 10) === page
                    }
                  >
                    <span>{item === '...' ? '  ...  ' : `Page ${item}`}</span>
                  </NumberLink>
                </PageItem>
              ))}
            </PageList>
          </NavBlock>
          <PrevNavLink
            href={getPage(+page + 1)}
            isDisabled={!next_page}
            aria-label="View next page"
          >
            Next &rarr;
          </PrevNavLink>
        </NavWrapper>
      </Container>
    </Wrapper>
  ) : (
    <></>
  );
};

export default Pagination;
