import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import CallToAction from '@components/call-to-action';
import PageHero from '@components/page-hero';
import { ImageProps } from '@components/image';
import { HeaderBackgroundProps } from '@components/svg-icons/header-background';
import PostsListContainer from '@components/posts-list-container';
import SmallHero from '@components/small-hero';
import CategoriesTag from '@components/categories';
import { PostPreviewGraphqlType } from '@components/posts-list/relative-posts-list';

export interface PrimaryProps {
  backgroundColor?: HeaderBackgroundProps['fillColor'];
  title?: {
    richText: RichTextBlock[];
    text: string;
  };
  category?: {
    data?: {
      name?: string;
    };
  };
  description?: {
    richText: RichTextBlock[];
  };
  eyebrow?: string;
  subtitle?: {
    richText: RichTextBlock[];
    text: string;
  };
  subheading?: {
    richText: RichTextBlock[];
    text: string;
  };
  link: {
    url: string;
    target?: string;
  };
  link_label: string;
  image: ImageProps;
  sign_up_form?: boolean;
  post_title?: {
    text: string;
  };
  input_placeholder?: string;
  button_label: string;
  button_link: {
    url: string;
    target: string;
  };
}

export interface ModuleProps {
  slice_type: string;
  slice_label: string;
  primary: PrimaryProps;
  postsType?: string;
}

const renderModule = (
  { slice_type, primary, postsType = 'post' }: ModuleProps,
  featuredPost?: PostPreviewGraphqlType
): JSX.Element | null => {
  switch (slice_type) {
    case 'page_hero':
      return (
        <PageHero
          title={primary.title?.text}
          subheading={primary.subheading?.richText}
          image={primary.image}
          isFormEnabled={primary.sign_up_form}
        />
      );
    case 'call_to_action':
      return (
        <CallToAction
          description={primary.description?.richText}
          buttonLabel={primary.button_label}
          image={primary.image}
        />
      );
    case 'posts_list':
      return (
        <PostsListContainer primary={primary} postsType={postsType} featuredPost={featuredPost} />
      );
    case 'category':
      return <CategoriesTag title={primary.title?.text} />;
    case 'small_hero':
      return (
        <SmallHero
          backgroundColor={primary.backgroundColor}
          eyebrow={primary.eyebrow}
          title={primary.title?.text}
          subheading={primary.subheading?.richText}
        />
      );
    default:
      return null;
  }
};

export default renderModule;
