import styled from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScalingQueries, generateScales } from '@helpers/generate-media-queries';

export const StyledEyebrow = styled.p<{
  size: keyof typeof defaultTheme.fontSize;
  fontColor: keyof typeof defaultTheme.colors;
}>`
  ${({ theme, fontColor, size }) => {
    const bottomMargin: string = theme.spacing.sm;

    return `
      font-weight: 600;
      font-family: ${theme.fontFamily.accent};
      line-height: ${theme.lineHeights.xs};
      color: ${theme.colors[fontColor]};
      margin: 0 0 ${bottomMargin} 0;

      ${generateScales(
        'font-size',
        theme.fontSize[size].mobile,
        theme.fontSize[size].desktop,
        theme
      )}

      ${generateScalingQueries('margin-bottom', bottomMargin, theme)}
      `;
  }}
`;
