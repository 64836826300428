import { PostsListProps } from '@components/posts-list/relative-posts-list';

const createRelativePosts = (
  relativePosts: PostsListProps | undefined,
  restPosts: PostsListProps | undefined,
  displayRelativePosts: PostsListProps['edges'],
  setDisplayRelativePosts: React.Dispatch<React.SetStateAction<PostsListProps['edges']> | []>
): void => {
  const relativePostsLength = relativePosts?.edges?.length || 0;
  const restPostsEdges = restPosts?.edges;

  if (relativePostsLength >= 3) {
    setDisplayRelativePosts(relativePosts?.edges?.slice(0, 3) || []);
  } else if (!relativePostsLength && restPostsEdges?.length) {
    setDisplayRelativePosts(restPostsEdges);
  } else if (relativePostsLength < 2 && restPostsEdges?.length) {
    const restPostsArray = restPostsEdges?.slice(0, 3 - relativePostsLength);
    const resultPostsArray = displayRelativePosts?.concat(restPostsArray);
    setDisplayRelativePosts(resultPostsArray);
  }
};

export default createRelativePosts;
