import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import SEO from '@components/seo';
import { RichTextBlock } from 'prismic-reactjs';
import renderParagraphs from '@components/paragraphs';
import getBlogType from '@helpers/get-blog-type';
import createRelativePosts from '@helpers/create-relative-posts';
import Image, { ImageProps } from '@components/image';
import getPrettyTypeName from '@helpers/get-pretty-type-name';
import renderModule from '@components/module';
import Layout from '@layouts/default-layout';
import TagList from '@components/posts-list/tag-list/tag-list';
import Wrapper from '@components/wrapper.styled';
import {
  PageContainer,
  TopMeta,
  MetaContainer,
  HeroImageContainer,
  PostContainer,
  Categories,
  SubMeta,
  TextSubMeta,
  PostBody,
  AuthorName,
  PrefixAuthorName,
  TagWrapper,
  DownloadTitle,
  DownloadButton,
  TitleContainer,
  CategoryLink,
  IconContainer,
  DownloadContainer,
  Description,
  Title,
  CTAWrapper,
} from '@components/paragraphs/paragraphs.shared.styled';
import RelativePosts from '@components/relative-posts';
import { PostsListProps } from '@components/posts-list/relative-posts-list';
import { CategoriesPost } from '@components/module/module.shared.styled';
import { Category, TypeLink } from '@components/posts-list/posts-list.shared.styled';
import SharePost from '@components/share-post';
import generateId from '@helpers/generate-id';
import DownloadIcon from '@components/svg-icons/download';
import Tout from '@components/tout';

export interface CategoriesProps {
  category: {
    id: string;
    document: {
      data: {
        color: string;
        name: {
          text: string;
        };
      };
    };
  };
}

const postPageIdGenerator = generateId();

export interface PostProps {
  data: {
    prismicGeneralConfig: {
      data: {
        favicon?: {
          url?: string;
        };
      };
    };
    prismicPostPageConfig: {
      data: {
        title_for_relative_posts: {
          text: string;
        };
        body: [];
      };
    };
    currentPost: {
      edges: {
        node: {
          type: 'book_post' | 'white_paper_post' | 'case_study_post' | 'video_post' | 'post';
          uid: string;
          first_publication_date: string;
          tags: string[];
          data: {
            category: {
              document: {
                prismicId: string;
                data: {
                  name: string;
                };
              };
            };
            post_image: ImageProps;
            header_image?: ImageProps;
            post_title: {
              text: string;
            };
            author_name: {
              text: string;
            };
            author_photo: ImageProps;
            body: [];
            post_description: {
              text: string;
              richText: RichTextBlock[];
            };
            link: {
              url: string;
            };
            call_to_action: {
              document: {
                data: {
                  image?: ImageProps;
                  title?: {
                    text: string;
                  };
                  description?: {
                    text: string;
                    richText: RichTextBlock[];
                  };
                  link: {
                    url: string;
                    target?: string;
                  };
                  link_label: string;
                };
              };
            };
          };
        };
      }[];
    };
    relativePosts?: PostsListProps;
    restPosts?: PostsListProps;
  };
}

const Post = ({
  data: {
    currentPost,
    relativePosts,
    restPosts,
    prismicPostPageConfig,
    prismicGeneralConfig: {
      data: { favicon },
    },
  },
}: PostProps): JSX.Element | null => {
  if (!currentPost) return null;
  const location = useLocation();
  const blogUrl = location.pathname;
  const [displayRelativePosts, setDisplayRelativePosts] = useState([] as PostsListProps['edges']);
  const post = currentPost.edges[0].node;
  const { title_for_relative_posts, body } = prismicPostPageConfig?.data || {};
  const headerImage = post.data.header_image?.url ? post.data.header_image : post.data.post_image;
  const currentUrl =
    blogUrl === '/' || blogUrl === '/resources/' ? blogUrl : blogUrl.replace(/\/+$/, '');

  useEffect(() => {
    createRelativePosts(relativePosts, restPosts, displayRelativePosts, setDisplayRelativePosts);
  }, []);

  const getPostUrl = (): string => (typeof window !== 'undefined' ? window.location.href : '');

  return (
    <Layout mode="light">
      <SEO
        title={post.data.post_title.text || 'Blog'}
        description={post.data.post_description.text}
        faviconHref={favicon?.url || ''}
        image={post.data.post_image}
      />
      <PageContainer>
        <CategoriesPost />
        {(post.data.post_title.text || post.data.post_description.text) && (
          <Wrapper>
            <PostContainer>
              <>
                <MetaContainer>
                  <TopMeta>
                    {post.data.category?.document?.prismicId && (
                      <Categories>
                        <CategoryLink
                          href={`/?category=${post.data.category?.document?.prismicId}`}
                          surfaceColor="dark"
                          title={`Explore more ${post.data.category?.document?.data?.name} posts`}
                        >
                          <Category>{post.data.category?.document?.data?.name}</Category>
                        </CategoryLink>
                      </Categories>
                    )}
                    {post.data.author_name.text && (
                      <SubMeta>
                        <TextSubMeta>
                          <AuthorName>
                            <PrefixAuthorName>by </PrefixAuthorName>
                            {post.data.author_name.text}
                          </AuthorName>
                        </TextSubMeta>
                      </SubMeta>
                    )}
                    <TagWrapper>
                      <TypeLink
                        href={`${
                          currentUrl === '/' || currentUrl === '/resources/'
                            ? getBlogType(post.type)
                            : getBlogType(post.type)
                        }`}
                        title={`Explore more ${getPrettyTypeName(post.type)} posts`}
                      >
                        {getPrettyTypeName(post.type)}
                      </TypeLink>
                      <TagList tags={post.tags} blogUrl="/" />
                    </TagWrapper>
                  </TopMeta>
                  <SharePost
                    textColor="dark"
                    strokeColor="primary"
                    url={getPostUrl()}
                    title={post.data.post_title.text}
                    description={post.data.post_description.text}
                  />
                  {post.data.link.url !== '' && post.data.link.url !== null && (
                    <DownloadContainer>
                      <DownloadTitle>Download</DownloadTitle>
                      <DownloadButton href={post.data.link.url} target="_blank" rel="noreferrer">
                        <IconContainer>
                          <DownloadIcon />
                        </IconContainer>
                        Download PDF
                      </DownloadButton>
                    </DownloadContainer>
                  )}
                </MetaContainer>
                <PostBody>
                  {headerImage?.url && (
                    <HeroImageContainer>
                      <Image
                        gatsbyImageData={headerImage.gatsbyImageData}
                        url={headerImage.url}
                        alt={headerImage.url}
                      />
                    </HeroImageContainer>
                  )}
                  <TitleContainer>
                    {post.data.post_title.text && (
                      <Title type="title" stringText={post.data.post_title.text} fontColor="dark" />
                    )}
                    {post.data.post_description.text && (
                      <Description>{post.data.post_description.text}</Description>
                    )}
                  </TitleContainer>
                  {post.data.body.map(({ slice_type, primary }) => (
                    <React.Fragment key={postPageIdGenerator.next().value}>
                      {renderParagraphs(slice_type, primary)}
                    </React.Fragment>
                  ))}
                </PostBody>
              </>
            </PostContainer>
          </Wrapper>
        )}
        {post.data.call_to_action?.document?.data.link && (
          <CTAWrapper>
            <Wrapper>
              <Tout
                title={post.data.call_to_action?.document?.data.title}
                description={post.data.call_to_action?.document?.data.description}
                image={post.data.call_to_action?.document?.data.image}
                link={post.data.call_to_action?.document?.data.link}
                link_label={post.data.call_to_action?.document?.data.link_label}
                mode="light"
              />
            </Wrapper>
          </CTAWrapper>
        )}
        {!!displayRelativePosts?.length && (
          <RelativePosts
            edges={displayRelativePosts}
            title={title_for_relative_posts?.text || ''}
          />
        )}
        {body?.map(
          (module) =>
            module && (
              <React.Fragment key={postPageIdGenerator.next().value}>
                {renderModule(module)}
              </React.Fragment>
            )
        )}
      </PageContainer>
    </Layout>
  );
};

export default Post;
