import React from 'react';
import { defaultTheme } from '@styles/theme.styled';
import { StyledSVG } from './svg-icon.styled';

interface ButtonProps {
  color?: keyof typeof defaultTheme.colors;
  width?: number;
  height?: number;
  className?: string;
}

const Arrow = ({
  color = 'dark',
  width = 12,
  height = 20,
  className = '',
}: ButtonProps): JSX.Element => (
  <StyledSVG
    color={color}
    width={width}
    height={height}
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2 18.0002L10 10.0002L2 2.00024"
      stroke={color}
      strokeWidth={defaultTheme.borderWidths.icons}
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
    />
  </StyledSVG>
);

export default Arrow;
