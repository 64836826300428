import React from 'react';
import { StyledFooterBG } from './svg-icon.styled';

const FooterBG = (): JSX.Element => (
  <StyledFooterBG
    width="3440"
    height="1440"
    viewBox="0 0 3440 1440"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
  >
    <path
      fill="white"
      d="M2016.5,1430.7L0,709.9C0,709.9,0,0,0,0h3440c0,0,0,958.4,0,958.4l-1321.2,472.3
	      C2085.8,1442.5,2049.6,1442.5,2016.5,1430.7z"
    />
  </StyledFooterBG>
);

export default FooterBG;
