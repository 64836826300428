import * as React from 'react'

import {
    PrismicPreviewProvider,
    componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'

import { linkResolver } from './src/helpers/linkResolver';
import BlogPage from './src/pages/blog';
import VideoPage from './src/pages/videos';
import CaseStudyPage from './src/pages/case-studies';
import BooksPage from './src/pages/ebooks';
import WhitePapersPage from './src/pages/white-papers';
import PostPage from './src/templates/post';
import BookPost from './src/templates/book-post';
import CaseStudyPostPage from './src/templates/case-study-post';
import PostVideo from './src/templates/video-post';
import WhitePaperPostPage from './src/pages/white-papers';

export const wrapRootElement = ({ element }) => (
    <PrismicPreviewProvider
        repositoryConfigs={[
        {
            repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
            linkResolver,
            componentResolver: componentResolverFromMap({
            blog: BlogPage,
            post: PostPage,
            videos: VideoPage,
            case_studies: CaseStudyPage,
            books: BooksPage,
            white_papers: WhitePapersPage,
            white_paper_post: WhitePaperPostPage,
            book_post: BookPost,
            case_study_post: CaseStudyPostPage,
            video_post: PostVideo,
            }),
        },
        ]}
    >
        {element}
    </PrismicPreviewProvider>
)