import { css } from 'styled-components';
import { StyledSVG } from '@components/svg-icons/svg-icon.styled';
import { generateScales } from '@helpers/generate-media-queries';

interface ButtonProps {
  variant?: 'dark' | 'white' | 'primary' | 'secondary';
  outlined?: boolean;
  size?: string;
}

export const Button = css<ButtonProps>`
  // base button
  ${({ theme }) => css`
    text-decoration: none;
    font-family: ${theme.fontFamily.accent};
    font-weight: 500;
    font-size: ${theme.fontSize.body.desktop};
    cursor: pointer;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    border-width: ${theme.borderWidths.ui};
    border-style: solid;
    text-wrap: nowrap;
  `}

  // dark variant
  ${({ theme, variant, outlined }) =>
    variant === 'dark' &&
    css`
      border-color: ${theme.colors.dark};
      background: ${theme.colors.dark};
      color: ${theme.colors.white};

      :hover {
        color: ${theme.colors.dark};
        background-color: ${theme.colors.white};
        border-color: ${theme.colors.white};
      }

      // icon
      ${StyledSVG} {
        stroke: ${theme.colors.primary};
      }

      // dark outlined
      ${outlined &&
      css`
        background-color: transparent;
        color: ${theme.colors.dark};

        :hover {
          color: ${theme.colors.white};
          background-color: ${theme.colors.dark};
          border-color: ${theme.colors.dark};
        }
      `}
    `}

    // white variant
    ${({ theme, variant, outlined }) =>
    variant === 'white' &&
    css`
      border-color: ${theme.colors.white};
      background: ${theme.colors.white};
      color: ${theme.colors.dark};

      :hover {
        color: ${theme.colors.white};
        background-color: ${theme.colors.primary};
        border-color: ${theme.colors.primary};
      }

      // icon
      ${StyledSVG} {
        stroke: ${theme.colors.primary};
      }

      // white outlined
      ${outlined &&
      css`
        background-color: transparent;
        color: ${theme.colors.white};

        // icon
        ${StyledSVG} {
          stroke: ${theme.colors.dark};
        }

        :hover {
          color: ${theme.colors.primary};
          background-color: ${theme.colors.white};
          border-color: ${theme.colors.white};
        }
      `}
    `}

    // primary variant
    ${({ theme, variant, outlined }) =>
    variant === 'primary' &&
    css`
      border-color: ${theme.colors.primary};
      background: ${theme.colors.primary};
      color: ${theme.colors.white};

      :hover {
        color: ${theme.colors.dark};
        background-color: ${theme.colors.white};
        border-color: ${theme.colors.white};
      }

      // icon
      ${StyledSVG} {
        stroke: ${theme.colors.dark};
      }

      // primary outlined
      ${outlined &&
      css`
        background-color: transparent;
        color: ${theme.colors.primary};

        // icon
        ${StyledSVG} {
          stroke: ${theme.colors.dark};
        }

        :hover {
          color: ${theme.colors.white};
          background-color: ${theme.colors.primary};
          border-color: ${theme.colors.primary};
        }
      `}
    `}

    // secondary variant
    ${({ theme, variant, outlined }) =>
    variant === 'secondary' &&
    css`
      border-color: ${theme.colors.secondary};
      background: ${theme.colors.secondary};
      color: ${theme.colors.dark};

      :hover {
        color: ${theme.colors.white};
        background-color: ${theme.colors.dark};
        border-color: ${theme.colors.dark};
      }

      // icon
      ${StyledSVG} {
        stroke: ${theme.colors.dark};
      }

      // primary outlined
      ${outlined &&
      css`
        background-color: transparent;
        color: ${theme.colors.secondary};

        // icon
        ${StyledSVG} {
          stroke: ${theme.colors.dark};
        }

        :hover {
          color: ${theme.colors.white};
          background-color: ${theme.colors.secondary};
          border-color: ${theme.colors.secondary};
        }
      `}
    `}
`;

export const LargeButton = css`
  ${Button}

  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.lg;

    return `
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}
      ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('height', mobile.height, desktop.height, theme)}
    `;
  }}
`;

export const MediumButton = css`
  ${Button}

  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.md;

    return `
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}
      ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('height', mobile.height, desktop.height, theme)}
      `;
  }}
`;

export const SmallButton = css`
  ${Button}

  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.sm;

    return `
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize, theme)}
      ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius, theme)}
      ${generateScales('height', mobile.height, desktop.height, theme)}
      `;
  }}
`;
