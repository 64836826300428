import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { underlineTransition } from '@components/link/link.styled';

interface LinkItemProps {
  layoutType: 'dark' | 'light';
}

interface StyledLinkProps {
  $isActive: boolean;
}

export const StyledLink = styled(Link)<StyledLinkProps>`
  ${({ theme, $isActive = false }) => css`
    ${underlineTransition}
    text-decoration: none;
    pointer-events: ${$isActive ? 'none' : 'auto'};
    color: ${theme.colors.dark};
    font-family: ${theme.fontFamily.display};
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 1rem;
  `}
`;

export const StyledExternalLink = styled.a`
  ${({ theme }) => css`
    ${underlineTransition}
    text-decoration: none;
    color: ${theme.colors.white};
    font-family: ${theme.fontFamily.display};
    font-size: 1.125rem;
    font-weight: 500;
    /* margin: 0 ${theme.spacing.xs}; */
    /* padding: ${theme.spacing.xs}; */
    margin-bottom: 1rem;

    :hover {
      text-decoration: underline;
    }

    :last-child {
      padding-right: 0;
      margin-right: 0;
    }
  `}
`;

export const StyledBorderedLink = styled(StyledLink)`
  ${({ theme }) => css`
    display: none;
    align-items: center;
    padding: 0 0.75rem;
    height: 2rem;
    border: ${theme.borderWidths.icons} solid ${theme.colors.secondary};
    color: ${theme.colors.dark} !important;
    background-color: ${theme.colors.secondary};
    border-radius: 1.25rem;
    font-weight: 500;
    font-family: ${theme.fontFamily.display};
    font-size: 0.9375rem;
    background-image: none;
    margin-bottom: 0;

    :hover {
      background-color: ${theme.colors.dark};
      border-color: ${theme.colors.dark};
      color: ${theme.colors.white} !important;
      text-decoration: none;
    }
  `}
`;

export const LinkContainer = styled.div`
  position: relative;
  margin-right: 1.25rem;
`;

export const LinkItem = styled.li<LinkItemProps>`
  ${({ theme, layoutType }) => css`
    ${StyledLink} {
      color: ${layoutType === 'dark' ? theme.colors.white : theme.colors.primary};
    }

    ${StyledBorderedLink} {
      color: ${layoutType === 'dark' ? theme.colors.white : theme.colors.primary};
    }
  `}
`;

const linePxWidth = 2;

export const Line = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: ${linePxWidth}px;
    border-radius: ${linePxWidth / 2}px;
    transition: all ${theme.transitions.normal};
    position: relative;
    transform-origin: 1px;
  `}
`;

interface BurgerButtonProps {
  isActive: boolean;
  layoutType: 'dark' | 'light';
}

export const BurgerButton = styled.div<BurgerButtonProps>`
  display: none;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    ${({ theme, isActive, layoutType }) => css`
      position: relative;
      width: 2rem;
      height: 2rem;
      padding: 0.25rem;
      border-radius: 100%;
      background-color: transparent;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      ${Line} {
        background: ${layoutType === 'dark' ? theme.colors.white : theme.colors.primary};
      }

      ${isActive &&
      css`
        ${Line} {
          :nth-child(1) {
            transform: translateX(2.5px) rotate(45deg);
          }

          :nth-child(2) {
            opacity: 0;
            transform: translateX(20px);
          }

          :nth-child(3) {
            transform: translateX(2.5px) rotate(-45deg);
          }
        }
      `}
    `}
  }
`;

interface MobileContentProps {
  isVisible?: boolean;
  headerHeight?: number;
  layoutType: 'dark' | 'light';
}

export const MobileContent = styled.ul<MobileContentProps>`
  ${({ theme, isVisible, headerHeight = 118, layoutType }) => css`
    display: none;
    padding: 0 0 2.5rem 0;
    margin: 0;
    opacity: 0;
    top: 0;
    bottom: 0;
    transform: translateY(-100%);
    background: ${layoutType === 'dark' ? theme.colors.dark : theme.colors.white};
    filter: drop-shadow(${theme.shadows.normal});
    transition: transform ${theme.transitions.normal}, visibility, opacity ${theme.transitions.long};
    position: fixed;
    width: 100%;
    z-index: ${theme.zIndex.navbar - 1};
    padding-top: ${headerHeight}px;
    visibility: hidden;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }

    ${isVisible &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    `};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      display: initial;
    }
  `}
`;

export const MobileMenu = styled.div`
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

export const MobileContactButton = styled(StyledBorderedLink)`
  ${({ theme }) => css`
    display: none;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: flex;
    }
  `}
`;
