import React from 'react';
import { StyledHeaderBackground } from './svg-icon.styled';

export interface HeaderBackgroundProps {
  fillColor: 'Launch Purple' | 'Deep Purple' | 'Purple Gradient' | 'Bright Moon';
}

const HeaderBackgroundSvg = ({ fillColor }: HeaderBackgroundProps): JSX.Element => {
  const colorVal = (colorName: string) => {
    switch (colorName) {
      case 'Launch Purple':
        return { start: '#AA1EF5', end: '#AA1EF5' };
      case 'Deep Purple':
        return { start: '#3D1152', end: '#3D1152' };
      case 'Purple Gradient':
        return { start: '#AA1EF5', end: '#3D1152' };
      case 'Bright Moon':
        return { start: '#F3E8DA', end: '#F3E8DA' };
      default:
        return { start: '#fff', end: '#fff' };
    }
  };
  const fillColors = colorVal(fillColor);

  return (
    <StyledHeaderBackground
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 3440 1440"
      xmlSpace="preserve"
      preserveAspectRatio="none"
    >
      <path
        d="M3440,0H0v866.7l1605.6,554.1c74.1,25.6,154.6,25.6,228.7,0L3440,866.7V0z"
        fill="url(#headerGradient)"
      />
      <defs>
        <linearGradient id="headerGradient" x1="0" x2="0" y1="0" y2="1">
          <stop offset="5%" stopColor={fillColors.start} />
          <stop offset="95%" stopColor={fillColors.end} />
        </linearGradient>
      </defs>
    </StyledHeaderBackground>
  );
};

export default HeaderBackgroundSvg;
