import styled, { css } from 'styled-components';
import Image from '@components/image';
import { Link } from 'gatsby';
import Wrapper from '@components/wrapper.styled';
import { generateScales } from '@helpers/generate-media-queries';
import { TextLink } from '@components/text-link.styled';
import Text from '@components/text';

export const LegalText = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop, theme)}
  `}
`;

export const StyledFooter = styled.footer`
  position: relative;
  z-index: 99;

  ${({ theme }) => css`
    ${generateScales('padding-top', '3.5rem', '5.5rem', theme)}
    ${generateScales('padding-bottom', '3.5rem', '5.5rem', theme)}
    background: ${theme.colors.light};
    color: ${theme.colors.dark};
  `}
`;

export const StyledWrapper = styled(Wrapper)`
  position: relative;
  z-index: 2;
`;

export const Content = styled.div`
  ${({ theme }) =>
    css`
      display: flex;
      justify-content: space-between;
      column-gap: 2rem;

      @media (max-width: ${theme.breakpoints.lg}) {
        flex-direction: column;
        row-gap: 2rem;
      }
    `}
`;

export const StyledDescription = styled(Text)`
  ${({ theme }) =>
    css`
      line-height: ${theme.lineHeights.lg};
    `}
`;

export const ContentBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  ${({ theme }) => css`
    ${generateScales('font-size', '0.925rem', '1rem', theme)}
    ${generateScales('margin-top', '3.5rem', '5.5rem', theme)}

    @media (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      row-gap: 4rem;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      row-gap: 2rem;
    }
  `}
`;

export const ContentTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  row-gap: 4rem;
  flex-direction: column-reverse;

  ${({ theme }) => css`
    ${generateScales('column-gap', '3rem', '6rem', theme)}

    @media (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column-reverse;
    }
  `}
`;

export const BrandContent = styled.div`
  ${({ theme }) => css`
    max-width: calc(50% - 1rem);
    @media (max-width: ${theme.breakpoints.lg}) {
      max-width: calc(66.666% - 1.5rem);
    }

    @media (max-width: ${theme.breakpoints.md}) {
      max-width: unset;
    }
  `}
`;

export const Logo = styled(Image)`
  ${({ theme }) => generateScales('max-height', '1.5rem', '2rem', theme)}
  width: auto;
  display: block;
  ${({ theme }) => generateScales('margin-bottom', '1rem', '1.5rem', theme)}
`;

export const SocialMediasContainer = styled.div`
  display: flex;
  margin-top: auto;
  margin-left: 1rem;
`;

export const SocialMedia = styled.a`
  ${({ theme }) => css`
    display: flex;
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    margin: 0 0 0 1rem;
    background-color: ${theme.colors.primary};
    padding: 0.5rem;

    @media (max-width: ${theme.breakpoints.sm}) {
      margin: 0 0 0 ${theme.spacing.sm};
      :nth-of-type(1) {
        margin: 0;
      }
    }
  `}
`;

export const Links = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    @media (max-width: ${theme.breakpoints.md}) {
      margin-top: 0 0 0 ${theme.spacing.sm};
      row-gap: 2rem;
      flex-direction: column;
    }
  `}
`;

export const NavLinks = styled.nav`
  width: 100%;
`;

export const NavColumn = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 4rem;
  column-gap: 2rem;

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.xl}) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: 1fr 1fr;
    }
  `}
`;

export const StyledNavInternalLink = styled(Link)`
  ${({ theme }) => css`
    ${TextLink(`#644175`)}
    color: #644175;
    font-family: ${theme.fontFamily.text};
    line-height: ${theme.lineHeights.sm};
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)}
  `}
`;

export const StyledNavLink = styled.a`
  ${({ theme }) => css`
    ${TextLink(`#644175`)}
    color: #644175;
    font-family: ${theme.fontFamily.text};
    line-height: ${theme.lineHeights.sm};
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)}
  `}
`;

export const NavColumnItem = styled.li`
  :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const SectionTitle = styled.p`
  margin: 0 0 1.5rem 0;

  ${({ theme }) => css`
    font-family: ${theme.fontFamily.display};
    font-weight: 500;
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)}
  `}
`;

export const MetaLinks = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const StyledLink = styled.a`
  ${({ theme }) => css`
    color: #644175;
    font-family: ${theme.fontFamily.text};
    ${generateScales('font-size', theme.fontSize.small.mobile, theme.fontSize.small.desktop, theme)}
    line-height: ${theme.lineHeights.sm};
    ${TextLink(theme.colors.dark)}

    @media (max-width: ${theme.breakpoints.sm}) {
      text-align: center;
    }
  `}
`;
