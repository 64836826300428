import styled from 'styled-components';
import Input from '@components/input';

export const Form = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;

    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      align-items: center;
    }
  `}
`;

export const StyledInput = styled(Input)`
  ${({ theme }) => `
    margin-right: 1rem;

    @media (max-width: ${theme.breakpoints.md}) {
      margin: 0 0 1rem;
    }
  `}
`;
