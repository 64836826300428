import styled, { css } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';
import { MediumButton, LargeButton } from '@styles/button.styled';
import Image from '@components/image';
import { defaultTheme } from '@styles/theme.styled';

export const GradientWrapper = styled.div<{ layoutType?: string }>`
  ${({ theme, layoutType }) => css`
    padding: 1.5rem;
    background: linear-gradient(
      90deg,
      ${theme.colors.accentSecondary} 0%,
      ${theme.colors.tertiary} 25%,
      ${theme.colors.primaryLight} 75%,
      ${theme.colors.primary} 100%
    );
    border-radius: 1rem;

    @media (max-width: ${theme.breakpoints.xl}) {
      padding: 1.25rem;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      padding: 1rem;
    }

    ${layoutType === 'Column' &&
    css`
      max-width: 63.5rem;
      width: 100%;
      margin: 0 auto;
    `}
  `}
`;

export const Container = styled.div<{ backgroundColor?: keyof typeof defaultTheme.colors }>`
  ${({ theme, backgroundColor = 'white' }) => css`
    margin: 0 auto;
    border-radius: 0.5rem;
    background-color: ${theme.colors[backgroundColor]};
    display: flex;
    padding: 3rem;
    column-gap: 3rem;

    @media (max-width: ${theme.breakpoints.xl}) {
      padding: 2.5rem;
    }

    @media (max-width: ${theme.breakpoints.lg}) {
      row-gap: 2rem;
      padding: 2rem;
    }

    @media (max-width: ${theme.breakpoints.md}) {
      flex-direction: column;
      padding: 2rem 1.5rem;
      row-gap: 1.5rem;
    }

    @media (max-width: ${theme.breakpoints.sm}) {
      padding: 1.75rem 1rem;
    }
  `}
`;

export const ImageContainer = styled(Image)`
  ${({ theme }) => css`
    justify-content: stretch;
    align-items: stretch;
    max-width: 14rem;
    max-height: 14rem;
    height: 100%;
    width: 100%;

    @media (max-width: ${theme.breakpoints.xl}) {
      max-width: 11rem;
      max-height: 11rem;
      margin: 0 auto;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-height: 8rem;
      max-width: 8rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      max-height: 7rem;
      max-width: 7rem;
    }
  `}
`;

interface ContentProps {
  hasImage: boolean;
}

export const Content = styled.div<ContentProps>`
  ${({ theme, hasImage }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 2rem;

    ${hasImage === false
      ? css`
          padding: 0 2rem;
          @media only screen and (max-width: ${theme.breakpoints.lg}) {
            flex-direction: column;
            text-align: center;
            row-gap: 2rem;
            padding: 0;
          }
        `
      : css`
          @media only screen and (max-width: ${theme.breakpoints.xl}) {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 2rem;
          }

          @media only screen and (max-width: ${theme.breakpoints.md}) {
            align-items: center;
            text-align: center;
          }
        `}
  `}
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LinkButton = styled.a`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.lg;
    const desktopOffet = (parseInt(desktop.height, 10) - parseInt(desktop.height, 10) - 2.25) / 2;
    const mobileOffet = (parseInt(mobile.height, 10) - parseInt(mobile.height, 10) - 2.25) / 2;
    return css`
      ${LargeButton};

      @media only screen and (max-width: ${theme.breakpoints.xl}) {
        ${MediumButton};
      }

      display: flex;
      gap: 1rem;
      svg {
        ${generateScales('margin-right', `${mobileOffet}rem`, `${desktopOffet}rem`, theme)}
      }
    `;
  }}
`;
