import React from 'react';
import styled, { css } from 'styled-components';
import DropdownMenu from '@components/dropdown-menu';
import { useLocation } from '@reach/router';
import generateId from '@helpers/generate-id';
import { Link } from 'gatsby';
import { underlineTransition } from '@components/link/link.styled';

const idGenerator = generateId();

interface LinkItemProps {
  layoutType: 'dark' | 'light';
}

interface StyledLinkProps {
  $isActive: boolean;
}

export const StyledLink = styled(Link)<StyledLinkProps>`
  ${({ theme, $isActive = false }) => css`
    ${underlineTransition}
    text-decoration: none;
    pointer-events: ${$isActive ? 'none' : 'auto'};
    font-family: ${theme.fontFamily.text};
    font-size: 1.0625rem;
    font-weight: 500;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      font-size: 1rem;
    }
  `}
`;

export const StyledExternalLink = styled.a`
  ${({ theme }) => css`
    ${underlineTransition}
    text-decoration: none;
    color: ${theme.colors.dark};
    font-family: ${theme.fontFamily.text};
    font-size: 1.0625rem;
    font-weight: 500;

    :last-child {
      padding-right: 0;
      margin-right: 0;
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      font-size: 1rem;
    }
  `}
`;

export const StyledExternalBorderedLink = styled.a`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 0 0.75rem;
    height: 2rem;
    border: ${theme.borderWidths.icons} solid ${theme.colors.secondary};
    color: ${theme.colors.dark} !important;
    background-color: ${theme.colors.secondary};
    border-radius: 1.25rem;
    font-weight: 500;
    font-family: ${theme.fontFamily.display};
    font-size: 0.9375rem;
    background-image: none;
    text-decoration: none;

    :hover {
      background-color: ${theme.colors.dark};
      border-color: ${theme.colors.dark};
      color: ${theme.colors.white} !important;
      text-decoration: none;
    }
  `}
`;

export const StyledBorderedLink = styled(StyledLink)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 0 0.75rem;
    height: 2rem;
    border: ${theme.borderWidths.icons} solid ${theme.colors.secondary};
    color: ${theme.colors.dark} !important;
    background-color: ${theme.colors.secondary};
    border-radius: 1.25rem;
    font-weight: 500;
    font-family: ${theme.fontFamily.display};
    font-size: 0.9375rem;
    background-image: none;
    text-decoration: none;

    :hover {
      background-color: ${theme.colors.dark};
      border-color: ${theme.colors.dark};
      color: ${theme.colors.white} !important;
      text-decoration: none;
    }
  `}
`;

const LinkContainer = styled.div`
  position: relative;
  margin-right: 1.25rem;
`;

export const LinkItem = styled.li<LinkItemProps>`
  ${({ theme, layoutType }) => css`
    ${StyledLink} {
      color: ${layoutType === 'dark' ? theme.colors.white : theme.colors.dark};
    }

    ${StyledBorderedLink} {
      color: ${layoutType === 'dark' ? theme.colors.white : theme.colors.dark};
    }

    ${StyledExternalLink} {
      color: ${layoutType === 'dark' ? theme.colors.white : theme.colors.dark};
    }
  `}
`;

export interface NavbarLinksType {
  link_label: string;
  link_url: {
    url: string;
    target: string;
    document: {
      uid: string;
    };
  };
  dropdown_menu: {
    document: {
      uid: string;
      data: {
        title: {
          text: string;
        };
        body: {
          primary: {
            group_title: {
              text: string;
            };
          };
          items: {
            link: {
              url: string;
              document: {
                uid: string;
              };
            };
            link_label: string;
            description: string;
          }[];
        }[];
      };
    };
  };
}

const renderNavbarLinks = (
  navbarLinks: NavbarLinksType[],
  layoutType: 'dark' | 'light'
): JSX.Element[] =>
  navbarLinks.map((link) => {
    const location = useLocation();
    const { link_label, link_url, dropdown_menu } = link;
    const { document, url, target } = link_url;

    const linkType = (): JSX.Element => {
      // is internal link
      if (document !== null) {
        const to = document.uid === '/main' ? '/' : `/${document.uid}`;
        if (to === '/careers') {
          return (
            <LinkContainer>
              <StyledLink to={to} $isActive={location.pathname === to}>
                {link_label}
              </StyledLink>
            </LinkContainer>
          );
        }
        return (
          <StyledLink to={to} $isActive={location.pathname === to}>
            {link_label}
          </StyledLink>
        );
      }

      if (dropdown_menu.document) {
        return (
          <DropdownMenu
            title={dropdown_menu.document.data.title.text}
            data={dropdown_menu.document.data.body}
            layoutType={layoutType}
          />
        );
      }

      if (link_label === 'Contact') {
        return <StyledExternalBorderedLink href={url}>{link_label}</StyledExternalBorderedLink>;
      }

      // external link
      return (
        <StyledExternalLink href={url} target={target !== null ? target : '_self'}>
          {link_label}
        </StyledExternalLink>
      );
    };

    return (
      <LinkItem key={idGenerator.next().value} layoutType={layoutType}>
        {linkType()}
      </LinkItem>
    );
  });

export default renderNavbarLinks;
