import React from 'react';

const TwitterIcon = (): JSX.Element => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      strokeWidth="2.5"
      stroke="#F3E8DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
      d="M15,6h18c5,0,9,4,9,9v18c0,5-4,9-9,9H15c-5,0-9-4-9-9V15C6,10,10,6,15,6L15,6z"
    />
    <path
      strokeWidth="2.5"
      stroke="#F3E8DA"
      strokeLinecap="round"
      strokeLinejoin="round"
      vectorEffect="non-scaling-stroke"
      d="M29.1,33.3C23,38,12.5,30.7,12.5,30.7c0,0,3.6-1.2,3.6-1.2c-4-4.1-4.3-10.1-1.2-14.4c1.5,2.7,4.2,5.3,7.2,6
      	c0.1-3.5,2.5-6,6-6c2.4,0,3.8,0.9,4.8,2.4h3.6l-2.4,3.6C34.1,21.1,35.4,28.2,29.1,33.3z"
    />
  </svg>
);

export default TwitterIcon;
