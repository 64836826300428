import React from 'react';
import { RichText } from 'prismic-reactjs';
import { TextComponentProps } from '@components/text/text-props.type';
import { StyledDescription } from './description.styled';

const Description = ({
  fontColor = 'dark',
  richText,
  size = 'body',
  className,
  spacing = 'none',
}: TextComponentProps): JSX.Element => (
  <StyledDescription size={size} fontColor={fontColor} className={className} spacing={spacing}>
    <RichText render={richText} />
  </StyledDescription>
);

export default Description;
