import React, { useState } from 'react';
import TwitterIcon from '@components/svg-icons/twitter';
import LinkedinIcon from '@components/svg-icons/linkedin';
import EmailIcon from '@components/svg-icons/email';
import FacebookIcon from '@components/svg-icons/facebook';
import Tooltip from '@components/tooltip';
import copyOnClick from '@helpers/copy-on-ckick';
import { defaultTheme } from '@styles/theme.styled';
import generateId from '@helpers/generate-id';
import { Container, Title, Media, MediaIcon, MediaShareIcon } from './share-post.styled';

const sharePostIdGenerator = generateId();

interface SharePostProps {
  textColor?: keyof typeof defaultTheme.colors;
  url: string;
  title: string;
  description: string;
  className?: string;
  strokeColor?: keyof typeof defaultTheme.colors;
  border?: boolean;
}

const SharePost = ({
  border = true,
  textColor = 'muted',
  strokeColor = 'light',
  url,
  title,
  description,
  className = '',
}: SharePostProps): JSX.Element => {
  const [isCopied, setIsCopied] = useState(false);
  const tooltipText = isCopied ? 'Copied!' : `Copy URL`;
  const handleButtonMouseLeave = () => {
    setIsCopied(false);
  };
  const media = [
    {
      name: 'Twitter',
      link: `https://twitter.com/intent/tweet?url=${url}&text=${title}`,
      icon: <TwitterIcon />,
    },
    {
      name: 'LinkedIn',
      link: `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${description}`,
      icon: <LinkedinIcon />,
    },
    {
      name: 'Email',
      link: `mailto:?body=${url}`,
      icon: <EmailIcon />,
    },
    {
      name: 'Facebook',
      link: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      icon: <FacebookIcon />,
    },
  ];

  return (
    <Container className={className} $hasBorder={border}>
      <Title textColor={textColor}>Share</Title>
      <Media>
        {media.map(({ link, icon, name }) => {
          return name !== 'share' ? (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              key={sharePostIdGenerator.next().value}
              title={`Share this post on ${name}`}
            >
              <MediaIcon strokeColor={strokeColor}>{icon}</MediaIcon>
            </a>
          ) : (
            <Tooltip
              text={tooltipText}
              handleMouseLeave={handleButtonMouseLeave}
              key={sharePostIdGenerator.next().value}
            >
              <MediaShareIcon
                role="button"
                onClick={() => copyOnClick(url, () => setIsCopied(true))}
                strokeColor={strokeColor}
              >
                {icon}
              </MediaShareIcon>
            </Tooltip>
          );
        })}
      </Media>
    </Container>
  );
};

export default SharePost;
