import React from 'react';
import generateId from '@helpers/generate-id';
import getBlogType from '@helpers/get-blog-type';
import { withPrefix } from 'gatsby';
import TagList from '@components/posts-list/tag-list/tag-list';
import getPrettyTypeName from '@helpers/get-pretty-type-name';
import { TypeLink, Category, NoImage } from '@components/posts-list/posts-list.shared.styled';
import { PostPreviewGraphqlType } from '@components/posts-list/relative-posts-list';
import Text from '@components/text';
import {
  StyledImage,
  ViewLink,
  CategoryLink,
  ImageLink,
  ImageContainer,
  FeaturedContainer,
  DescriptionContainer,
  DescriptionCategory,
  Meta,
} from './feature-post.styled';

const featurePostIdGenerator = generateId();

interface FeaturePostProps {
  post?: PostPreviewGraphqlType;
  blogUrl: string;
}

const FeaturePost = ({ post, blogUrl }: FeaturePostProps): JSX.Element | null => {
  if (!post) {
    return null;
  }

  const { uid, data, tags, type } = post;

  const postId = featurePostIdGenerator.next().value;
  const currentUrl =
    blogUrl === '/' || blogUrl === '/resources/' ? blogUrl : blogUrl.replace(/\/+$/, '');

  return (
    <FeaturedContainer key={postId}>
      <ImageContainer>
        {data.post_image?.url ? (
          <ImageLink
            href={`${
              currentUrl === '/' || currentUrl === '/resources/'
                ? `${getBlogType(type)}`
                : currentUrl
            }/${uid}`}
            title="View this post"
          >
            <StyledImage
              url={data.post_image.url}
              alt={data.post_image.alt}
              gatsbyImageData={data.post_image.gatsbyImageData}
              objectFit="cover"
            />
          </ImageLink>
        ) : (
          <NoImage />
        )}
      </ImageContainer>
      <DescriptionContainer>
        <div>
          {data.category?.document?.data?.name && (
            <DescriptionCategory>
              <CategoryLink
                href={withPrefix(`${blogUrl}?category=${data.category?.document?.prismicId}`)}
                title={`Explore more posts about ${data.category?.document?.data?.name}`}
              >
                <Category>{data.category?.document?.data?.name}</Category>
              </CategoryLink>
            </DescriptionCategory>
          )}
          {data.post_title && (
            <Text
              type="title"
              tag="h2"
              size="heading3"
              spacing="md"
              fontColor="dark"
              stringText={data.post_title.text}
            />
          )}
          <ViewLink
            href={`${
              currentUrl === '/' || currentUrl === '/resources/'
                ? `${getBlogType(type)}`
                : currentUrl
            }/${uid}`}
          >
            <span>View Post</span>
          </ViewLink>
        </div>
        <Meta>
          <TypeLink
            href={`${
              currentUrl === '/' || currentUrl === '/resources/' ? getBlogType(type) : blogUrl
            }`}
            title={`Explore more ${getPrettyTypeName(type)} posts`}
          >
            {getPrettyTypeName(type)}
          </TypeLink>
          <TagList tags={tags} blogUrl={withPrefix('/')} />
        </Meta>
      </DescriptionContainer>
    </FeaturedContainer>
  );
};

export default FeaturePost;
