import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';

interface StyledSVGProps {
  color: keyof typeof defaultTheme.colors;
}

export const StyledSVG = styled.svg<StyledSVGProps>`
  ${({ theme, color }) => css`
    stroke-width: ${theme.borderWidths.icons};
    stroke: ${theme.colors[color]};
  `}
`;

interface StyledActionArrowProps {
  fillColor: keyof typeof defaultTheme.colors;
  strokeColor: keyof typeof defaultTheme.colors;
}

export const StyledActionArrow = styled.svg<StyledActionArrowProps>`
  ${({ theme, fillColor, strokeColor }) => css`
    stroke-width: ${theme.borderWidths.icons};

    .action-arrow-stroke {
      stroke: ${theme.colors[strokeColor]};
    }

    .action-arrow-fill {
      fill: ${theme.colors[fillColor]};
    }
  `}
`;

export const StyledHeaderBackground = styled.svg`
  ${({ theme }) => css`
    position: absolute;
    bottom: -2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 3440px;
    height: 1440px;
    z-index: 1;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      transform: translateX(-50%) scale(0.5);
      transform-origin: 50% 100%;
    }
  `}
`;

export const StyledPostOverlay = styled.svg`
  position: absolute;
  top: 0;
  left: 1rem;
  z-index: 1;
  height: 5.3rem;
  width: 4.8rem;
`;

export const StyledFooterBG = styled.svg`
  position: absolute;
  top: 0rem;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 90%;
  z-index: 1;
`;
