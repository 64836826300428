import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';
import Image from '@components/image';
import StyledFooterBG from '@components/svg-icons/footer-bg';
import NewsletterModal from '@components/newsletter-modal';
import LINKS from '@constants/footer-links';
import generateId from '@helpers/generate-id';
import {
  StyledFooter,
  StyledWrapper,
  StyledDescription,
  Content,
  ContentTop,
  ContentBottom,
  Logo,
  SocialMediasContainer,
  SocialMedia,
  SectionTitle,
  Links,
  LegalText,
  NavLinks,
  NavColumn,
  NavColumnItem,
  StyledLink,
  MetaLinks,
  BrandContent,
  StyledNavLink,
  StyledNavInternalLink,
} from './footer.styled';

const footerIdGenerator = generateId();

interface FooterProps {
  offsetAmount: number;
}

interface FooterPageInterface {
  prismicGeneralConfig: {
    data: {
      legal_brand_name?: string;
      small_logo?: {
        alt: string;
        url: string;
      };
      footer_description: {
        richText: RichTextBlock[];
      };
      footer_transit_types_links: {
        link_label: string;
        link_url: {
          url: string;
          target: string;
          document?: {
            uid: string;
          };
        };
      }[];
      footer_product_links: {
        link_label: string;
        link_url: {
          url: string;
          target: string;
          document?: {
            uid: string;
          };
        };
      }[];
      footer_resources_links: {
        link_label: string;
        link_url: {
          url: string;
          target: string;
          document?: {
            uid: string;
          };
        };
      }[];
      footer_why_tripshot_links: {
        link_label: string;
        link_url: {
          url: string;
          target: string;
          document?: {
            uid: string;
          };
        };
      }[];
      footer_company_links: {
        link_label: string;
        link_url: {
          url: string;
          target: string;
          document?: {
            uid: string;
          };
        };
      }[];
      footer_resource_center_links: {
        link_label: string;
        link_url: {
          url: string;
          target: string;
          document?: {
            uid: string;
          };
        };
      }[];
      body: {
        items: {
          image?: {
            alt: string;
            url: string;
          };
          community_link?: {
            url: string;
          };
        }[];
      }[];
    };
  };
}

const query = graphql`
  query FooterQuery {
    prismicGeneralConfig {
      data {
        legal_brand_name
        small_logo {
          alt
          url
        }
        footer_description {
          richText
        }
        footer_transit_types_links {
          link_label
          link_url {
            url
            target
          }
        }
        footer_product_links {
          link_label
          link_url {
            url
            target
          }
        }
        footer_resources_links {
          link_label
          link_url {
            url
            target
          }
        }
        footer_why_tripshot_links {
          link_label
          link_url {
            url
            target
          }
        }
        footer_company_links {
          link_label
          link_url {
            url
            target
          }
        }
        footer_resource_center_links {
          link_label
          link_url {
            url
            target
            document {
              ... on PrismicPage {
                uid
              }
              ... on PrismicBlog {
                uid
              }
              ... on PrismicBooks {
                uid
              }
              ... on PrismicVideos {
                uid
              }
              ... on PrismicWhitePapers {
                uid
              }
              ... on PrismicCaseStudies {
                uid
              }
            }
          }
        }
        body {
          ... on PrismicGeneralConfigDataBodySocialMedia {
            id
            items {
              image {
                url
                alt
              }
              community_link {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const Footer = ({ offsetAmount }: FooterProps): JSX.Element => {
  const {
    prismicGeneralConfig: {
      data: {
        footer_description,
        legal_brand_name,
        small_logo,
        footer_transit_types_links,
        footer_product_links,
        footer_resources_links,
        footer_why_tripshot_links,
        footer_company_links,
        footer_resource_center_links,
        body,
      },
    },
  }: FooterPageInterface = useStaticQuery(query);
  const allLinks = [
    { title: 'Transit Types', links: footer_transit_types_links },
    { title: 'Products', links: footer_product_links },
    { title: 'Resources', links: footer_resources_links },
    { title: 'Why TripShot', links: footer_why_tripshot_links },
    { title: 'Company', links: footer_company_links },
    { title: 'Resource Center', links: footer_resource_center_links },
  ];
  const description = footer_description?.richText;
  const hasDescription = Boolean(description.length && description[0].text);
  const currentYear = new Date().getFullYear();
  const copyText = `© ${currentYear} ${legal_brand_name || 'TripShot Inc'} All rights reserved.`;

  return (
    <StyledFooter>
      <StyledFooterBG />
      <StyledWrapper>
        <ContentTop>
          <Content>
            <BrandContent>
              {(small_logo?.url || body[0]?.items[0].image?.url) && small_logo?.url && (
                <Logo url={small_logo?.url} alt={small_logo?.alt || 'logo'} />
              )}
              {hasDescription && (
                <StyledDescription size="body" fontColor="dark" richText={description} />
              )}
            </BrandContent>
            <NewsletterModal offsetAmount={offsetAmount} />
          </Content>
          <NavLinks>
            <NavColumn>
              {allLinks.map(({ title, links }) => (
                <li key={footerIdGenerator.next().value}>
                  <SectionTitle>{title}</SectionTitle>
                  <ul>
                    {links?.map(({ link_label, link_url }) => (
                      <NavColumnItem key={footerIdGenerator.next().value}>
                        {link_url.document && link_url.document.uid ? (
                          <StyledNavInternalLink
                            to={
                              link_url.document.uid === 'main' ? `/` : `/${link_url.document.uid}`
                            }
                          >
                            {link_label}
                          </StyledNavInternalLink>
                        ) : (
                          <StyledNavLink href={link_url.url}>{link_label}</StyledNavLink>
                        )}
                      </NavColumnItem>
                    ))}
                  </ul>
                </li>
              ))}
            </NavColumn>
          </NavLinks>
        </ContentTop>
        <ContentBottom>
          <LegalText>{copyText}</LegalText>
          <Links>
            <MetaLinks>
              {LINKS.map((item) => (
                <StyledLink key={footerIdGenerator.next().value} href={item.url}>
                  {item.name}
                </StyledLink>
              ))}
            </MetaLinks>
            <SocialMediasContainer>
              {body[0]?.items?.map(({ image, community_link }) => {
                if (!image?.url) {
                  return null;
                }

                return (
                  <SocialMedia
                    href={community_link?.url}
                    target="_blank"
                    rel="noreferrer"
                    key={footerIdGenerator.next().value}
                  >
                    <Image url={image?.url} alt={image?.alt} />
                  </SocialMedia>
                );
              })}
            </SocialMediasContainer>
          </Links>
        </ContentBottom>
      </StyledWrapper>
    </StyledFooter>
  );
};

export default Footer;
