import React from 'react';
import { VideoContainer } from './video.styled';

export interface VideoProps {
  video: {
    html: string;
  };
}

const Video = ({ video }: VideoProps): JSX.Element | null => {
  return <VideoContainer dangerouslySetInnerHTML={{ __html: video?.html }} />;
};

export default Video;
