import React from 'react';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { RichTextBlock } from 'prismic-reactjs';
import Wrapper from '@components/wrapper.styled';
import HeaderBackgroundSvg, {
  HeaderBackgroundProps,
} from '@components/svg-icons/header-background';
import { CategoriesBlog } from '@components/module/module.shared.styled';
import Text from '@components/text';
import { DropdownItemProps } from '@components/dropdown';
import { StyledHeader, Constraint, Subheading } from './small-hero.styled';

interface SmallHeroProps {
  backgroundColor?: HeaderBackgroundProps['fillColor'];
  eyebrow?: string;
  title?: string;
  subheading?: RichTextBlock[];
}

interface CategoriesQueryInterface {
  allPrismicCategory: {
    edges: [];
  };
}

export const query = graphql`
  query CategoryNamesQuery {
    allPrismicCategory {
      edges {
        node {
          ...CategoryFragment
        }
      }
    }
  }
`;

const SmallHero = ({
  backgroundColor = 'Launch Purple',
  eyebrow,
  title,
  subheading = [],
}: SmallHeroProps): JSX.Element => {
  const {
    allPrismicCategory: { edges },
  }: CategoriesQueryInterface = useStaticQuery(query);
  const categoryItems: DropdownItemProps[] = edges.map(
    (edge: { node: { prismicId: string; data: { name?: string } } }): DropdownItemProps => {
      return {
        label: edge.node?.data?.name || '',
        link: edge.node?.prismicId,
      };
    }
  );
  const hasSubheading: boolean = subheading?.length > 0 && subheading[0].text !== '';
  const location = useLocation();
  const activeTag = new URLSearchParams(location.search).get('tag') || '';
  const activeCategory = new URLSearchParams(location.search).get('category') || '';
  const activeCategoryName = categoryItems.find((item) => item.link === activeCategory)?.label;

  return (
    <StyledHeader fillColor={backgroundColor}>
      <CategoriesBlog />
      <Wrapper>
        <Constraint>
          {title && (
            <Text
              type="title"
              stringText={activeCategoryName || decodeURIComponent(activeTag) || title}
              size="heading2"
              fontColor="white"
              spacing="sm"
            />
          )}
          {!activeCategory && !activeTag && hasSubheading && (
            <Subheading type="subheading" richText={subheading} />
          )}
        </Constraint>
        <HeaderBackgroundSvg fillColor={backgroundColor} />
      </Wrapper>
    </StyledHeader>
  );
};

export default SmallHero;
