import React from 'react';
import { PostsGrid } from '@components/posts-list/posts-list.shared.styled';
import generateId from '@helpers/generate-id';
import {
  DummyWrapper,
  DummyImageContainer,
  DummyEyebrow,
  DummyTitle,
  DummyLink,
  DummyFeaturedImage,
  DummyDescriptionContainer,
  DummyFeaturedContainer,
  DummyCopy,
  DummySmallCopy,
} from './loader.styled';

const postsListIdGenerator = generateId();

interface LoaderProps {
  type: 'index' | 'related' | 'featured';
}

const Grid = ({ type = 'index' }: LoaderProps): JSX.Element => {
  const postItems = (qty = 9): JSX.Element[] => {
    const items: JSX.Element[] = [];
    for (let i = 0; i < qty; i += 1) {
      items.push(
        <DummyWrapper key={postsListIdGenerator.next().value}>
          <DummyImageContainer />
          <DummyEyebrow />
          <DummyTitle />
          <DummyLink />
        </DummyWrapper>
      );
    }
    return items;
  };

  const loadingEl = (loadType: LoaderProps['type']): JSX.Element => {
    switch (loadType) {
      case 'index':
        return <PostsGrid>{postItems().map((item) => item)}</PostsGrid>;

      case 'featured':
        return (
          <DummyFeaturedContainer>
            <DummyFeaturedImage />
            <DummyDescriptionContainer>
              <DummyEyebrow />
              <DummyTitle />
              <DummyCopy />
              <DummyCopy />
              <DummySmallCopy />
              <DummyLink />
            </DummyDescriptionContainer>
          </DummyFeaturedContainer>
        );

      case 'related':
        return <PostsGrid>{postItems().map((item) => item)}</PostsGrid>;

      default:
        return <PostsGrid>{postItems().map((item) => item)}</PostsGrid>;
    }
  };

  return loadingEl(type);
};

export default Grid;
