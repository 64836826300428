import styled, { css } from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';
import { HeaderBackgroundProps } from '@components/svg-icons/header-background';

export const Constraint = styled.div`
  max-width: 720px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
`;

export const Subheading = styled(Text)<{ position?: string }>`
  display: block;
  max-width: 38rem;
  margin: 0 auto;

  ${({ theme }) => css`
    ${generateScales('font-size', '1.125rem', '1.375rem', theme)}

    p + p {
      ${generateScales('margin-top', '1rem', '1.5rem', theme)}
    }
  `}
`;

export const StyledHeader = styled.header<{ fillColor: HeaderBackgroundProps['fillColor'] }>`
  ${({ fillColor, theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    text-align: center;
    width: 100%;
    position: relative;
    padding-bottom: 3rem;
    padding-top: 1.5rem;
    z-index: 10;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      row-gap: 2rem;
      padding-bottom: 2rem;
    }

    ${fillColor === `Launch Purple` &&
    css`
      ${Subheading} {
        color: ${theme.colors.light};
      }
    `}

    ${fillColor === `Deep Purple` &&
    css`
      ${Subheading} {
        color: ${theme.colors.light};
      }
    `}
  `}
`;
