import styled, { css, keyframes } from 'styled-components';
import { generateScales } from '@helpers/generate-media-queries';

const rotateDownKeyframes = keyframes`
  0% {
      transform: scaleY(0)
      
  }
  80% {
      transform: scaleY(1.1)
  }
  100% {
      transform: scaleY(1)
  }
`;

export const rotateDownAnimation = () => css`
  transform-origin: top center;
  animation: 0.25s ${rotateDownKeyframes} ease-in-out 0s 1 forwards;
`;

export const DropdownContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledArrow = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.425rem 0.3125rem 0 0.3125rem;
  margin-top: 0.1125rem;
  border-color: ${({ theme }) => theme.colors.dark} transparent transparent transparent;
  transition: transform 0.15s ease-in-out, margin-top 0.15s ease-in-out,
    margin-bottom 0.15s ease-in-out;
  transform-style: preserve-3d;
`;

export const StyledDropDown = styled.ul<{ isVisible?: boolean }>`
  ${({ theme }) => css`
    padding: 0.675rem 0.375rem 0.5rem 0.375rem;
    width: 100%;
    min-width: 8rem;
    position: absolute;
    top: calc(100% + 0.25rem);
    left: 0;
    margin: 0;
    visibility: hidden;
    font-family: ${theme.fontFamily.text};
    font-weight: ${theme.fontWeights.bold};
    z-index: 5;
    ${generateScales('font-size', '0.8375rem', '1rem', theme)}
    border: 1px solid ${theme.colors.white};
    border-radius: ${theme.borderRadius.normal};
    background-color: ${theme.colors.white};
    pointer-events: none;
    box-shadow: ${theme.shadows.small};
  `}
  ${({ isVisible }) =>
    isVisible &&
    css`
      pointer-events: auto;
      visibility: visible;
      ${rotateDownAnimation}
    `};
`;

export const Button = styled.button<{ isVisible?: boolean }>`
  ${({ theme }) => {
    return css`
      text-decoration: none;
      font-family: ${theme.fontFamily.text};
      font-weight: ${theme.fontWeights.normal};
      font-size: ${theme.fontSize.body.desktop};
      cursor: pointer;
      display: flex;
      width: 100%;
      column-gap: 0.5rem;
      justify-content: space-between;
      align-items: center;
      border-width: ${theme.borderWidths.ui};
      border-style: solid;
      border-color: ${theme.colors.dark};
      background: ${theme.colors.white};
      color: ${theme.colors.dark}75;
      height: 2.5rem;
      padding: 0 0.75rem;
      ${generateScales('font-size', '0.8375rem', '1rem', theme)}
      ${generateScales('border-radius', '0.25rem', '0.25rem', theme)}

      :hover, :focus {
        border-color: ${theme.colors.primary};
      }

      :focus {
        outline: 1px solid ${theme.colors.primary};
      }

      :hover ${StyledArrow}, :focus ${StyledArrow} {
        border-color: ${theme.colors.primary} transparent transparent transparent;
      }
    `;
  }}

  ${({ theme, isVisible }) =>
    isVisible &&
    css`
      color: ${theme.colors.primary};

      ${StyledArrow} {
        margin: 0 0 0.1125rem;
        transform: rotateX(180deg);
      }
    };
  `}
`;

export const Text = styled.span`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.colors.dark};
    font-family: ${theme.fontFamily.text};
    font-weight: ${theme.fontWeights.normal};
    ${generateScales('font-size', '0.8375rem', '1rem', theme)}
    line-height: ${theme.lineHeights.sm};
    text-decoration: none;
    border: none;
  `}
`;

export const Links = styled.a`
  ${({ theme }) => css`
    display: flex;
    padding: 0.5rem 0.5rem;
    text-decoration: none;
    border-radius: ${theme.borderRadius.small};
    text-align: left;

    ::after {
      content: '→';
      display: inline;
      color: ${theme.colors.dark}00;
      font-family: ${theme.fontFamily.text};
      font-weight: ${theme.fontWeights.normal};
      ${generateScales('font-size', '0.8375rem', '1rem', theme)}
      line-height: ${theme.lineHeights.sm};
      text-decoration: none;
    }

    :last-child {
      padding-bottom: 0.75rem;
    }

    :hover {
      margin-top: -0.25rem;
      background-color: ${theme.colors.primary};
      padding: 0.75rem 0.5rem;

      + a {
        padding-top: 0.25rem;
      }

      ${Text} {
        color: ${theme.colors.white};
      }

      ::after {
        color: ${theme.colors.white};
      }
    }
  `}
`;
