import styled, { css, keyframes } from 'styled-components';
import {
  FeaturedContainer,
  DescriptionContainer,
} from '@components/feature-post/feature-post.styled';
import { generateScales } from '@helpers/generate-media-queries';
import { hexToRgb } from '@helpers/hex-to-rgb';

const gradientKeyframes = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const GradientLoader = css`
  ${({ theme }) => {
    const rgbVals = hexToRgb(theme?.colors.light);

    return css`
      position: relative;

      ::before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        animation: ${gradientKeyframes} 3s linear infinite;
        background: linear-gradient(
          45deg,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0.75) 0%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0.75) 2.5%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0) 15%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0) 35%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0.75) 47.5%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0.75) 52.5%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0) 65%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0) 85%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0.75) 97.5%,
          rgba(${rgbVals.r}, ${rgbVals.g}, ${rgbVals.b}, 0.75) 100%
        );
        background-size: 400% 400%;
      }
    `;
  }}
`;

export const DummyWrapper = styled.div`
  ${GradientLoader}
`;

export const DummyImageContainer = styled.div`
  padding-top: 56.25%;
  background: ${({ theme }) => theme.colors.light};
`;

export const DummyEyebrow = styled.div`
  ${({ theme }) => {
    const desktopH =
      parseFloat(theme.lineHeights.base) * parseFloat(theme.fontSize.eyebrow.desktop);
    const mobileH = parseFloat(theme.lineHeights.base) * parseFloat(theme.fontSize.eyebrow.mobile);

    return css`
      ${generateScales('height', `${desktopH}rem`, `${mobileH}rem`, theme)}
      width: ${`${Math.random() * (40 - 20) + 20}%`};
      background: ${theme.colors.light};
      ${generateScales('border-radius', `${desktopH / 4}rem`, `${mobileH / 4}rem`, theme)}
      ${generateScales('margin-top', '1.25rem', '1.5rem', theme)}
      ${generateScales('margin-bottom', '0.75rem', '0.875rem', theme)}
    `;
  }}
`;

export const DummyCopy = styled.div`
  width: 100%;
  margin-bottom: 1rem;

  ${({ theme }) => {
    const desktopH = parseFloat(theme.lineHeights.base) * parseFloat(theme.fontSize.body.desktop);
    const mobileH = parseFloat(theme.lineHeights.base) * parseFloat(theme.fontSize.body.mobile);
    return css`
      background: ${theme.colors.light};
      ${generateScales('border-radius', `${desktopH / 4}rem`, `${mobileH / 4}rem`, theme)}
      ${generateScales('height', `${desktopH}rem`, `${mobileH}rem`, theme)}
    `;
  }}
`;

export const DummyLink = styled.div`
  width: ${`${Math.random() * (50 - 25) + 25}%`};

  ${({ theme }) => {
    const desktopH = parseFloat(theme.lineHeights.base) * parseFloat(theme.fontSize.body.desktop);
    const mobileH = parseFloat(theme.lineHeights.base) * parseFloat(theme.fontSize.body.mobile);
    return css`
      background: ${theme.colors.light};
      ${generateScales('border-radius', `${desktopH / 4}rem`, `${mobileH / 4}rem`, theme)}
      ${generateScales('height', `${desktopH}rem`, `${mobileH}rem`, theme)}
    `;
  }}
`;

export const DummySmallCopy = styled(DummyCopy)`
  width: 50%;

  + ${DummyLink} {
    margin-top: 2rem;
  }
`;

export const DummyTitle = styled.div`
  width: ${`${Math.random() * (100 - 75) + 75}%`};

  + ${DummyCopy} {
    margin-top: 2rem;
  }

  ${({ theme }) => {
    const desktopH =
      parseFloat(theme.lineHeights.base) * parseFloat(theme.fontSize.heading5.desktop);
    const mobileH = parseFloat(theme.lineHeights.base) * parseFloat(theme.fontSize.heading5.mobile);
    return css`
      background: ${theme.colors.light};
      ${generateScales('border-radius', `${desktopH / 4}rem`, `${mobileH / 4}rem`, theme)}
      ${generateScales('height', `${desktopH}rem`, `${mobileH}rem`, theme)}
      ${generateScales('margin-bottom', '1.25rem', '1.5rem', theme)}
    `;
  }}
`;

export const DummyFeaturedContainer = styled(FeaturedContainer)`
  ${GradientLoader}
`;

export const DummyDescriptionContainer = styled(DescriptionContainer)`
  ${GradientLoader}
`;

export const DummyFeaturedImage = styled.div`
  padding-top: 56.25%;
  background: ${({ theme }) => theme.colors.light};
`;
