import React, { useState, useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Text from '@components/text';
import { Cookies } from 'react-cookie-consent';
import { debounce } from '@helpers/debounce';
import { RichTextBlock } from 'prismic-reactjs';
import NewsletterForm from '@components/newsletter-form';
import {
  NewsletterWrapper,
  NewsletterHeader,
  NewsletterTitle,
  CloseButton,
  NewsletterContainer,
} from './newsletter-modal.styled';

interface NewsletterModalProps {
  offsetAmount: number;
}

export interface NewsletterData {
  prismicGeneralConfig: {
    data: {
      newsletter_title?: {
        text: string;
      };
      newsletter_description?: {
        richText: RichTextBlock[];
      };
      newsletter_button_label?: string;
      newsletter_confirmation_title?: {
        text: string;
      };
      newsletter_confirmation_message?: {
        richText: RichTextBlock[];
      };
      newsletter_legal_text?: {
        richText: RichTextBlock[];
      };
    };
  };
}

const NewsletterModal = ({ offsetAmount }: NewsletterModalProps): JSX.Element => {
  const modalRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const cookieName = 'newsletterModal';
  const [displayModal, setDisplayModal] = useState(false);
  const isMouseOverModalRef = useRef(false);

  // cms query
  const {
    prismicGeneralConfig: {
      data: {
        newsletter_title,
        newsletter_description,
        newsletter_button_label,
        newsletter_confirmation_title,
        newsletter_confirmation_message,
        newsletter_legal_text,
      },
    },
  }: NewsletterData = useStaticQuery(
    graphql`
      query NewsletterQuery {
        prismicGeneralConfig {
          data {
            newsletter_title {
              text
            }
            newsletter_description {
              richText
            }
            newsletter_button_label
            newsletter_confirmation_title {
              text
            }
            newsletter_confirmation_message {
              richText
            }
            newsletter_legal_text {
              richText
            }
          }
        }
      }
    `
  );

  const hideModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setDisplayModal(false);
    Cookies.set(cookieName, 'false');
  };

  // check if cookie is set
  useEffect(() => {
    const cookieVal = Cookies.get(cookieName);
    if (cookieVal === 'false') {
      setDisplayModal(false);
    } else {
      setDisplayModal(true);
    }
  }, []);

  // hide modal on scroll
  useEffect(() => {
    const loadScrollPosition = window.scrollY;
    const modal = modalRef.current;

    const handleScroll = debounce(() => {
      const scrollPosition = loadScrollPosition - window.scrollY;
      if (
        modal &&
        !modal.contains(document.activeElement) &&
        !isMouseOverModalRef.current &&
        (scrollPosition >= 100 || scrollPosition <= -100)
      ) {
        window.removeEventListener('scroll', handleScroll);
        modal?.classList.add('animate-out');
        modal?.addEventListener('animationend', () => {
          setDisplayModal(false);
        });
      }
    }, 250);

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // show modal on mouseover
  useEffect(() => {
    const modal = innerRef.current;

    if (modal) {
      modal.addEventListener('mouseenter', () => {
        isMouseOverModalRef.current = true;
      });
      modal.addEventListener('mouseleave', () => {
        isMouseOverModalRef.current = false;
      });
    }

    return () => {
      if (modal) {
        modal.removeEventListener('mouseenter', () => {
          isMouseOverModalRef.current = true;
        });
        modal.removeEventListener('mouseleave', () => {
          isMouseOverModalRef.current = false;
        });
      }
    };
  }, [innerRef]);

  return (
    <NewsletterWrapper isFixed={displayModal} offsetAmount={offsetAmount} ref={modalRef}>
      <NewsletterContainer ref={innerRef}>
        {displayModal && <CloseButton onClick={hideModal} />}
        <NewsletterTitle>
          <NewsletterHeader>
            <Text
              type="title"
              tag="h3"
              size="heading5"
              fontColor="primary"
              stringText={newsletter_title?.text}
            />
          </NewsletterHeader>
          <Text
            type="body"
            fontColor="dark"
            richText={newsletter_description?.richText}
            size="body"
            spacing="none"
          />
        </NewsletterTitle>
        <NewsletterForm
          buttonLabel={newsletter_button_label || 'Subscribe'}
          legalRichText={newsletter_legal_text?.richText}
          confirmationMessage={newsletter_confirmation_message?.richText}
          confirmationTitle={newsletter_confirmation_title?.text}
        />
      </NewsletterContainer>
    </NewsletterWrapper>
  );
};

export default NewsletterModal;
